<div class="container">
    <div class="content-form">
      <div class="logo-app">
        <img src="assets/logo.jpg" alt="Logo" title="Logo Unifersa"/>
      </div>
      <form id="loginForm" #loginForm="ngForm">
        <div class="title">
            <h3>Recordar contraseña</h3>
        </div>
        <div class="input-form">
            <input type="text"  [(ngModel)]="emailSend" placeholder="Introduzca el E-Mail" name="emailSend">
        </div>
        <div class="notification" *ngIf="success">
          Se ha enviado un enlace a este correo para reestablecer la contraseña
        </div>
       
       
        <div class="actions">
            <button type="submit" class="cancel" (click)="back()">Cancelar</button>
            <button type="submit" (click)="sendMail()">Confirmar</button>
        </div>
      </form>
    </div>
  </div>