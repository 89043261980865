<div class="unimodal">
  <div class="wrapper">
    <div class="title">
      <h3>{{title}}</h3>
    </div>
    <div class="body">
      {{text}}
    </div>
    <div class="actions">
      <div class="button cancel" (click)="cancel()">Cancelar</div>
      <div class="button confirm" (click)="actionExec()">Confirmar</div>
    </div>
  </div>
</div>