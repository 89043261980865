import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Provider } from '../../api/models/provider/provider';
import { NotificationService } from 'src/app/notification/notification.service';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/api/api.service';
import { Product } from 'src/app/api/models/product/product';

@Component({
  selector: 'app-provider',
  templateUrl: './provider.component.html',
  styleUrls: ['./provider.component.less']
})

export class ProviderComponent implements OnInit {
  public providers: Provider[];
  p:number = 1;
  public deleteModal:boolean=false;
  public id:number;  
  public filters:Array<string>=[];
  public filter:string=null;
  providersA: Provider[];
  
  public hideLoad:boolean=false;
  provider: Provider = new Provider;
  role:number;

  deleteAllModal:boolean = false;
  deleteProviderProds: number = 0;

  constructor(private api: ApiService, private router:Router,private notification: NotificationService, private _route: ActivatedRoute, private cookie:CookieService) {
    this.role = parseInt(cookie.get("role"));
    if(parseInt(cookie.get("role"))!=3){
      //this.getProviders();
    }
    else{
      this.api.user.getUsersId(parseInt(cookie.get("userid"))).subscribe(
        data => {
          this.getProviderId(data.response.provider);
        },
        error => {
          console.error(error);
        }
      );
    }
  }

  ngOnInit() {
    if (this.role!=3) {
      this.api.provider.getProviders().subscribe(
        data => {
          if(data && data.code===200){
            this.providers=data.response;
            this.providers.forEach(provider => {
              provider.image="/assets/logos/"+provider.image;
              let exist:boolean = false;
              this.filters.forEach(filt => {
                if(filt==provider.name.charAt(0).toLowerCase()){
                  exist=true;
                }
                if(parseInt(provider.name.charAt(0).toLowerCase()) && filt=='0-9'){
                  exist=true;
                }
              });
              if(!exist){
                if (parseInt(provider.name.charAt(0).toLowerCase())){
                  this.filters.push('0-9');
                }else{
                  this.filters.push(provider.name.charAt(0).toLowerCase());
                }
              }
            });
          this.filters.sort();
          }
          else{
            this.providers=[];
          }
        },
        err => {
          if(err.error.code==500){
            this.notification.error(err.error.message);
          }
        }
      );
    }
  }

  getProviders(): void{
    this.api.provider.getProviders().subscribe(
      providers => {
        if (providers){
          this.providers = providers.response;
          this.providers.forEach(provider => {
            provider.image="/assets/logos/"+provider.image;
          });
        }
      },
      error => {
        console.error(error);
        this.notification.error("Ha ocurrido un error al cargar los datos.");
      }
    );
  }

  getProviderId(id:number): void{
    this.api.provider.getProviderId(id).subscribe(user=>{
      if(user.response!=null){
        this.router.navigate(["/admin/provider/"+id]);
      } else{
        this.notification.error("ERROR with provider "+ id);  
      }
    });
  }

  getProductsByProvider(id:number): void{
    this.api.provider.getProviderId(id).subscribe(user=>{
      if(user.response!=null){
        this.router.navigate(["/admin/provider/"+id+"/products"]);
      } else{
        this.notification.error("ERROR with provider "+ id);  
      }
    });
  }

  insertProvider(): void{
    this.router.navigate(["/admin/provider"]);
  }

  deleteProvider(id: number): void{
    this.deleteModal=true;
    this.id=id;
  }

  hide(event):void{
    this.deleteModal=false;
    this.deleteAllModal = false;
    this.deleteProviderProds = 0;
  }

  delete(event):void{ //TODO revisar, poner solo un delete
    this.api.provider.deleteProvider(event.id).subscribe(
      data=>{
        if(data.code== 200){
          this.notification.success("Proveedor eliminado.");
          window.location.reload();
          document.querySelector("tr.id-"+event.id).remove();
        }
      },
      error=>{
        if(error.status==500){
          this.notification.error("No se pueden eliminar productos que ya hayan sido pedidos.");
        }
        this.deleteModal=false;
      }
    );
  }

  changeCsv(event, provider){
    if (event.srcElement.files.length>0){
      this.hideLoad=true;
      const file: File = event.srcElement.files[0];
      const reader = new FileReader();
      let products = [];
      let that=this;
      this.provider.id = provider;

      this.provider.date_products = new Date().toLocaleDateString();

      reader.onloadend = function(event){
        event.preventDefault();
        let texto: string = reader.result as string;
        let arr = texto.split('\n');
          
        arr.forEach( (row,index) =>{ 
          let product=new Product;
          if(row.trim()){
            let row_arr=row.split("\t");
            row_arr[0] = row_arr[0].replace('"',"");
            product.ean=row_arr[0].trim();
            product.ref=row_arr[1];
            product.name=row_arr[2];
            product.uds_caja = parseFloat(row_arr[3]);
            product.price=parseFloat(row_arr[4]);
            product.pvpr = parseFloat(row_arr[5]);
            product.description=row_arr[6];
            product.images=row_arr[7];

            products.push(product);
            
          }
        });

        that.hideLoad=false;
        that.provider.products=products;
        that.updateProvider(provider);
        
      }
      reader.readAsText(file, "UTF-8");
    }
  }

  private updateProvider(idprov): void{
    this.hideLoad=true;
    this.api.provider.updateProvider(idprov, this.provider).subscribe(
      data => {
        let num_prods=this.provider.products.length;
        let i=0;
        if (num_prods != 0){
        this.provider.products.forEach( (product, j) => {
          product.provider=idprov;
          this.api.product.updateProduct(product).subscribe(
            prod=>{ 0
              i++;
              if(i==num_prods-1){ 
                this.hideLoad=false;
                window.location.reload();
              }
            },
            err=>{
              console.log(err);
              if (err.error.code==404){
                this.api.product.insertProduct(product).subscribe(
                  success => { 
                    i++;
                    if(i==num_prods-1){
                      this.hideLoad=false;
                      this.notification.success("Proveedor actualizado");
                      window.location.reload();
                    }
                  },
                  error => {
                    console.log(error);
                    //console.log(error);
                  }
                );
              }
            }
          );
        });
        } 
        else{
          this.hideLoad=false;
          window.location.reload();
        }
      },
      error =>{
        console.error(error.error);
        this.hideLoad=false; 
        this.notification.error("Ha ocurrido un error al actualizar.");
      }
    );
  }

  changeConds(event, provider, name){
    if(event.srcElement.files.length>0){
      this.hideLoad=true;
      name = name.replace('Ñ', 'N');
      name = name.replace('-','');
      name = name.replace('_','');
      name = name.replace('.','');
      name = name.replace('&','');
      name = name.split(",")[0];
      name = name.replace('Á', 'A');
      name = name.replace('É', 'E');
      name = name.replace('Í', 'I');
      name = name.replace('Ó', 'O');
      name = name.replace('Ú', 'U');
      name = name.replace('Ü', 'U');
      this.api.provider.uploadFile(provider, event.srcElement.files[0], name).subscribe(
        data => {
          this.provider.conditions=data.response;
          this.hideLoad=false;
          this.notification.success("Se han subido las condiciones correctamente");
        },
        error => {
          this.notification.error("Ha ocurrido un error al subir las condiciones");
          console.error(error);
          this.hideLoad=false;
        }
      );
      //this.hideLoad=false;
    }
  }

  uploadFile(event, provider, name){
    if (event.srcElement.files.length>0){
      this.hideLoad=true;
      let nameArchivo = event.srcElement.files[0].name;
      name = name.split(" ")[0];
      name = name.replace("-", " ");
     
      this.api.provider.uploadArchive(provider, event.srcElement.files[0], nameArchivo, name).subscribe(
        data=>{
          console.log(data);
          this.notification.success("Archivo subido correctamente.");
          this.hideLoad=false;
        },
        error=>{
          console.error(error);
          this.notification.error("Ha ocurrido un error al subir el archivo");
          this.hideLoad=false;
        }

      );
    }
  }


  activateFilter($event,letter){ 
    if(letter == '0-9'){ 
      if(document.querySelector(".filter.active")){
        document.querySelector(".filter.active").classList.remove("active");
      }
      document.querySelector(".filter.all").classList.remove("all");
      $event.currentTarget.classList.add('active');
      this.filter='0';
    }
    else if(letter!="todos"){
      if(document.querySelector(".filter.active")){
        document.querySelector(".filter.active").classList.remove("active");
      }
      document.querySelector(".filter.all").classList.remove("all");
      $event.currentTarget.classList.add('active');
      this.filter=letter;
    }
    else{
      document.querySelector(".filter.active").classList.remove("active");
      document.querySelector(".filter").classList.add("all");
      this.filter=null;
    }
  }

  removeAllProducts(provider){
    this.deleteAllModal = true;
    this.deleteProviderProds = provider;
  }

  removeAllProds(){
    //Eliminar (poner fecha de baja) tódos los productos del proveedor
    let that = this;
    let ean = null;
    this.api.product.deleteProduct(ean, this.deleteProviderProds).subscribe(
      data=>{
        if (data && data.code == 200){
          window.location.reload();
        }
      }
    );
  }
 
}
