import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/api/api.service';
import { Product } from 'src/app/api/models/product/product';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/notification/notification.service';

@Component({
  selector: 'list-orders-client',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.less']
})
export class OrdersComponent implements OnInit {
  public orders:any[] = [];
  userId:number;
  user:any=[];

  constructor(private router:Router, private api: ApiService, private cookie:CookieService,private notification: NotificationService) { 
    this.userId = parseInt(cookie.get("userid"));
    api.user.sumPages(this.userId).subscribe();
    let that= this;
    this.api.user.getUsersId(this.userId).subscribe(
      data=>{
        if (data.response){
          that.user = data.response;
          that.getOrders();
        } 
      }, 
      error=>{
        console.log(error);
      }
    );
  
  }

  ngOnInit() {
  }

  getOrders(){
    let that = this;
    if (this.user.role != 3){
      this.api.order.getOrdersByUser(this.userId).subscribe(
        data=>{
          if (data && data.response){console.log(data);
            that.orders = [];
            data.response.forEach(element => {
              element.user = that.user.contact;
              that.orders.push(element);
            });
            //console.log(that.orders);
          }
        },
        error=>{
          console.log(error);
        }
      );
    }else if (this.user.role == 3){ //proveedor
      this.api.order.getOrdersByProvider(this.user.provider).subscribe(
        data=>{
          if (data && data.response){
            that.orders = [];
            data.response.forEach(element => {
              element.user = that.user.contact;
              that.api.user.getUsersEmail(element.email).subscribe(
                data=>{
                  if (data.response){
                    element['dataUser']=data.response;
                  }
                }
              );
              let repe = that.orders.filter(el => {return el.id == element.id});
              if (repe.length == 0) that.orders.push(element);
            });
          }
        }
      );
    }
  }

  getProductsOrder(id:number){console.log(id);
    this.api.order.getProductsOrder(id).subscribe(
      data =>{
        if (data!=null){
          this.router.navigate(["/orders/"+id]);
        }else{
          this.notification.error("Ha ocurrido un error con el pedido "+ id);
        }
      },
      error=>{
        console.log(error.error);
      }
    );
  }

}
