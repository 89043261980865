export class Contact {
    id: number;
    name: string;
    provider: number;
    phone: string;
    email: string;
    province: number;
    central: number;
}

export class Province {
    id: number;
    name: string;
}
  