import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.less']
})
export class ModalComponent implements OnInit {
  @Input() title:string;
  @Input() text:string;
  @Input() id:number;

  @Output() hideModal = new EventEmitter();
  @Output() action = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  cancel(){
    this.hideModal.emit(false);
  }

  actionExec(){
    this.action.emit({id: this.id});
  }
}
