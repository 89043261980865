import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from 'src/app/config/config.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class OrderService {
  private http;
  private server;
  private url = "/api/"+this.config.version+"/order";

  constructor(private httpClient: HttpClient, private config: ConfigService, private cookie: CookieService) {
    this.server=this.config.getServer();
    this.http=this.httpClient;
  }

  public sendOrder(text: string, idUser: number, contacts: string = null, productsText: string = null, namePdf: string = null){
    const _headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const httpOptions = {_headers};
   
    return this.http.post(this.server + this.url + "/send", {message: text, to: idUser, contacts: contacts, productsText: productsText, namePdf: namePdf}, httpOptions);
  }

  public insertOrder(user: number, more:string, products:any[]){
    const _headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const httpOptions = {_headers};
    
    return this.http.post(this.server + this.url, {user: user, more:more, products: products}, httpOptions);
  }

  public uploadInvoice(name: string, file){
    const _headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const httpOptions = {_headers};
    
    return this.http.post(this.server + this.url + "/uploadFile", {name: name, file:file}, httpOptions);
  }

  public uploadInvoiceCSV(name: string, file){
    const _headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const httpOptions = {_headers};
    
    return this.http.post(this.server + this.url + "/uploadFileCSV", {name: name, file:file}, httpOptions);
  }

  public getOrders(){
    return this.http.get(this.server + this.url);
  }

  public getOrderById(id){
    return this.http.get(this.server + this.url + "/"+id.toString() + "/order")
  }

  public getOrdersById(id){
    return this.http.get(this.server + this.url + "/"+id.toString() + "/orderid");
  }

  public getOrdersByUser(id){
    return this.http.get(this.server + this.url + "/"+id.toString() + "/user")
  }

  public getOrdersByProvider(id){
    return this.http.get(this.server + this.url + "/"+id.toString() + "/provider")
  }

  public getProductsOrder(id: number){
    return this.http.get(this.server + this.url + "/" + id);
  }

  public getClientsWOrders(){
    return this.http.get(this.server+this.url+"/clients");
  }

  public getProvidersWOrders(){
    return this.http.get(this.server+this.url+"/providers");
  }
}
