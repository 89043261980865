<div class="container">
  <header-admin *ngIf="n" title="Editar usuario"></header-admin>
  <header-admin *ngIf="!n" title="Añadir un nuevo usuario"></header-admin>
  <form *ngIf="user">
    <div class="campo">
      <div class="etiqueta">
        <div class="wrapper">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/></svg>
          </div>
          <div class="text">Nombre</div> 
        </div>
      </div>
      <div class = "input">
        <input class = "inputc" type = "text" name="name" placeholder="Nombre del usuario" 
          [(ngModel)]="user.contact" #ctrl="ngModel">
      </div>
    </div>
    
    <div class="campo">
        <div class="etiqueta">
          <div class="wrapper">
            <div class="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 7V3H2v18h20V7H12zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zm-2-8h-2v2h2v-2zm0 4h-2v2h2v-2z"/></svg>
            </div>
            <div class="text">Nombre de empresa</div> 
          </div>
        </div>
        <div class = "input">
          <input class = "inputc" type = "text" name="business" placeholder="Nombre de empresa" 
          [(ngModel)]="user.business" #ctrl="ngModel">
        </div>
    </div>
    <div class="campo">
        <div class="etiqueta">
          <div class="wrapper">
            <div class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"/></svg>
            </div>
            <div class="text">Email</div> 
          </div>
        </div>
        <div class = "input">
          <input class = "inputc" type = "email" name="email" placeholder="Email del usuario" 
          [(ngModel)]="user.email" #ctrl="ngModel">
        </div>
    </div>
    <div class="campo">
        <div class="etiqueta">
          <div class="wrapper">
            <div class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"/></svg>
            </div>
            <div class="text">Contraseña</div> 
          </div>
        </div>
      <div class = "input">
        <input class = "inputc" type = "password" name="pass" placeholder="Contraseña del usuario" 
        [(ngModel)]="user.pass" #ctrl="ngModel">
      </div>
    </div>
    <div class="campo">
        <div class="etiqueta">
          <div class="wrapper">
            <div class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"/></svg>
            </div>
            <div class="text">Validar contraseña</div> 
          </div>
        </div>
      <div class = "input">
        <input class = "inputc" type = "password" name="passv" placeholder="Contraseña del usuario" 
        [(ngModel)]="user.passv" #ctrl="ngModel">
      </div>
    </div>
    <div class="campo">
      <div class="etiqueta">
        <div class="wrapper">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M11.99 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm3.61 6.34c1.07 0 1.93.86 1.93 1.93 0 1.07-.86 1.93-1.93 1.93-1.07 0-1.93-.86-1.93-1.93-.01-1.07.86-1.93 1.93-1.93zm-6-1.58c1.3 0 2.36 1.06 2.36 2.36 0 1.3-1.06 2.36-2.36 2.36s-2.36-1.06-2.36-2.36c0-1.31 1.05-2.36 2.36-2.36zm0 9.13v3.75c-2.4-.75-4.3-2.6-5.14-4.96 1.05-1.12 3.67-1.69 5.14-1.69.53 0 1.2.08 1.9.22-1.64.87-1.9 2.02-1.9 2.68zM11.99 20c-.27 0-.53-.01-.79-.04v-4.07c0-1.42 2.94-2.13 4.4-2.13 1.07 0 2.92.39 3.84 1.15-1.17 2.97-4.06 5.09-7.45 5.09z"/></svg>
          </div>
          <div class="text">Rol</div> 
        </div>
      </div>
      <div class = "input">
        <select class="inputc" name="role" [(ngModel)]="user.role" #ctrl="ngModel" ng-init= "'none'">
          <option [ngValue]="none" disabled>Selecciona tu rol</option>
          <option value="1">Cliente Asociado</option>
          <option value="4">Cliente Compras</option>
          <option value="2">Administrador</option>
          <option value="3">Proveedor</option>
          <option value="5">Demo</option>
        </select>
      </div>
    </div>
    <div class="campo" *ngIf="user.role && user.role==3">
      <div class="etiqueta">
        <div class="wrapper">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 7V3H2v18h20V7H12zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zm-2-8h-2v2h2v-2zm0 4h-2v2h2v-2z"/></svg>
          </div>
          <div class="text">Proveedor asociado</div> 
        </div>
      </div>
      <div class = "input">
        <select class="inputc" name="provider" [(ngModel)]="user.provider" #ctrl="ngModel" ng-init= "'none'">
          <option [ngValue]="none" disabled>Selecciona el proveedor asociado</option>
          <option *ngFor="let provider of providers" [ngValue]="provider.id">{{provider.name}}</option>
        </select>
      </div>
    </div>

    <div class="campo" *ngIf="user.role && (user.role==1 || user.role==4 || user.role==5)">
      <div class="etiqueta">
        <div class="wrapper">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path xmlns="http://www.w3.org/2000/svg" d="M17.81 4.47c-.08 0-.16-.02-.23-.06C15.66 3.42 14 3 12.01 3c-1.98 0-3.86.47-5.57 1.41-.24.13-.54.04-.68-.2-.13-.24-.04-.55.2-.68C7.82 2.52 9.86 2 12.01 2c2.13 0 3.99.47 6.03 1.52.25.13.34.43.21.67-.09.18-.26.28-.44.28zM3.5 9.72c-.1 0-.2-.03-.29-.09-.23-.16-.28-.47-.12-.7.99-1.4 2.25-2.5 3.75-3.27C9.98 4.04 14 4.03 17.15 5.65c1.5.77 2.76 1.86 3.75 3.25.16.22.11.54-.12.7-.23.16-.54.11-.7-.12-.9-1.26-2.04-2.25-3.39-2.94-2.87-1.47-6.54-1.47-9.4.01-1.36.7-2.5 1.7-3.4 2.96-.08.14-.23.21-.39.21zm6.25 12.07c-.13 0-.26-.05-.35-.15-.87-.87-1.34-1.43-2.01-2.64-.69-1.23-1.05-2.73-1.05-4.34 0-2.97 2.54-5.39 5.66-5.39s5.66 2.42 5.66 5.39c0 .28-.22.5-.5.5s-.5-.22-.5-.5c0-2.42-2.09-4.39-4.66-4.39-2.57 0-4.66 1.97-4.66 4.39 0 1.44.32 2.77.93 3.85.64 1.15 1.08 1.64 1.85 2.42.19.2.19.51 0 .71-.11.1-.24.15-.37.15zm7.17-1.85c-1.19 0-2.24-.3-3.1-.89-1.49-1.01-2.38-2.65-2.38-4.39 0-.28.22-.5.5-.5s.5.22.5.5c0 1.41.72 2.74 1.94 3.56.71.48 1.54.71 2.54.71.24 0 .64-.03 1.04-.1.27-.05.53.13.58.41.05.27-.13.53-.41.58-.57.11-1.07.12-1.21.12zM14.91 22c-.04 0-.09-.01-.13-.02-1.59-.44-2.63-1.03-3.72-2.1-1.4-1.39-2.17-3.24-2.17-5.22 0-1.62 1.38-2.94 3.08-2.94 1.7 0 3.08 1.32 3.08 2.94 0 1.07.93 1.94 2.08 1.94s2.08-.87 2.08-1.94c0-3.77-3.25-6.83-7.25-6.83-2.84 0-5.44 1.58-6.61 4.03-.39.81-.59 1.76-.59 2.8 0 .78.07 2.01.67 3.61.1.26-.03.55-.29.64-.26.1-.55-.04-.64-.29-.49-1.31-.73-2.61-.73-3.96 0-1.2.23-2.29.68-3.24 1.33-2.79 4.28-4.6 7.51-4.6 4.55 0 8.25 3.51 8.25 7.83 0 1.62-1.38 2.94-3.08 2.94s-3.08-1.32-3.08-2.94c0-1.07-.93-1.94-2.08-1.94s-2.08.87-2.08 1.94c0 1.71.66 3.31 1.87 4.51.95.94 1.86 1.46 3.27 1.85.27.07.42.35.35.61-.05.23-.26.38-.47.38z"/></svg>
          </div>
          <div class="text">NIF</div> 
        </div>
      </div>
      <div class = "input">
        <input class = "inputc" type = "text" name="nif" placeholder="NIF" 
          [(ngModel)]="user.nif" #ctrl="ngModel">
      </div>
    </div>
    <div class="campo" *ngIf="user.role && (user.role==1 || user.role==4 || user.role==5)">
      <div class="etiqueta">
        <div class="wrapper">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path xmlns="http://www.w3.org/2000/svg" d="M3.5 18.99l11 .01c.67 0 1.27-.33 1.63-.84L20.5 12l-4.37-6.16c-.36-.51-.96-.84-1.63-.84l-11 .01L8.34 12 3.5 18.99z"/></svg>
          </div>
          <div class="text">Código</div> 
        </div>
      </div>
      <div class = "input">
        <input class = "inputc" type = "text" name="code" placeholder="Código de la empresa" 
          [(ngModel)]="user.code" #ctrl="ngModel">
      </div>
    </div>
    <div class="campo" *ngIf="user.role && (user.role==1 || user.role==4 || user.role==5)">
      <div class="etiqueta">
        <div class="wrapper">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path xmlns="http://www.w3.org/2000/svg" d="M10 16v-1H3.01L3 19c0 1.11.89 2 2 2h14c1.11 0 2-.89 2-2v-4h-7v1h-4zm10-9h-4.01V5l-2-2h-4l-2 2v2H4c-1.1 0-2 .9-2 2v3c0 1.11.89 2 2 2h6v-2h4v2h6c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2zm-6 0h-4V5h4v2z"/></svg>
          </div>
          <div class="text">Razón social</div> 
        </div>
      </div>
      <div class = "input">
        <input class = "inputc" type = "text" name="name_business" placeholder="Razón social de la empresa" 
          [(ngModel)]="user.name_business" #ctrl="ngModel">
      </div>
    </div>
    <div class="campo" *ngIf="user.role && (user.role==1 || user.role==4 || user.role==5)">
      <div class="etiqueta">
        <div class="wrapper">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/></svg>
          </div>
          <div class="text">Código Postal</div> 
        </div>
      </div>
      <div class = "input">
        <input class = "inputc" type = "number" name="cp" placeholder="Código postal de la empresa" 
          [(ngModel)]="user.cp" #ctrl="ngModel">
      </div>
    </div>
    <div class="campo" *ngIf="user.role && (user.role==1 || user.role==4 || user.role==5)">
      <div class="etiqueta">
        <div class="wrapper">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/></svg>
          </div>
          <div class="text">País</div> 
        </div>
      </div>
      <div class = "input">
        <input class = "inputc" type = "text" name="country" placeholder="País de la empresa" 
          [(ngModel)]="user.country" #ctrl="ngModel">
      </div>
    </div>
    <div class="campo" *ngIf="user.role && (user.role==1 || user.role==4 || user.role==5)">
      <div class="etiqueta">
        <div class="wrapper">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/></svg>
          </div>
          <div class="text">Localidad</div> 
        </div>
      </div>
      <div class = "input">
        <input class = "inputc" type = "text" name="locality" placeholder="Localidad de la empresa" 
          [(ngModel)]="user.locality" #ctrl="ngModel">
      </div>
    </div>
    <div class="campo" *ngIf="user.role && (user.role==1 || user.role==4 || user.role==5)">
      <div class="etiqueta">
        <div class="wrapper">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/></svg>
          </div>
          <div class="text">Provincia</div> 
        </div>
      </div>
      <div class = "input">
        <select class="inputc" name="province" [(ngModel)]="user.province" #ctrl="ngModel">
          <option [ngValue]="" disabled>Selecciona</option>
          <option *ngFor="let provin of provinces" value="{{provin.name}}">{{provin.name}}</option>
        </select>
      </div>
    </div>
    <div class="campo" *ngIf="user.role && (user.role==1 || user.role==4 || user.role==5)">
      <div class="etiqueta">
        <div class="wrapper">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
              <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
            </svg>
          </div>
          <div class="text">Teléfono</div> 
        </div>
      </div>
      <div class = "input">
        <input class = "inputc" type = "text" name="phone_number" placeholder="Teléfono" 
          [(ngModel)]="user.phone_number" #ctrl="ngModel">
      </div>
    </div>

    <div class="campo width-all" *ngIf="user.role && (user.role==1 || user.role==4 || user.role==5)">
      <div class="etiqueta">
        <div class="wrapper">
          <div class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/></svg>
          </div>
          <div class="text">Dirección de la empresa</div> 
        </div>
      </div>
      <div class = "input">
        <input class = "inputc" type = "text" name="address_business" placeholder="Dirección del la empresa" 
        [(ngModel)]="user.address_business" #ctrl="ngModel">
      </div>
    </div>

    <div class="campo" *ngIf="n && user.role && (user.role==1 || user.role==4 || user.role==5)">
      <div class="etiqueta">
        <div class="wrapper">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path xmlns="http://www.w3.org/2000/svg" d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z"/></svg>
          </div>
          <div class="text">Crédito Disponible</div> 
        </div>
      </div>
      <div class = "input">
        <select class="inputc" name="credito" [(ngModel)]="user.credito" #ctrl="ngModel" ng-init= "'none'">
          <option [ngValue]="none" disabled>Selecciona</option>
          <option value="1">Sí</option>
          <option value="0">No (excedido)</option>
        </select>
      </div>
    </div>

    <div class="campo">
      <div class="etiqueta">
        <div class="wrapper">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24"><g><rect fill="none" height="24" width="24"/></g><g><g><g><path d="M23,5.5V20c0,2.2-1.8,4-4,4h-7.3c-1.08,0-2.1-0.43-2.85-1.19L1,14.83c0,0,1.26-1.23,1.3-1.25 c0.22-0.19,0.49-0.29,0.79-0.29c0.22,0,0.42,0.06,0.6,0.16C3.73,13.46,8,15.91,8,15.91V4c0-0.83,0.67-1.5,1.5-1.5S11,3.17,11,4v7 h1V1.5C12,0.67,12.67,0,13.5,0S15,0.67,15,1.5V11h1V2.5C16,1.67,16.67,1,17.5,1S19,1.67,19,2.5V11h1V5.5C20,4.67,20.67,4,21.5,4 S23,4.67,23,5.5z"/></g></g></g></svg>
          </div>
          <div class="text">Control de acceso</div> 
        </div>
      </div>
      <div class = "input">
        <select class="inputc" name="control" [(ngModel)]="user.control" #ctrl="ngModel" ng-init= "'none'">
          <option [ngValue]="none" disabled>Selecciona</option>
          <option value="1">Sí</option>
          <option value="0">No</option>
        </select>
      </div>
    </div>

  </form>
  <div class="actions">
    <div class="action save" (click)="saveUser()">
      <div class="wrapper">
        <div class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/></svg></div>
        <div class="text">Guardar usuario</div>
      </div>
    </div>
  </div>
</div>