import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from '../api/api.service';
import { User } from '../api/models/user/user';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.less']
})
export class AdminComponent implements OnInit {

  constructor(private cookie:CookieService, private api:ApiService, private route:Router) { }

  ngOnInit() {
    if(this.cookie.get("role") && (parseInt(this.cookie.get("role"))==1 || parseInt(this.cookie.get("role")) == 4 || parseInt(this.cookie.get("role")) == 5)){
      this.route.navigate([""]);
    }
    else if(this.cookie.get("role") && parseInt(this.cookie.get("role"))==3){
      this.route.navigate(["admin/providers"]);
    }
  }

}
