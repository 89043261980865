import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import { NotificationService } from 'src/app/notification/notification.service';
import { CookieService } from 'ngx-cookie-service';
import { User } from 'src/app/api/models/user/user';

@Component({
  selector: 'list-providers',
  templateUrl: './providers.component.html',
  styleUrls: ['./providers.component.less']
})
export class ProvidersComponent implements OnInit {
  public providers:any[];
  public filters:Array<string>=[];
  public filter:string=null;
  user:User;
  roleProvider: number = 3;

  constructor(private api: ApiService, private notification: NotificationService, private cookie:CookieService) {
    let userid = parseInt(cookie.get("userid"));
    api.user.sumPages(userid).subscribe();

    let that = this;
    api.user.getUsersId(userid).subscribe(
      data=>{
        if (data.response){
          that.user = data.response;
          that.getDataProvider();
        }
      }
    );

  }

  ngOnInit() {
   
  }

  getDataProvider(){
    this.api.provider.getProviders().subscribe(
      data => {
        if(data && data.code===200){
          this.providers=data.response;
          if (this.user.role==this.roleProvider){
            this.providers = data.response.filter(res=>{
              return res.id == this.user.provider;            
            });
            window.location.href = "/provider/"+this.user.provider;
          }
          let that = this;
          this.providers.forEach(provider => {
            this.api.contact.getContactByProvider(provider.id).subscribe(
              data=>{
                if (data.code == 200 && data.response.length>0 && typeof(data.response) == 'object'){                  
                  provider['online']=true;            
                }
              }
            );

            let exist:boolean = false;
            this.filters.forEach(filt => {
              if(filt==provider.name.charAt(0).toLowerCase()){
                exist=true;
              }
              if(parseInt(provider.name.charAt(0).toLowerCase()) && filt=='0-9'){
                exist=true;
              }
            });
            if(!exist){
              if (parseInt(provider.name.charAt(0).toLowerCase())){
                this.filters.push('0-9');
              }else{
                this.filters.push(provider.name.charAt(0).toLowerCase());
              }
              //this.filters.push(provider.name.charAt(0).toLowerCase());
            }
          });
        this.filters.sort();
        }
        else{
          this.providers=[];
        }
      },
      err => {
        if(err.error.code==500){
          this.notification.error(err.error.message);
        }
      }
    );

  }

  activateFilter($event,letter){
    if(letter == '0-9'){
      if(document.querySelector(".filter.active")){
        document.querySelector(".filter.active").classList.remove("active");
      }
      document.querySelector(".filter.all").classList.remove("all");
      $event.currentTarget.classList.add('active');
      this.filter='0';
    }
    else if(letter!="todos"){
      if(document.querySelector(".filter.active")){
        document.querySelector(".filter.active").classList.remove("active");
      }
      document.querySelector(".filter.all").classList.remove("all");
      $event.currentTarget.classList.add('active');
      this.filter=letter;
    }
    else{
      document.querySelector(".filter.active").classList.remove("active");
      document.querySelector(".filter").classList.add("all");
      this.filter=null;
    }
  }

}
