import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.less']
})
export class MenuComponent implements OnInit {
  menu:Array<any>;
  role:number;

  constructor(private router:Router, private cookie:CookieService) {
    let url=router.url.split("/")[2];
    this.role=parseInt(cookie.get("role"));
    this.menu = [
      {
        "icon": 'home',
        "title": "Inicio",
        "action": "/",
        "active": false,
        "not": [],
      },
      {
        "icon": "users",
        "title": "Usuarios",
        "active": url == "users" || url == "user" || url =="usersadmin" || url =="usersadministrador" || url =="usersasociado" || url =="userscompras" || url =="usersproveedor",
        "not": [3],
        "actions": [
          {
            "icon": "all",
            "title": "Todos los usuarios",
            "active": url == "users",
            "action": "/admin/users",
            "not": [3],
          },
          {
            "icon": "all",
            "title": "Administrador",
            "active": url == "usersadministrador",
            "action": "/admin/users/administrador",
            "not": [3],
          },
          {
            "icon": "all",
            "title": "Cliente Asociado",
            "active": url == "usersasociado",
            "action": "/admin/users/asociado",
            "not": [3],
          },
          {
            "icon": "all",
            "title": "Cliente Compras",
            "active": url == "userscompras",
            "action": "/admin/users/compras",
            "not": [3],
          },
          {
            "icon": "all",
            "title": "Proveedor",
            "active": url == "usersproveedor",
            "action": "/admin/users/prov",
            "not": [3],
          },
          {
            "icon": "all",
            "title": "Administrar usuarios",
            "active": url == "usersadmin",
            "action": "/admin/users/admin",
            "not": [3],
          },
          {
            "icon": "add",
            "title": "Añadir usuario",
            "active": url == "user",
            "action": "/admin/user",
            "not": [3],
          }
        ]
      },
      {
        "icon": "providers",
        "title": "Proveedores",
        "active": url == "providers" || url =="provider",
        "not": [],
        "actions": [
          {
            "icon": "all",
            "title": "Todos los proveedores",
            "active": url == "providers",
            "action": "/admin/providers",
            "not": [3]
          },
          {
            "icon": "edit",
            "title": "Editar proveedor",
            "active": url == "provider",
            "action": "/admin/providers",
            "not": [2]
          },
          {
            "icon": "add",
            "title": "Añadir proveedor",
            "active": url =="provider",
            "action": "/admin/provider",
            "not": [3]
          }
        ]
      },
      {
        "icon": "orders",
        "title": "Pedidos",
        "active": url == "orders",
        "not": [],
        "actions": [
          {
            "icon": "all",
            "title": "Todos los pedidos",
            "active": url == "orders",
            "action": "/admin/orders",
            "not": [],
          },
          {
            "icon": "all",
            "title": "Pedidos por asociado",
            "active": url == "ordersasociado",
            "action": "/admin/orders/asociado",
            "not": [3],
          },
          {
            "icon": "all",
            "title": "Pedidos por proveedor",
            "active": url == "ordersproveedor",
            "action": "/admin/orders/proveedor",
            "not": [3],
          }
        ]
      },
    ];
  }

  ngOnInit() {
  }

  viewActions($event, index){
    if(this.menu[index].action){
      this.router.navigate([this.menu[index].action]);
    }
    else if(document.querySelector(".entry.active")){
      document.querySelector(".entry.active").classList.remove("active");
      $event.currentTarget.classList.add('active');
    }
  }

  doAction($event, action){
    let element;
    if( (element=document.querySelector(".entry.active .actions.active .action.active")) ){
      element.classList.remove("active");
    }
    $event.currentTarget.classList.add('active');
    
    this.router.navigate([action]);
  }

  showMenu(){
    let classes=document.querySelector(".content .mobile-wrapper").classList;
    let change:boolean=false;
    classes.value.split(" ").forEach(elem => {
      if(elem=="active"){
        classes.remove("active");
        change=true;
      }
    });

    if(!change){
      classes.add("active");
    }
  }
}
