export class User {
    id: number;
    email: string;
    password: string;
    contact:string; 
    role:number;
    provider:number;
    rec_pass:string;
    business:string;

    code:string;
    name_business:string;
    address_business:string;
    cp:number;
    locality:string;
    province:string;
    nif:string;
    credito:number;
    control:number;
    docs:number;
    country:string;
    phone_number:string;
}
