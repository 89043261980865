import { Component, OnInit, Input, OnChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'paginate-list',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.less']
})
export class PaginationComponent implements OnInit, OnChanges, OnDestroy {
  @Input() data:any[];
  @Input() perPage:number;

  @Output() public onChangePage = new EventEmitter<any[]>();

  public data_print:Array<any[]> = [];
  public page:number;
  public num_pages:number;

  public prevDisabled:boolean=true;
  public nextDisabled:boolean=true;
  public pageActive:boolean[]= [];

  constructor(private activatedRoute: ActivatedRoute, private cookie:CookieService) {
  }

  ngOnInit() {
    let pcrt = this.cookie.get("page");
    if(pcrt){
      this.page=parseInt(pcrt);
      this.changePage(this.page);
    }
    else{
      this.page=0;
    }
    /*this.activatedRoute.queryParams.subscribe(
      params =>{
        if(params["crtp"]){
          this.page=parseInt(params["crtp"]);
        }
      }
    );*/
  }

  ngOnChanges(){
    let pcrt = this.cookie.get("page");
    if(pcrt){
      this.page=parseInt(pcrt);
      this.changePage(this.page);
    }
    else{
      this.page=0;
    }
    this.loadPagination();
  }

  ngOnDestroy(): void {
    this.cookie.delete("page");
    this.cookie.set("page", this.page.toString());
  }

  public changePage(i:number){
    if(i>=0 && i<this.num_pages){
      this.prevDisabled=!(i>0);
      this.nextDisabled=!(i<(this.num_pages-1));
      this.activePage(i);
      this.page=i;
      this.data=this.data_print[this.page];
      this.onChangePage.emit(this.data);
    }
  }

  private activePage(i:number){
    this.pageActive.forEach((pageActive, idx) => {
      this.pageActive[idx]=(idx==i);
    });
  }

  public loadPagination(){
    let num_elems:number=0;
    let num_rows:number=0;
    if(this.data && this.perPage){
      this.num_pages=Math.ceil(this.data.length/this.perPage);
 
      this.data_print[num_rows]=[];
      this.pageActive[num_rows]=true;
      this.data.forEach(element => {
        if(num_elems==this.perPage){
          num_rows ++;
          num_elems=0;

          this.data_print[num_rows]=[];
          this.pageActive[num_rows]=false;
            
        }
        this.data_print[num_rows][num_elems]=element;
        num_elems ++;
      });

      if(this.data_print[this.data_print.length-1].length < this.perPage){
        for(let i = this.data_print[this.data_print.length-1].length; i < this.perPage; i++){
          this.data_print[this.data_print.length-1][i]=[];
        }
      }

      if(this.num_pages>1){
        this.nextDisabled=false;
      }

      this.data=this.data_print[0];
    }
  }

}