import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from 'src/app/config/config.service';
import { Cart } from './cart';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private http;
  private server;
  private url = "/api/"+this.config.version+"/cart";

  constructor(private httpClient: HttpClient, private config: ConfigService) { 
    this.server=this.config.getServer();
    this.http=this.httpClient;
  }

  public getCarts(){
    return this.http.get(this.server + this.url);
  }

  public getCartCurrentByUser(id){
    return this.http.get(this.server + this.url + "/"+id.toString());
  }

  public insertCurrentCart(data: Cart){
    const _headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const _params = null;

    const httpOptions = {
      _headers,
      _params,
      withCredentials: false
    };

    return this.http.post(this.server+this.url, data, httpOptions);
  }

  public deleteCartByUser(id:number){
    
    const _headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const _params = null;

    const httpOptions = {
      _headers,
      _params,
      withCredentials: false
    };

    return this.http.delete(this.server + this.url + "/" + id, httpOptions);  
  }

  public deleteCartByUserAndEan(id:number, ean:string){
    
    const _headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const _params = null;

    const httpOptions = {
      _headers,
      _params,
      withCredentials: false
    };

    return this.http.delete(this.server + this.url + "/" + id + "/" + ean, httpOptions);  
  }
}
