<div class="container">
  <header-admin title="Todos los proveedores"></header-admin>
  <div class="header">
    <div class="filters">
      <div class="filter all" (click)="activateFilter($event, 'todos')">Todos</div>
      <div [ngClass]="{'filter': true, 'active': filter==letter, 'all': letter==null}" *ngFor="let filter of filters" (click)="activateFilter($event, filter)">{{filter}}</div>
    </div>
  </div>
  <div class="list-data">
    <div *ngFor="let provider of providers">
    <div class="row" *ngIf="!filter || provider.name[0].toLowerCase() == filter || (filter == 0 && provider.name[0]>=0)">
      <div class="data img">
        <img *ngIf="provider.image" [src]="provider.image" title="Logo Empresa" alt="Error imagen"/>
        <img *ngIf="!provider.image" src="/assets/no-image.png" title="Empresa Sin Logo" alt="Error imagen"/>
      </div>
      <div class="data name">{{provider.name}}</div>
      <div class="data num">
        <span class="label products" *ngIf="provider.nProds > 0">{{provider.nProds}} productos</span>
        <span class="label noproducts" *ngIf="provider.nProds == 0">Sin productos</span>
      </div>
      <div class="data act">
        Actualización:<br> {{provider.date_products}}
      </div>
      <div class="data upload">
        <div class = "input"> Productos
          <input class = "inputc" id="csv" type = "file" name="products" 
          placeholder="Productos del usuario" accept=".csv, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" 
          (change)="changeCsv($event, provider.id)">
        </div>
      </div>
      <div class="data remove">
        <button class="but" (click)="removeAllProducts(provider.id)">Eliminar productos</button>      
      </div>
     
      <div class="data actions">
        <div class="action edit" (click)="getProviderId(provider.id)">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/></svg>
        </div>
        <div class="action delete" (click)="deleteProvider(provider.id)">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/></svg>
        </div>
      </div>
      <div class="more" (click)="getProductsByProvider(provider.id)">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5.88 4.12L13.76 12l-7.88 7.88L8 22l10-10L8 2z"/></svg>
      </div>
    </div>
    </div>
  </div>
  <div class="add" (click)="insertProvider()">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
  </div>
</div>
<div class="loading" *ngIf="hideLoad"><div>Cargando...</div></div>
<modal text="¿Está seguro que desea eliminar este proveedor?" title="Eliminar proveedor" id="{{id}}" (hideModal)="hide($event)" (action)="delete($event)" *ngIf="deleteModal"></modal>
<modal text="¿Está seguro que desea eliminar todos los productos de este proveedor?" title="Eliminar todos los productos" (hideModal)="hide($event)" (action)="removeAllProds()" *ngIf="deleteAllModal"></modal>