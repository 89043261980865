import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'main-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent implements OnInit {
  year: number = new Date().getFullYear();

  @Input() banner:boolean;

  constructor() { }

  ngOnInit() {
  }

}
