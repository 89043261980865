<div class="container">
  <header-admin title="Lista de productos">
    <div class="header-aux">
      <div class="wrapper">
        <button class="but but-clean" (click)="cleanProducts()">Limpiar</button>
        <button class="but" (click)="removeAllProducts()">Eliminar todos</button>
        <form id="changeConds" *ngIf="provider">
          <input type="checkbox" name="show_conds" [checked]="provider.show_conds" (change)="changeConds()"/>
          <label for="show_conds">Mostrar condiciones</label>
        </form>
      </div>
    </div>
  </header-admin>
  <unifersa-tabla [headers]="headers">
    <div class="row" *ngFor="let product of products | paginate: {itemsPerPage: 10, currentPage: p}">
      <div class="ref">{{product.ref}}</div>
      <div class="ean">{{product.ean}}</div>
      <div class="name">{{product.name}}</div>
      <div class="price">{{product.price}}</div>
      <div class="actions">
        <div class="action delete" (click)="deleteProdutc(product.ean)">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/></svg>
        </div>
      </div>
    </div>
  </unifersa-tabla>
  <div class="paginacion">
    <pagination-controls (pageChange)="p = $event" directionLinks="true" nextLabel="Siguiente" previousLabel="Anterior" autoHide="true"></pagination-controls>
  </div>
  
</div>

<modal text="¿Está seguro que desea eliminar este producto?" title="Eliminar producto" id="{{id}}" (hideModal)="hide($event)" (action)="delete($event)" *ngIf="deleteModal"></modal>
<modal text="Se eliminarán todos los productos que no tengan EAN en este proveedor ¿Está seguro que desea eliminar estos productos?" title="Limpiar proveedor" (hideModal)="hide($event)" (action)="clean($event)" *ngIf="cleanModal"></modal>
<modal text="¿Está seguro que desea eliminar todos los productos?" title="Eliminar todos los productos" (hideModal)="hide($event)" (action)="removeAll()" *ngIf="deleteAllModal"></modal>