import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';
import { LoginComponent } from './login/login.component';
import { ProductsComponent } from './main/products/products.component';
import { ProductViewComponent } from './main/products/view/view.component';
import { CartComponent } from './main/cart/cart.component';
import { AdminComponent } from './admin/admin.component';
import { UserComponent } from './admin/user/user.component';
import { UserDataComponent } from './admin/user-data/user-data.component';
import { ProviderComponent } from './admin/provider/provider.component';
import { ProviderDataComponent } from './admin/provider-data/provider-data.component';
import { OrderComponent } from './admin/order/order.component';
import { OrderDataComponent } from './admin/order-data/order-data.component';
import { SearchComponent } from './main/search/search.component';
import { ProviderProductsComponent } from './admin/provider/provider-products/provider-products.component';
import { ProvidersComponent } from './main/providers/providers.component';
import { AvisoLegalComponent } from './main/aviso-legal/aviso-legal.component';
import { PoliticaPrivacidadComponent } from './main/politica-privacidad/politica-privacidad.component';
import { PoliticaCookiesComponent } from './main/politica-cookies/politica-cookies.component';
import { AccessComponent } from './admin/user/access/access.component';
import { OrdersComponent } from './main/orders/orders.component';
import { ProfileComponent } from './main/profile/profile.component';
import { SetpassComponent } from './login/setpass/setpass.component';
import { RememberComponent } from './login/remember/remember.component';
import { ProductsXmlComponent } from './products-xml/products-xml.component';
import { ProviderContactsComponent } from './main/provider-contacts/provider-contacts.component';
import { ContactFormComponent } from './main/provider-contacts/form/form.component';
import { DocumentsComponent } from './admin/user/documents/documents.component';

const routes: Routes = [
  {
    path: "login",
    children: [
      {
        path: "",
        component: LoginComponent
      },
      {
        path: "remember",
        component: RememberComponent
      },
      {
        path: "setpass/:code",
        component: SetpassComponent
      }
    ]
  },
  {
    path: "cart",
    component: CartComponent
  },
  {
    path: "orders",
    children: [
      {
        path: "",
        component: OrdersComponent
      },
      {
        path: ":id",
        component: OrderDataComponent
      }
    ]
  },
 
  {
    path: "",
    component: MainComponent,
    children: [
      {
        path: "",
        redirectTo: "providers",
        pathMatch: "full"
      },
      {
        path: "providers",
        component: ProvidersComponent
      },
      {
        path: "provider/:id",
        component: ProductsComponent
      },
      {
        path: "provider/:id/product/:ean",
        component: ProductViewComponent
      },
      {
        path: "search/:seekerText",
        component: SearchComponent
      },
      {
        path: "aviso-legal",
        component: AvisoLegalComponent
      },
      {
        path: "politica-privacidad",
        component: PoliticaPrivacidadComponent
      },
      {
        path: "politica-cookies",
        component: PoliticaCookiesComponent
      }
    ]
  },
  {
    path: "profile",
    component: ProfileComponent
  },
  {
    path: "my-contacts",
    children: [
      {
        path: "",
        component: ProviderContactsComponent
      },
      {
        path: ":id",
        component: ContactFormComponent
      }
    ]
  },
  {
    path: "admin",
    component: AdminComponent,
    children: [
      {
        path: "",
        redirectTo: "users",
        pathMatch: "full"
      },
      {
        path: "users",
        component: UserComponent
      },
      {
        path: "users/admin",
        component: UserComponent
      },
      {
        path: "users/administrador",
        component: UserComponent
      },
      {
        path: "users/compras",
        component: UserComponent
      },
      {
        path: "users/asociado",
        component: UserComponent
      },
      {
        path: "users/prov",
        component: UserComponent
      },
      {
        path: "users/:id",
        component: UserComponent
      },
      {
        path: "user",
        component: UserDataComponent
      },
      {
        path: "user/:id",
        component: UserDataComponent
      },
      {
        path: "user/:id/access",
        children: [
          {
            path: '',
            component: AccessComponent
          },
          {
            path: ":ip",
            component: DocumentsComponent
          }
        ]
      },
      {
        path: "providers",
        component: ProviderComponent
      },
      {
        path: "provider",
        component: ProviderDataComponent
      },
      {
        path: "provider/:id/products",
        component: ProviderProductsComponent
      },
      {
        path: "provider/:id/contacts",
        component: ProviderContactsComponent
      },
      {
        path: "provider/:id/contacts/:idContact",
        component: ContactFormComponent
      },
      {
        path: "provider/:id",
        component: ProviderDataComponent
      },
      {
        path: "orders",
        component: OrderComponent
      },
      {
        path: "orders/asociado",
        component: OrderComponent
      },
      {
        path: "orders/proveedor",
        component: OrderComponent
      },
      {
        path: "order/asociado/:id",
        component: OrderComponent
      },
      {
        path: "order/proveedor/:id",
        component: OrderComponent
      },
      {
        path: "order/:id",
        component: OrderDataComponent
      }
    ]
  },
  {
    path: "getPoductsProvider/:code",
    component: ProductsXmlComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
