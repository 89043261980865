<div class="product-container" *ngIf="product">
  <div class="image" *ngIf="product.images">
    <img src="{{product.images[0]}}" title="{{product.name}}" alt="Imagen no disponible" onerror="this.src='/assets/no-image.png';" />
    <div class="image-carousel" *ngIf="product.images.length>1">
      <div class="img-car" *ngFor="let image of product.images">
        <img src="{{image}}" title="{{product.name}}" alt="Imagen no disponible" onerror="this.src='/assets/no-image.png';" />
      </div>
    </div>
  </div>
  <div class="product-info">
    <div class="wrapper">
      <div class="title">
        <div class="provider-img" *ngIf="provider.image">
          <a href="/provider/{{provider.id}}"><img src="../../../assets/logos/{{provider.image}}" title="{{provider.name}}" alt="Imagen no disponible" onerror="this.src='/assets/no-image.png';" /></a>
        </div>
        <h1>{{product.name}}</h1>
        <p *ngIf="product.description!=null" [innerHtml]="sanitizer.bypassSecurityTrustHtml(product.description)"></p>
      </div>
    
    </div>
    <div class="data">
      <div class="uds-box" *ngIf="product.uds_caja">
        <span class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"/></svg></span>
        <span class="value">Unidad mínima de venta: {{product.uds_caja}} unidades por caja</span>
      </div>
      <div class="table-info">
        <div class="row ean" *ngIf="product.ean">
          <div class="title">EAN</div>
          <div class="value">{{product.ean}}</div>
        </div>
        <div class="row ref" *ngIf="product.ref">
          <div class="title">Referencia</div>
          <div class="value">{{product.ref}}</div>
        </div>
        <div class="row more-info" *ngIf="product.url_info">
          <div class="title">Más info</div>
          <div class="value">
            <a class="link" href="{{product.url_info}}" target="_blank">Ver PDF</a>
          </div>
        </div>
      </div>
      <div class="price">
          <span class="price-offer" *ngIf="product.price_offer!=null">{{product.price_offer}} €</span>
          <span class="price" [ngClass]="{'in-offer': product.price_offer!=null}" *ngIf="product.price!=null && product.price!=0 && product.price != '0,0000'">{{product.price}} €</span>
          <span class="price" [ngClass]="{'in-offer': product.price_offer!=null}" *ngIf="product.price==null || product.price==0 || product.price == '0,0000'">Consultar precio</span>
          <span class="text" *ngIf="product.price!=null && product.price!=0 && product.price != '0,0000'">precio tarifa a {{dateActu}}</span>
        </div>
    </div>
    <div class="actions" *ngIf="showAddToCart">
      <form>
        <div class="form-input number-input">
          <input type="number" name="uds" min="{{product.uds_caja}}" [(ngModel)]="uds" readonly/>
          <div class="controls">
            <div class="wrapper">
              <div class="decrement" (click)="removeUds()">-</div>
              <div class="increment" (click)="addUds()">+</div>
            </div>
          </div>
        </div>
        <button type="submit" class="add-to-cart" (click)="addToCart()">Añadir al carrito</button>
      </form>
    </div>
  </div>
</div>

<div class="iframe_class" *ngIf="provider.view && ifr">
  <iframe frameborder="0" [src]="ifr" ></iframe>
</div>

<div id="iframe-aecoc"></div>

<div class="back" (click)="back()">
  <div class="icon">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z"/></svg>
  </div>
  <div class="text">
    Volver al catálogo de {{provider.name}}
  </div>
</div>