import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/api/api.service';
import { NotificationService } from 'src/app/notification/notification.service';
import { Provider } from 'src/app/api/models/provider/provider';
import { CookieService } from 'ngx-cookie-service';
import { ExportToCsv } from 'export-to-csv';
import * as importedSaveAs from "file-saver";
import * as watermark from 'watermarkjs';

@Component({
  selector: 'list-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.less']
})
export class ProductsComponent implements OnInit {
  public title:string="PROVEEDOR";
  public products:any[] = [];
  public provider:Provider;

  public listActive:string;
  public gridActive:string;

  public view:string="grid";
  public order:number=0;
  public np:number=100;

  public showArchive:boolean = false;
  public archive:string = "";

  public infoProducts:string= "";

  public loading:boolean=false;

  public roleBanned:number = 4;
  public roleBanned2:number = 5;
  public isRoleBanned:boolean = false;
  public roleProvider: number = 3;

  constructor(private route:ActivatedRoute, private api: ApiService, private notification: NotificationService, private router:Router, private cookie:CookieService) {
    api.user.sumPages(parseInt(cookie.get("userid"))).subscribe();
    //this.regDowloadAll();
    let that = this;
    api.user.getUsersId(parseInt(cookie.get("userid"))).subscribe(
      data=>{
        if (data.response){
          if (data.response.role == that.roleBanned || data.response.role == that.roleBanned2){
            that.isRoleBanned = true;
          }
          if (data.response.role == that.roleProvider){
            that.router.navigate(['/provider/'+data.response.provider]);
          }
        }
      }
    );

    this.route.params.subscribe(
      data => {
        this.api.provider.getProviderId(data.id).subscribe(
          data => {
            this.title=data.response.name;
            this.provider=data.response; 
            let name = this.title;
            name = name.split(" ")[0];
            name = name.replace("-", " ");
            while (name.includes(' ')){
              name = name.replace(' ', '__');
            }
            //this.title = name;
            this.api.provider.getArchive(name).subscribe(
              data=>{
                if (data.response && data.response != ""){ 
                  this.archive = data.response;
                  this.showArchive = true;
                }else{
                  this.showArchive = false;
                }
              },
              error=>{
                console.log(error);
              }
            );
          },
          err => {
            if(err.error.code==500){
              this.notification.error(err.error.message);
            }
          }
        );
        
        this.route.queryParams.subscribe(params => {
          if(params['v']){
            this.view = params['v'];
          }
          if(params['o']){
            this.order = params['o'] ? params['o'] : null;
          }
          if(params['p']){
            this.np = params['p'];
          }
        });

        this.api.product.getProductsByProvider(data.id, this.order).subscribe(
          data => {
            this.products=data.response;
            this.products.forEach(element => {
              //if (element.name == "") element.name = "Sin nombre";
              element.price=(element.price * 100 / 100).toFixed(2);
              if (element.name == "") element.name = element.ref;
            });
          },
          err => {
            console.log(err);
            if (err.error.code==404){
              this.infoProducts="Información de producto no disponible para este proveedor.";
            }
            if(err.error.code==500){
              this.notification.error(err.error.message);
            }
          }
        );
        
      }
    );
  }

  ngOnInit() {
    if(this.view=="grid"){
      this.gridActive="active";
    }else{
      this.listActive="active";
    }
  }

  public changeView(){
    let url=this.router.url.split("?")[0];
    
    if(this.order || this.np){
      if(this.order){
        url+="?o="+this.order+"&";
        if(this.np){
          url+="p="+this.np+"&";
        }
      }
      else{
        url+="?p="+this.np+"&";
      }
    }
    else{
      url+="?";
    }

    if(this.view=="grid"){
      window.location.href=url+"v=list";
    }else{
      window.location.href=url+"v=grid";
    }
  }

  public changeOrder(event){
    let url=this.router.url.split("?")[0];
    
    if(this.view || this.np){
      if(this.view){
        url+="?v="+this.view+"&";
        if(this.np){
          url+="p="+this.np+"&";
        }
      }
      else{
        url+="?p="+this.np+"&";
      }
    }
    else{
      url+="?";
    }
    
    window.location.href=url+"o="+event.srcElement.selectedIndex;
  }

  public changeNProducts(event){
    let url=this.router.url.split("?")[0];

    if(this.order || this.view){
      if(this.order){
        url+="?o="+this.order+"&";
        if(this.view){
          url+="v="+this.view+"&";
        }
      }
      else{
        url+="?v="+this.view+"&";
      }
    }
    else{
      url+="?";
    }

    this.np = event.target.value;
    window.location.href=url+"p="+this.np;
  }

  public regDowloadAll(){
    let that = this;

    that.api.provider.getAllConditions().subscribe(
      data => {
        if (data.code == 200 && data.response && data.response != ""){
          const options = {
            init(img){
              img.crossOrigin = 'anonymous';
            }
          };
          let X = 600; let Y = -1700;
          let nameUser=that.cookie.get("userid"); //TODO poner codigo o email
          const rotate = function(target){
            var context = target.getContext('2d');
            var metrics = context.measureText(nameUser);
            var x = (target.width / 2) - (metrics.width + 164);
            var y = (target.height / 2) + 164 * 2;

            context.translate(x, y);
            context.globalAlpha = 0.2;
            context.fillStyle = '#0082c0';
            context.font = '164px Josefin Slab';
            context.rotate(-45 * Math.PI / 180);
            context.fillText(nameUser, X, Y);
            return target;
          }
          
          let archiveName;
          let providers = data.response;
          Object.entries(providers).forEach((element)=>{
            archiveName = element[1];
            console.log(archiveName);
            
            let url = "https://unifersa.net/assets/conditions/"+archiveName;
            watermark([url], options).image(rotate).then(function(img){ 
              X=1000;Y=-1300; //1,2
              watermark([img.src]).image(rotate).then(function(img){
                X=1400;Y=-900; //1,3
                watermark([img.src]).image(rotate).then(function(img){
                  X=1800;Y=-500; //1,4
                  watermark([img.src]).image(rotate).then(function(img){
                    X = 0; Y= -1100; //3,1
                    watermark([img.src]).image(rotate).then(function(img){
                      X=400;Y=-700; //3,2
                      watermark([img.src]).image(rotate).then(function(img){
                        X=800;Y=-300;//3,3
                        watermark([img.src]).image(rotate).then(function(img){
                          X=1200;Y=100;//3,4
                          watermark([img.src]).image(rotate).then(function(img){
                            X=-600;Y=-500;//5,1 
                            watermark([img.src]).image(rotate).then(function(img){
                              X=-200; Y=-100; //5,2
                              watermark([img.src]).image(rotate).then(function(img){
                                X=200;Y=300; //5,3
                                watermark([img.src]).image(rotate).then(function(img){
                                  X=600;Y=700; //5,4
                                  watermark([img.src]).image(rotate).then(function(img){
                                    X=-1200;Y=100; //7,1
                                    watermark([img.src]).image(rotate).then(function(img){
                                      X=-800;Y=500; //7,2
                                      watermark([img.src]).image(rotate).then(function(img){
                                        X=-400;Y=900; //7,3
                                        watermark([img.src]).image(rotate).then(function(img){
                                          X=0;Y=1300; //7,4
                                          watermark([img.src]).image(rotate).then(function(img){
                                            X=300;Y=-1400; //2,1
                                            watermark([img.src]).image(rotate).then(function(img){
                                              X=700;Y=-1000; //2,2
                                              watermark([img.src]).image(rotate).then(function(img){
                                                X=1100;Y=-600; //2,3
                                                watermark([img.src]).image(rotate).then(function(img){
                                                  X=1500;Y=-200; //2,4
                                                  watermark([img.src]).image(rotate).then(function(img){
                                                    X=-300;Y=-800; //4,1
                                                    watermark([img.src]).image(rotate).then(function(img){
                                                      X=100;Y=-400; //4,2
                                                      watermark([img.src]).image(rotate).then(function(img){
                                                        X=500;Y=0; //4,3
                                                        watermark([img.src]).image(rotate).then(function(img){
                                                          X=900;Y=400; //4,4
                                                          watermark([img.src]).image(rotate).then(function(img){
                                                            X=-900;Y=-200; //6,1
                                                            watermark([img.src]).image(rotate).then(function(img){
                                                              X=-500;Y=200; //6,2
                                                              watermark([img.src]).image(rotate).then(function(img){
                                                                X=-100;Y=600; //6,3
                                                                watermark([img.src]).image(rotate).then(function(img){
                                                                  X=300;Y=1000; //6,4
                                                                  watermark([img.src]).image(rotate).then(function(img){
                                                                    X=-1500;Y=400; //8,1
                                                                    watermark([img.src]).image(rotate).then(function(img){
                                                                      X=-1100;Y=800; //8,2
                                                                      watermark([img.src]).image(rotate).then(function(img){
                                                                        X=-700;Y=1200; //8,3
                                                                        watermark([img.src]).image(rotate).then(function(img){
                                                                          X=-300;Y=1600; //8,4
                                                                          watermark([img.src]).image(rotate).then(function(img){
                                                                            //if (ind == (archiveName.length - 1))
                                                                              //that.loading = false;
                                                                              console.log("IMRIMER");
                                                                            //importedSaveAs.saveAs(img.src, elem);
                                                                          });
                                                                        });
                                                                      });
                                                                    });
                                                                  });
                                                                });
                                                              });
                                                            });
                                                          });
                                                        });
                                                      });
                                                    });
                                                  });
                                                });
                                              });
                                            });
                                          });
                                        });
                                      });
                                    });
                                  });
                                });
                              });
                            });
                          });
                        });
                      });
                    });
                  });
                });
              });                
            });
          });
        }        
      },
      error => {
        that.loading = false;
        console.error(error); 
      }
    );   

  }

  public regDownload(){    
    this.api.user.downloadPages(
      parseInt(this.cookie.get("userid")),
      this.provider.id, 'condiciones'
    ).subscribe();

    this.loading = true;

    const options = {
      init(img){
        img.crossOrigin = 'anonymous';
      }
    };

    let X = 600; let Y = -1700;
    let nameUser=this.cookie.get("userid");
    const rotate = function(target){
      var context = target.getContext('2d');
      var metrics = context.measureText(nameUser);
      var x = (target.width / 2) - (metrics.width + 164);
      var y = (target.height / 2) + 164 * 2;

      context.translate(x, y);
      context.globalAlpha = 0.2;
      context.fillStyle = '#0082c0';
      //context.fill();
      context.font = '164px Josefin Slab';
      context.rotate(-45 * Math.PI / 180);
      context.fillText(nameUser, X, Y);
      return target;
    }
    let archiveName;
    let name = this.provider.name.replace('Ñ', 'N');
    name = name.replace('-','');
    name = name.replace('_','');
    name = name.replace('.','');
    name = name.replace('&','');
    name = name.split(",")[0];    
    name = name.replace('Á', 'A');
    name = name.replace('É', 'E');
    name = name.replace('Í', 'I');
    name = name.replace('Ó', 'O');
    name = name.replace('Ú', 'U');
    name = name.replace('Ü', 'U');
    while (name.includes(' ')){
      name = name.replace(' ', '__');
    }
    
    let that = this;
    this.api.provider.getConditions(name).subscribe(
      data => {
        if (data.response != ""){
         archiveName = data.response;
         if (archiveName == "") archiveName = [this.provider.conditions];
        }else{
          archiveName = [this.provider.conditions];
        } 
        
        archiveName.forEach((elem, ind) => {     
          let url = "https://unifersa.net/assets/conditions/"+elem;
          let that = this;
          this.api.user.getUsersId(parseInt(this.cookie.get("userid"))).subscribe(
            data=>{
              if (data.response.code != null && data.response.code != "")
                nameUser= data.response.code;
              else
                nameUser = data.response.email;
              //4 cols 8 filas
              watermark([url], options).image(rotate).then(function(img){ 
                X=1000;Y=-1300; //1,2
                watermark([img.src]).image(rotate).then(function(img){
                  X=1400;Y=-900; //1,3
                  watermark([img.src]).image(rotate).then(function(img){
                    X=1800;Y=-500; //1,4
                    watermark([img.src]).image(rotate).then(function(img){
                      X = 0; Y= -1100; //3,1
                      watermark([img.src]).image(rotate).then(function(img){
                        X=400;Y=-700; //3,2
                        watermark([img.src]).image(rotate).then(function(img){
                          X=800;Y=-300;//3,3
                          watermark([img.src]).image(rotate).then(function(img){
                            X=1200;Y=100;//3,4
                            watermark([img.src]).image(rotate).then(function(img){
                              X=-600;Y=-500;//5,1 
                              watermark([img.src]).image(rotate).then(function(img){
                                X=-200; Y=-100; //5,2
                                watermark([img.src]).image(rotate).then(function(img){
                                  X=200;Y=300; //5,3
                                  watermark([img.src]).image(rotate).then(function(img){
                                    X=600;Y=700; //5,4
                                    watermark([img.src]).image(rotate).then(function(img){
                                      X=-1200;Y=100; //7,1
                                      watermark([img.src]).image(rotate).then(function(img){
                                        X=-800;Y=500; //7,2
                                        watermark([img.src]).image(rotate).then(function(img){
                                          X=-400;Y=900; //7,3
                                          watermark([img.src]).image(rotate).then(function(img){
                                            X=0;Y=1300; //7,4
                                            watermark([img.src]).image(rotate).then(function(img){
                                              X=300;Y=-1400; //2,1
                                              watermark([img.src]).image(rotate).then(function(img){
                                                X=700;Y=-1000; //2,2
                                                watermark([img.src]).image(rotate).then(function(img){
                                                  X=1100;Y=-600; //2,3
                                                  watermark([img.src]).image(rotate).then(function(img){
                                                    X=1500;Y=-200; //2,4
                                                    watermark([img.src]).image(rotate).then(function(img){
                                                      X=-300;Y=-800; //4,1
                                                      watermark([img.src]).image(rotate).then(function(img){
                                                        X=100;Y=-400; //4,2
                                                        watermark([img.src]).image(rotate).then(function(img){
                                                          X=500;Y=0; //4,3
                                                          watermark([img.src]).image(rotate).then(function(img){
                                                            X=900;Y=400; //4,4
                                                            watermark([img.src]).image(rotate).then(function(img){
                                                              X=-900;Y=-200; //6,1
                                                              watermark([img.src]).image(rotate).then(function(img){
                                                                X=-500;Y=200; //6,2
                                                                watermark([img.src]).image(rotate).then(function(img){
                                                                  X=-100;Y=600; //6,3
                                                                  watermark([img.src]).image(rotate).then(function(img){
                                                                    X=300;Y=1000; //6,4
                                                                    watermark([img.src]).image(rotate).then(function(img){
                                                                      X=-1500;Y=400; //8,1
                                                                      watermark([img.src]).image(rotate).then(function(img){
                                                                        X=-1100;Y=800; //8,2
                                                                        watermark([img.src]).image(rotate).then(function(img){
                                                                          X=-700;Y=1200; //8,3
                                                                          watermark([img.src]).image(rotate).then(function(img){
                                                                            X=-300;Y=1600; //8,4
                                                                            watermark([img.src]).image(rotate).then(function(img){
                                                                              if (ind == (archiveName.length - 1))
                                                                                that.loading = false;
                                                                              importedSaveAs.saveAs(img.src, elem);
                                                                            });
                                                                          });
                                                                        });
                                                                      });
                                                                    });
                                                                  });
                                                                });
                                                              });
                                                            });
                                                          });
                                                        });
                                                      });
                                                    });
                                                  });
                                                });
                                              });
                                            });
                                          });
                                        });
                                      });
                                    });
                                  });
                                });
                              });
                            });
                          });
                        });
                      });
                    });
                  });
                });                
              });
  
            }
          );
        });
        
      },
      error => {
        that.loading = false;
        console.error(error); 
      }
    );
  }

  public productsDownload(){
    this.api.user.downloadPages(
      parseInt(this.cookie.get("userid")),
      this.provider.id, 'productos'
    ).subscribe();

    let options={
      fieldSeparator: ';',
      filename: 'productos'+this.title,
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: "",
      useTextFile: false,
      useBom: true,
      useKeyAsHeaders: false,
      headers: ['PROVEEDOR', 'EAN', 'REFERENCIA', 'DESCRIPCIÓN', 'UNIDAD_VENTA', 'TARIFA', 'PVPR', 'CARACTERÍSTICAS', 'URL_IMAGEN']
    }
    const csvExported = new ExportToCsv(options);
    let productsExport:Array<any>=[];
    
    this.products.forEach(elem => {
      productsExport.push(
        [this.title, elem.ean, elem.ref, elem.name == null ? "" : elem.name, elem.uds_caja == 1 ? "" : elem.uds_caja, 
        elem.price == null ? "" : elem.price, elem.pvpr== null ? "" : elem.pvpr, elem.description== null ? "" : elem.description, elem.images== null ? "" : elem.images]
      );
    })

    csvExported.generateCsv(JSON.stringify(productsExport));

  }
}
