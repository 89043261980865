<header>
    <div class="top">
        <div class="wrapper">
            <div class="user">
                <div class="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/></svg>
                </div>
                <div class="content">
                    <div class="action" (click)="goTo('/admin')" *ngIf="role==2">Administrar</div>
                    <div class="action" (click)="goTo('/profile')" *ngIf="role==1 || role==4 || role==5 || role==3">Cuenta</div>
                    <div class="action" (click)="goTo('/my-contacts')" *ngIf="role==3">Contactos</div>
                    <div class="action" (click)="goTo('/orders')" *ngIf="role==1 || role==4 || role==3">Mis pedidos</div>
                    <div class="action" (click)="logout()">Salir</div>
                </div>
            </div>
            <div class="sep" *ngIf="showAddToCart"> | </div>
            <div class="cart" *ngIf="showAddToCart">
                <div class="number">
                    <div class="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z"/></svg>
                    </div>
                    <div class="nElem">{{cart.length}}</div>
                </div>
                <div class="content">
                    <div *ngIf="cart.length==0">No hay productos en el carrito.</div>
                    <div *ngIf="cart.length>0">
                        <h5 class="title">Tu carro de compra</h5>
                        <div class="nProds" *ngIf="cart.length==1">1 producto</div>
                        <div class="nProds" *ngIf="cart.length>1">{{cart.length}} productos</div>
                        <div class="products" *ngFor="let product of cart">
                            <div class="product">
                                <div class="wrapper">
                                    <div class="image">
                                        <img *ngIf="product.images" [src]="product.images[0]" title="Imagen Producto" alt="Error imagen" onerror="this.src='/assets/no-image.png';" />
                                        <img *ngIf="!product.images" src="/assets/no-image.png" title="Sin Imagen De Producto" alt="Error imagen"/>
                                    </div>
                                    <div class="info">
                                        <div class="name">{{product.name}}</div>
                                        <div class="price" *ngIf="product.price!=0">{{numberPointToCo(product.price)}} €</div>
                                        <div class="price" *ngIf="product.price==0">Consultar precio</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="total"><strong>Total en tu pedido</strong> {{numberPointToCo(cart_total)}} €</div>
                        <div class="actions">
                            <div class="action view" (click)="viewCart()">Ver carrito</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="logo" (click)="reloadHome()">
            <img src="assets/logo.jpg" title="Logo" alt="Logo Unifersa"/>
        </div>
        <div class="info">
            <div class="text main">Catálogo de artículos de proveedor directo</div>
            <div class="grid">
                <div class="text">{{nprovs}} proveedores con <br> plantilla de condiciones</div>
                <div class="text">{{nprods}} referencias disponibles <br> con entrega desde el proveedor</div>
                <div class="text">Envío de propuesta de pedido <br> directamente al proveedor</div>
            </div>
        </div>
    </div>
    <div class="seeker" *ngIf="seeker && role!=roleProvider">
        <div class="box">
            <div class="input">
                <span class="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/></svg>
                </span>
            </div>
        </div>
    </div>
</header>