<div class="header">
    <div class="title">
        <h2>{{title}}</h2>
        <div>
        <div class="conds" *ngIf="provider && provider.show_conds && !isRoleBanned" id="condition">
            <a (click)="regDownload()" title="Plantilla de condiciones">
                <svg width="24px" height="24px" viewBox="0 0 16 16" class="bi bi-info-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                    <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z"/>
                    <circle cx="8" cy="4.5" r="1"/>
                  </svg>
            </a>
        </div>
        <div class="conds" *ngIf="provider && showArchive">
            <a href="https://unifersa.net/assets/providers/{{title}}/file/{{archive}}" download title="Descargar Archivo">
                <svg width="24px" height="24px" viewBox="0 0 16 16" class="bi bi-file-earmark-arrow-down" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 1h5v1H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V6h1v7a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2z"/>
                <path d="M9 4.5V1l5 5h-3.5A1.5 1.5 0 0 1 9 4.5z"/>
                <path fill-rule="evenodd" d="M5.646 9.146a.5.5 0 0 1 .708 0L8 10.793l1.646-1.647a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 0-.708z"/>
                <path fill-rule="evenodd" d="M8 6a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0v-4A.5.5 0 0 1 8 6z"/>
              </svg>
            </a>
        </div>
        <div class="conds" >
            <a (click)="productsDownload()" title="Exportar productos">
                <svg width="24px" height="24px" viewBox="0 0 16 16" class="bi bi-list-ul" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm-3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                </svg>
            </a>
        </div>
        </div>
    </div>
    <div class="actions">
        <div class="wrapper">
            <div class="action show">
                <span class="text">Se están mostrando {{np}} de {{products.length}} productos</span>
                <span class="options">
                    <select (change)="changeNProducts($event)">
                        <option value="100" [selected]="np == 100" *ngIf="products.length>100">100 productos</option>
                        <option value="500" [selected]="np == 500" *ngIf="products.length>500">500 productos</option>
                        <option value="1000" [selected]="np == 1000" *ngIf="products.length>1000">1000 productos</option>
                        <option value="{{products.length}}" [selected]="np == products.length" *ngIf="products.length<=1000">Todos los productos</option>
                    </select>
                </span>
            </div>
            <div class="action order">
                <span class="text hideMobile">Ordénalos por</span>
                <span class="options">
                    <select (change)="changeOrder($event)">
                        <option value="0" [selected]="!order || order == 0">Posición</option>
                        <option value="1" [selected]="order == 1">Alfabético(A-Z)</option>
                        <option value="2" [selected]="order == 2">Alfabético(Z-A)</option>
                        <option value="3" [selected]="order == 3">Precio(Mayor a menor)</option>
                        <option value="4" [selected]="order == 4">Precio(Menor a mayor)</option>
                    </select>
                </span>
            </div>
            <div class="action view">
                <div class="grid {{gridActive}}" (click)="changeView()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path class="change" d="M20 2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM8 20H4v-4h4v4zm0-6H4v-4h4v4zm0-6H4V4h4v4zm6 12h-4v-4h4v4zm0-6h-4v-4h4v4zm0-6h-4V4h4v4zm6 12h-4v-4h4v4zm0-6h-4v-4h4v4zm0-6h-4V4h4v4z"/></svg>
                </div>
                <div class="list {{listActive}}" (click)="changeView()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M3 13h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7z" class="change" /><path d="M0 0h24v24H0z" fill="none"/></svg>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="loading" class="text-center view-grid">
    <div class="wrapper">
        <div class="element" >
            <p style="color: #0082c0">Descargando Condiciones...</p>
        </div>
    </div>
</div>
<div *ngIf="products.length==0" class="text-center view-grid">
    <div class="wrapper">
      <div class="element" *ngIf="infoProducts==''">
        Cargando artículos...
      </div>
      <div class="element" *ngIf="infoProducts!=''">
        {{infoProducts}}
      </div>
    </div>
</div>
<list-grid *ngIf="products.length>0" [data]="products" [view]="view" [order]="order" route="product" image="" identifier="ean" show_pag="true" elem_by_row="5" [perPage]="np"></list-grid>