import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/notification/notification.service';
import { CookieService } from 'ngx-cookie-service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { Cart } from 'src/app/api/models/cart/cart';

@Component({
  selector: 'view-product',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.less']
})
export class ProductViewComponent implements OnInit {
  public product:any;
  public provider:any = {
    id: null,
    name: "",
    image: "",
    view: ""
  };
  public uds:number=1;
  public ifr: SafeResourceUrl;
  public showAddToCart: boolean = true;
  public dateActu = null;

  constructor(private router:Router, private route:ActivatedRoute, private api:ApiService, private notification: NotificationService, private cookie: CookieService, private sanitizer: DomSanitizer, private location:Location) {
    api.user.sumPages(parseInt(cookie.get("userid"))).subscribe();
    if (3 == parseInt(this.cookie.get('role'))){ 
      this.showAddToCart = false;
    }

    //this.loadScript();
  }

  ngOnInit() {
    this.route.params.subscribe(
      data => {
        if (3 == parseInt(this.cookie.get('role'))){
          this.api.user.getUsersId(parseInt(this.cookie.get("userid"))).subscribe(
            data=>{
              if (!data.response /*|| (data.response.provider != data.id)*/){
                this.router.navigate(['/provider/'+data.response.provider]);
             }
            }
          );
        }
        this.api.provider.getProviderId(data.id).subscribe(
          data => {
            this.provider.id=data.response.id;
            this.provider.name=data.response.name;
            this.provider.image=data.response.image;
            this.provider.view = data.response.view;
            this.provider.api = data.response.api;
            this.dateActu = data.response.date_products;
          },
          err =>  {
            if(err.error.code==500){
              this.notification.error(err.error.message);
            }
          }
        );
        
        this.api.product.getProductByRef(data.id, data.ean).subscribe(
          data => {
            this.product=data.response;
            if(this.product.uds_caja){
              this.uds=this.product.uds_caja;
            }
            
            if (this.product.images[0].includes('g,')){
              this.product.images = this.product.images[0].split('g,');
              this.product.images.forEach((element,ind) => {
                if (ind < (this.product.images.length - 1)) element = element+'g';
              });
            }

            this.product.price=(this.product.price * 100 / 100).toFixed(4);
            this.product.price = this.product.price.toString().replace(".", ",");
            if (this.product.price_offer != null){
             this.product.price_offer=(this.product.price_offer * 100 / 100).toFixed(4);
             this.product.price_offer = this.product.price_offer.toString().replace(".", ",");
            }
            if(this.provider.api==2){
              this.provider.view = "https://unifersa.net/iframe/?ean="+this.product.ean;
              this.ifr = this.sanitizer.bypassSecurityTrustResourceUrl(this.provider.view);
            }
            else{
              this.ifr = this.sanitizer.bypassSecurityTrustResourceUrl(this.provider.view + this.product.ean);
            } 
          },
          err => {
            console.log(err);
            if(err.error.code==500){
              this.notification.error(err.error.message);
            }
          }
        )
      }
    );
  }

  loadScript(){    
    let gln = '8431832000005';
    let gtin = '04003530259012';
    let scriptt = document.createElement('script');
    scriptt.type = "text/javascript";
    scriptt.innerHTML = "AECOC_EMBEDDED_LIBRARY.init('ZGZhZTUwM2UtNTdjMi00YWNlLThmYjktMzVhZWFhOTUzNDdi', '724', '"+gln+"', '"+gtin+"','iframe-aecoc');";
    document.getElementsByTagName('head')[0].appendChild(scriptt);
  }

  addToCart(){
    let cartData = new Cart();
    cartData.user = parseInt(this.cookie.get("userid"));
    cartData.ref = this.product.ref;
    cartData.ean = this.product.ean;
    cartData.uds = this.uds;
    cartData.provider = this.provider.id;

    this.api.cart.insertCurrentCart(cartData).subscribe(
      data=>{
        if (data && data.code == 201){
          this.notification.success(["Producto añadido al carrito"]);
          window.location.reload();
        }else{
          this.notification.error(["No se ha podido añadir el producto al carrito"]);
        }
      },
      error=>{
        this.notification.error(["No se ha podido añadir el producto al carrito"]);
      }
    );
    /*let productAdd={
      provider: this.provider.id,
      ean: this.product.ean,
      uds: this.uds
    };

    let cart = this.cookie.get("cart");
    let providerAdded=false;
    let productAdded=false;
    let cartjson:Array<any>=[];*/

    /*if(cart){
      cartjson=JSON.parse(cart);
      cartjson.forEach(provider => {
        if(provider.id==productAdd.provider){
          provider.products.forEach(product =>{
            if(product.ean==productAdd.ean){
              product.uds=productAdd.uds;
              productAdded=true;
            }
          });
          if(!productAdded){
            provider.products.push({
              ean: productAdd.ean,
              uds: productAdd.uds
            });
          }
          providerAdded=true;
        }
      });
    }

    if(!providerAdded) {
      cartjson.push({
        id: productAdd.provider,
        products: [
          {
            ean: productAdd.ean,
            uds: productAdd.uds
          }
        ]
      });
    }*/
    
    //this.cookie.set("cart", JSON.stringify(cartjson), 1, "/", undefined, true, "None");

    //this.router.navigate(["/cart"]);
    /*this.notification.success(["Producto añadido al carrito"]);
    window.location.reload();*/
  }

  private addUds(){
    if(this.product.uds_caja){
      this.uds+=this.product.uds_caja;
    }else{
      this.uds++;
    }
  }

  private removeUds(){
    if(this.uds>1){
      if(this.product.uds_caja){
        this.uds-=this.product.uds_caja;
      }else{
        this.uds--;
      }
    }
  }

  back(){
    this.location.back();
  }

}