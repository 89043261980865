<main-header *ngIf="user && user.role==roleProvider"></main-header> <!---is es rol provider-->
<div class="container">
    <header-admin *ngIf="id" title="Editar contacto"></header-admin>
    <header-admin *ngIf="!id" title="Añadir un nuevo contacto"></header-admin>

    <form>
        <!--Nombre-->
        <div class="campo">
            <div class="etiqueta"> 
                <div class="wrapper">
                    <div class="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/></svg>
                    </div>
                    <div class="text">Nombre</div>
                </div>
            </div>
            <div class = "input">
                <input class = "inputc" type = "text" name="name" placeholder="Nombre del contacto" 
                [(ngModel)]="contact.name" #ctrl="ngModel">
            </div>
        </div>
        <!--Proveedor-->
        <!--div class="campo" *ngIf="user && user.role == 2">
            <div class="etiqueta"> 
                <div class="wrapper">
                    <div class="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/></svg>
                    </div>
                    <div class="text">Proveedor</div>
                </div>
            </div>
            <div class = "input">
                <select class="inputc" name="provider" [(ngModel)]="contact.provider" #ctrl="ngModel" ng-init= "'none'">
                    <option [ngValue]="none" disabled>Selecciona al proveedor</option>
                    <option *ngFor="let provid of providers" value="{{provid.id}}">{{provid.name}}</option>
                </select>
            </div>
        </div-->
         <!--Phone-->
         <div class="campo">
            <div class="etiqueta"> 
                <div class="wrapper">
                    <div class="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/></svg>
                    </div>
                    <div class="text">Teléfono</div>
                </div>
            </div>
            <div class = "input">
                <input class = "inputc" type = "text" name="phone" placeholder="Teléfono del contacto" 
                [(ngModel)]="contact.phone" #ctrl="ngModel">
            </div>
        </div>
         <!--Email-->
         <div class="campo">
            <div class="etiqueta"> 
                <div class="wrapper">
                    <div class="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"/></svg>
                    </div>
                    <div class="text">Email</div>
                </div>
            </div>
            <div class = "input">
                <input class = "inputc" type = "text" name="email" placeholder="Correo electrónico del contacto" 
                [(ngModel)]="contact.email" #ctrl="ngModel">
            </div>
        </div>
         <!--Oficina central?-->
         <div class="campo">
            <div class="etiqueta"> 
                <div class="wrapper">
                    <div class="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 7V3H2v18h20V7H12zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zm-2-8h-2v2h2v-2zm0 4h-2v2h2v-2z"/></svg>
                    </div>
                    <div class="text">Oficina Central?</div>
                </div>
            </div>
            <div class = "input">
                <select class="inputc" name="central" [(ngModel)]="contact.central" #ctrl="ngModel">
                    <option [ngValue]="" disabled>¿Es sede central?</option>
                    <option value="1">Sí</option>
                    <option value="0">No</option>
                </select>
            </div>
        </div>
         <!--Provincia-->
         <div class="campo width-all">
            <div class="etiqueta"> 
                <div class="wrapper">
                    <div class="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/></svg>
                    </div>
                    <div class="text">Provincia</div>
                </div>
            </div>
            <div class = "input provinces" *ngIf="!contact || !contact.id">
                <div *ngFor="let provin of provinces">
                <input type="checkbox" value="{{provin.name}}" (click)="addProvinces($event.target.checked, provin.id)"> {{provin.name}}
                </div>
            </div>
            <div class="input" *ngIf="contact && contact.id">
                <select class="inputc" name="province" [(ngModel)]="contact.province" #ctrl="ngModel">
                    <option [ngValue]="" disabled>Selecciona la provincia</option>
                    <option *ngFor="let provin of provinces" value="{{provin.id}}">{{provin.name}}</option>
                </select>
            </div>
        </div>
       
    </form>

    <div class="actions">
        <p *ngIf="error" style="color: red;">{{error}}</p>

        <div class="action back" (click)="back()">
            <div class="wrapper">
                <div class="icon"></div>
                <div class="text">Atrás</div>
            </div>
        </div>

        <div class="action save" (click)="saveContact()">
            <div class="wrapper">
                <div class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/></svg></div>
                <div class="text">Guardar</div>
            </div>
        </div>
    </div>
</div>