import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/api/api.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.less']
})
export class SearchComponent implements OnInit {
  public products: any[];
  public seekerText:string;

  constructor(private router:Router, private route:ActivatedRoute, private api:ApiService) { }

  ngOnInit() {
    this.route.params.subscribe(
      data => {
        if(data.seekerText && data.seekerText.length>5){
          this.seekerText=data.seekerText;
          this.api.product.getProductsByName(data.seekerText).subscribe(
            data => {
              this.products=data.response;
            },
            error => {
              if(error.error.code==404){
                this.router.navigate(["/"]);
              }
            }
          );
        }
        else{
          this.router.navigate(["/"]);
        }
      },
      error => {console.error(error);}
    );
  }

}
