import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfigService } from 'src/app/config/config.service';
import { Product } from './product';

@Injectable()
export class ProductService {
  private http;
  private server;
  private url = "/api/"+this.config.version+"/product";

  constructor(private httpClient: HttpClient, private config: ConfigService) {
    this.server=this.config.getServer();
    this.http=this.httpClient;
  }

  /*public getProducts(){
    return this.http.get(this.server + this.url);
  }*/

  public getProductByRef(id:number, ref:string){
    return this.http.get(this.server + this.url + "/" + id + "/" + ref);
  }

  public getProductsByName(name:string){
    return this.http.get(this.server + this.url + "/" + name);
  }

  public getProductsByProvider(id:number, order:number){
    return this.http.get(this.server + this.url + "/" + id + "/order=" + order);
  }

  public insertProduct(obj: Object){
    const _headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const _params = null;

    const httpOptions = {
      _headers,
      _params,
      withCredentials: false
    };

    return this.http.post(this.server + this.url, {ref: obj["ref"], provider: obj["provider"], ean:obj["ean"], 
    name:obj["name"], price:obj["price"], price_offer:obj["price_offer"], pvpr:obj["pvpr"], 
    description:obj["description"], attr_conf:obj["attr_conf"], attr_value:obj["attr_value"],
    uds_caja:obj["uds_caja"], url_info:obj["url_info"], images:obj["images"]}, httpOptions );
  }

  public updateProduct(data: Product){
    const _headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    //const _params = null;

    const httpOptions = {
      _headers
     // _params,
      //withCredentials: false
    };
    
    return this.http.put(this.server + this.url + "/" + data.provider + "/" + data.ean, data, httpOptions );
  }

  public deleteProduct(ean: string, provider: number){
    const _headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const _params =null;

    const httpOptions = {
      _headers,
      _params,
      withCredentials: false
    };

    return this.http.delete(this.server + this.url + "/" + provider + "/" + ean,httpOptions); 
  }
}
