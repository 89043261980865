import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../api/api.service';
import { Product } from '../api/models/product/product';
import * as JsonToXml from "js2xmlparser";
import * as importedSaveAs from "file-saver";

@Component({
  selector: 'app-products-xml',
  templateUrl: './products-xml.component.html',
  styleUrls: ['./products-xml.component.less']
})
export class ProductsXmlComponent implements OnInit {
  public code:string;
  public infoProducts:string;
  private products:Array<Product>=[];
  public productsXml;

  constructor(private route:ActivatedRoute, private api: ApiService) { 
    let that = this;
    this.route.params.subscribe(
      data=>{
        that.code = data.code;
        that.getProducts();
      }
    );
  }

  ngOnInit() {
  }

  getProducts(){
    let that = this;
    this.api.product.getProductsByProvider(parseInt(this.code), 0).subscribe(
      data=>{
        that.products = data.response;
        that.exportToXml();
      },
      error=>{
        if (error.error.code==404){
          this.infoProducts="Información de producto no disponible para este proveedor.";
        }
        if(error.error.code==500){
          //this.notification.error(err.error.message);
          console.log(error);
        }
      }
    );
  }

  exportToXml(){
    let parser = new DOMParser();
    let productsJson =JSON.parse(JSON.stringify(this.products));
    let xmlDoc = JsonToXml.parse("products", productsJson);

    this.productsXml = xmlDoc;
    //document.getElementById('producst')

    let blob = new Blob([this.productsXml], {type: 'application/xml'});
    importedSaveAs.saveAs(blob, "products"+this.code+".xml");
  }

}
