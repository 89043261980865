<div class="container">
  <header-admin title="Accesos de usuario"></header-admin>
  <unifersa-tabla [headers]="headers">
    <div class="row" *ngFor="let acc of access">
      <div class="access">
        <div class="wrapper">
          <div class="init">{{acc.contact.charAt(0)}}</div>
          <div class="data">
            <div class="title">{{acc.contact}}</div>
            <div class="subtitle">{{acc.email}}</div>
          </div>
        </div>
      </div>
      <div class="ip">{{acc.ip}}</div>
      
      <div class="documents">
        <div class="numpages">
          <span class="label number" title="Páginas visitadas">{{acc.pages}} páginas visitadas</span>
        </div>
        <div class="docs">
          <span class="label" *ngIf="acc.documents.length==0">Sin documentos</span>
          <span class="label" *ngIf="acc.documents.length>0"><a routerLink="/admin/user/{{userId}}/access/{{acc.ip}}">{{acc.documents.length}} documentos descargados</a></span>
          <!--span [ngClass]="{'label': true, 'condiciones': doc.document == 'condiciones', 'productos': doc.document == 'productos'}" 
            *ngFor="let doc of acc.documents" title="Documento {{doc.document == 'condiciones' ? 'condiciones' : doc.document == 'productos' ? 'productos' : ''}}">{{doc.provider}}</span-->
        </div>        
      </div>

      <div class="date">{{acc.date | date: 'dd/MM/yyyy hh:mm'}}</div>
      <div class="date" *ngIf="acc.last">{{acc.last | date: 'dd/MM/yyyy hh:mm'}}</div>
      <div class="date" *ngIf="!acc.last">------</div>
      <div class="actions">
        <div *ngIf="!acc.enable" class="action enable" (click)="setAccess(acc.ip)" title="Activar">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><circle fill="#F00" cx="12" cy="12" r="8"/></svg>
        </div>
        <div *ngIf="acc.enable" class="action disable" (click)="setAccess(acc.ip)" title="Desactivar">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><circle fill="#0F0" cx="12" cy="12" r="8"/></svg>
        </div>
      </div>
    </div>
  </unifersa-tabla>
</div>