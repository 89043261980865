import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

@Component({
  selector: 'unifersa-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.less']
})
export class MainComponent implements OnInit {

  constructor(private cookies: CookieService, private router: Router) {
    if(!this.cookies.check("userid")){
      this.router.navigate(["/login"]);
    }
  }

  ngOnInit() {
    
  }

  onActivate(event) {
    document.body.scrollTop = 0;
  }

}
