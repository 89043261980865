import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { CookieService } from "ngx-cookie-service";

import {ConfigService} from "../../../config/config.service";
/*import { User } from './user';
import { Observable, of } from 'rxjs';*/

@Injectable()
export class UserService {
  private http;
  private server;
  private url = "/api/"+this.config.version+"/user";

  constructor(private httpClient: HttpClient, private config: ConfigService, private cookie: CookieService) {
    this.server=this.config.getServer();
    this.http=this.httpClient;
  }

  public login(email:string, pass: string){
    const _headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const _params = new HttpParams()
    .set('email', email)
    .set('pass', pass );

    const httpOptions = {
      _headers,
      _params,
      withCredentials: false
    };
    
    return this.http.post(this.server + this.url + "/login", {email: email, pass:pass}, httpOptions);
  }

  public verify(id:number, code:number){
    const _headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const _params = new HttpParams();

    const httpOptions = {
      _headers,
      _params,
      withCredentials: false
    };
    return this.http.post(this.server + this.url + '/verify', {user: id, code: code}, httpOptions);
  }

  public setIdCookie(data){
    this.cookie.set("userid", data, 30, "/", undefined, true, "None");
  }

  public setNameCookie(data){
    this.cookie.set("username", data, 30, "/", undefined, true, "None");
  }

  public setRoleCookie(data){
    this.cookie.set("role", data, 30, "/", undefined, true, "None");
  }

  public getUsers(){
    return this.http.get(this.server + this.url);
  }

  public getUsersId(id: number){
    return this.http.get(this.server + this.url + '/'+ id);
  }

  public getUsersEmail(id: string){
    return this.http.get(this.server + this.url + '/'+ id + '/email');
  }
  
  public getUserRecPass(id: string){
    return this.http.get(this.server + this.url + '/'+ id + '/recpass');
  }

  public deleteUser(id: number){
    const _headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const _params = null;

    const httpOptions = {
      _headers,
      _params,
      withCredentials: false
    };

    return this.http.delete(this.server + this.url + "/" + id, httpOptions);  
  }

  public insertUser(email:string, contact:string,  role:number, pass:string, business:string, code:string, name_business:string, address_business:string, cp:number,
     locality:string,  province:string, nif:string, credito:number, control:number, country:string,phone_number:string,provider: number){
    const _headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const _params = null;

    const httpOptions = {
      _headers,
      _params,
      withCredentials: false
    };
    
    return this.http.post(this.server+this.url, 
      {email:email, contact:contact,  role:role, pass:pass, business:business, code:code, name_business:name_business, address_business:address_business, cp:cp, 
      locality:locality,  province:province, nif:nif, credito:credito, control:control, country:country,phone_number:phone_number, provider: provider},
      httpOptions);
  }
  
  public updateUser(id: number, data:any[]){
    const _headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const httpOptions = {
      _headers
    };
    
    return this.http.put(this.server + this.url + "/"+id, data, httpOptions);
  }

  public getAccess(id: number){
    return this.http.get(this.server + this.url + '/'+ id + '/access');
  }

  public setAccess(id:number, ip:string){
    const _headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const _params = null;

    const httpOptions = {
      _headers,
      _params,
      withCredentials: false
    };

    return this.http.post(this.server+this.url+"/access", 
      {'user': id, 'ip':ip},
      httpOptions);
  }

  public checkThisIp(id: number){
    return this.http.get(this.server + this.url + "/"+ id + '/checkip');
  }

  public sumPages(id:number){
    const _headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const _params = null;

    const httpOptions = {
      _headers,
      _params,
      withCredentials: false
    };

    this.checkThisIp(id).subscribe(
      data=>{
        if (!data.response || data.response.length==0){
          this.logout();
        }
      },
      error=>{
        this.logout();
      }
    )
    return this.http.post(this.server+this.url+"/sumPages", 
      {'user': id},
      httpOptions);
  }

  public downloadPages(id:number, provider:number, document: string){
    const _headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const _params = null;

    const httpOptions = {
      _headers,
      _params,
      withCredentials: false
    };

    return this.http.post(this.server+this.url+"/downloadDocument", 
      {'user': id, 'provider': provider, 'document': document},
      httpOptions);
  }

  private logout(){
    this.cookie.delete("userid");
    this.cookie.delete("username");
    this.cookie.delete("role");
    
    window.location.href="/login";
  }
}