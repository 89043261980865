import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from 'src/app/notification/notification.service';

@Component({
  selector: 'app-access',
  templateUrl: './access.component.html',
  styleUrls: ['./access.component.less']
})
export class AccessComponent implements OnInit {
  userId:number;
  access:any[];
  headers:any[];

  constructor(private api:ApiService, private aRoute:ActivatedRoute, private notification:NotificationService) {
    this.userId=parseInt(aRoute.snapshot.paramMap.get('id'));
    this.api.user.getAccess(this.userId).subscribe(
      data => {
        this.access=data.response;
      },
      error => {
        console.error(error);
      }
    );
  }

  ngOnInit() {
    this.headers=[
      {
        name: "Usuario",
        class: "min-25"
      },
      {
        name: "IP",
        class: "min-100"
      }, 
      {
        name: "Documentos",
        class: "min-447"
      },
      {
        name: "Primer acceso",
        class: "min-180"
      },
      {
        name: "Último acceso",
        class: "min-180"
      },
      {
        name: "",
        class: "min-80"
      }
    ];
  }

  setAccess(ip){
    this.api.user.setAccess(this.userId, ip).subscribe(
      data => {
        this.access.forEach(element => {
          if(element.ip==ip){
            if(element.enable){
              this.notification.success("La ip "+ip+" ha sido bloqueada");
            }
            else{
              this.notification.success("La ip "+ip+" ha sido activada");
            }
            element.enable=(!element.enable);
          }
        });
      },
      error => {
        console.error(error);
      }
    );
  }

}
