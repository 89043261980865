import { Component, OnInit, OnChanges, SimpleChanges, Input} from '@angular/core';
import { User } from '../../api/models/user/user';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/notification/notification.service';
import { ApiService } from 'src/app/api/api.service';
import { Provider } from 'src/app/api/models/provider/provider';
import { Md5 } from 'ts-md5/dist/md5';
import { Province } from 'src/app/api/models/contact/contact';

@Component({
  selector: 'app-user-data',
  templateUrl: './user-data.component.html',
  styleUrls: ['./user-data.component.less']
})
export class UserDataComponent implements OnInit {
  user : User;
  public n: number;
  providers:Provider[];
  provinces:Array<Province>=[];

  constructor(private api: ApiService, private _route: ActivatedRoute, private router:Router, private notification: NotificationService) { 
   this.n = parseInt(this._route.snapshot.paramMap.get('id'));
    if (this.n){
      this.getUserId(this.n);
    }else{
      //console.log(this.user);
      this.user=new User;
    }

    this.api.provider.getProviders().subscribe(
      data =>{
        this.providers=data.response;
      },
      error =>{
        console.error(error);
      }
    );

    this.getProvinces();

   //private location: Location;
  }

  ngOnInit() {  }
  
  getUserId(id: number): void{
    this.api.user.getUsersId(id).subscribe(user=>{
      if(user.response!=null){
        this.user = user.response;
        console.log(this.user);
      } else{
        console.log("Error with user by id.");
      }
    });
  }

  getProvinces(){
    let that = this;
    let params = new Map<string,any>();
    this.api.contact.getProvinces().subscribe(
      data=>{
        that.provinces = data.response;
      }
    );
  }

  private updateUser(): void{ 
    if (this.user.control == null) this.user.control = 0;
    if (this.user.credito == null)  this.user.credito = 0;
    
    if (this.user["passv"] == null){
      this.user["pass"] = null;
      this.updateUserFunction();
    }else{
      if (this.user["pass"] == this.user["passv"]){
        this.updateUserFunction();
      }else{
        this.notification.error("Contraseña no válida.");
      }
    }
  
  }

  private updateUserFunction():void{
    this.api.user.updateUser(this.n, [this.user]).subscribe(
      ()=> {
        this.notification.success("Usuario actualizado.");
        this.router.navigate(["/admin"]);
      },
      error =>{
        this.notification.error("Ha ocurrido un error.");
        console.error(error);
      }
    );
  }

  private insertUser(): void{ 
    if (this.user["pass"] == this.user["passv"] || this.user['pass'] == ''){
      if (this.user.control == null) this.user.control = 0;
      if (this.user.credito == null)  this.user.credito = 0;
      if (this.user['pass'] == null || this.user['pass'] == ""){
        let hash = new Md5();
        let pass = hash.appendStr(this.user['email']).end();
        this.user['pass'] = pass;
      } 
      
      this.api.user.insertUser(this.user["email"], this.user["contact"], this.user["role"], this.user["pass"], this.user["business"],
        this.user["code"], this.user["name_business"], this.user["address_business"], this.user["cp"], this.user["locality"],  this.user["province"], 
        this.user["nif"], this.user["credito"], this.user["control"], this.user["country"], this.user["phone_number"], this.user["provider"])
      .subscribe(
        data=>{
          this.notification.success("Usuario insertado.");
          this.router.navigate(["/admin"]);
        },
        error =>{
          if (error.error.code == 500 && error.error.message.includes('Integrity constraint violation: 1062 Duplicate entry') && error.error.message.includes("email")){
            this.notification.error("Este email ya está vinculado a otro usuario.");
          }else{
            this.notification.error("Ha ocurrido un error.");
          }
          console.log(error);
        }
        );
    }else{
      this.notification.error("Contraseña no válida.");
    }
  }

  saveUser(): void{
    if(this.n){
      this.updateUser();
    }else{
      this.insertUser();
    }
  }

}
