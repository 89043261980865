import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/api/api.service';
import { Contact, Province } from 'src/app/api/models/contact/contact';
import { User } from 'src/app/api/models/user/user';
import { NotificationService } from 'src/app/notification/notification.service';

@Component({
  selector: 'app-provider-contacts',
  templateUrl: './provider-contacts.component.html',
  styleUrls: ['./provider-contacts.component.less']
})
export class ProviderContactsComponent implements OnInit {
  public user:User;
  public n:number; 
  public roleProvider:number = 3;
  public provinces: Array<Province>=[];
  public contacts: Array<Contact>=[];
  public idProvider: number = 0;
  public nameProvider: string= null;

  constructor(private api: ApiService,private cookie: CookieService, private notification:NotificationService, private router: Router, private route: ActivatedRoute) {
    this.n = parseInt(this.cookie.get('userid'));
    this.getUser();

    this.route.params.subscribe(
      data => {
        if (data.id){
          this.idProvider = data.id;
          this.api.provider.getProviderId(data.id).subscribe(
            data=>{
              this.nameProvider = data.response.name
            }
          );
        }else
          this.idProvider = 0;   
      }
    );
  }

  ngOnInit(): void {  }

  getUser(){
    let that = this;
    this.api.user.getUsersId(this.n).subscribe(
      data=>{
        if (data && data.response!=null){
          that.user = data.response;
          that.user['pass'] = null;
          that.getContacts();
          that.getProvinces();
        }
      },
      error=>{
        console.log(error);
      }
    );
  }

  getProvinces(){
    let that = this;
    this.api.contact.getProvinces().subscribe(
      data=>{
        that.provinces = data.response;
      }
    );
  }

  getContacts(){
    let that = this;
    let proveedor = this.user.role == this.roleProvider ? this.user.provider : this.idProvider;
    this.api.contact.getContactByProvider(proveedor).subscribe(
      data=>{
        if (data.response)
          that.contacts = data.response;
      },
      err=>{
        console.log(err);
      }
    );
  }

  goTo(id=null){
    if (id){
      if (this.idProvider != 0)
        this.router.navigate(['/admin/provider/'+this.idProvider+"/contacts/"+id]); 
      else
        this.router.navigate(['/my-contacts/'+id]);
    }
    else{
      if (this.idProvider != 0)
        this.router.navigate(['/admin/provider/'+this.idProvider+"/contacts/new"]);
      else
        this.router.navigate(['/my-contacts/new']);
    }
  }

  deleteContact(id){
    let that = this;
    this.api.contact.deleteContact(id).subscribe(
      data=>{
        if (data.code==200){
          that.notification.success("Contacto eliminado.");
          window.location.reload();
        }else{
          that.notification.error("Se ha producido un error.")
        }
      },
      err=>{
        console.log(err);
        that.notification.error("Se ha producido un error.")
      }
    )
  }

}
