import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/notification/notification.service';
import { OrderService } from '../../api/models/order/order.service';
import { Order } from '../../api/models/order/order';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/api/api.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.less']
})
export class OrderComponent implements OnInit {
  orders: Order[];
  p: number = 1;
  headers:Array<any>;
  //products: any[];
  isAsociados: boolean = false;
  isProvider: boolean = false;
  path: string = "";
  id: number = 0;
  title: string = "Todos los Pedidos";
  desc:Array<boolean> = [false, false, false, false, false];
  orderDate:boolean = false;
  providerName="";
  objectKeys = Object.keys;
  sortName = null;

  constructor(private orderService: OrderService, private route:ActivatedRoute, private notification: NotificationService, private router:Router, private cookie:CookieService, private api: ApiService) { 
    let rutas = this.route.url['value'];
    
    if (rutas.length == 1){
      this.getOrders();
    }else if (rutas.length >= 2){
      this.path = rutas[1].path;
      if (rutas[2]){
        this.id = parseInt(rutas[2].path);
      }
      if (this.path == 'asociado'){
        this.isAsociados = this.id == 0;
        this.title = "Pedidos de Asociados";
        this.getOrdersByAsociado()
      }
      if (this.path == 'proveedor'){
        if (this.id>0){
          this.api.provider.getProviderId(this.id).subscribe(
            data=>{
              this.providerName = data.response.name;
            }
          );
        }        
        this.isProvider = this.id == 0;
        this.title = "Pedidos de Proveedores";
        this.getOrdersByProveedor()
      }

    }
  }

  ngOnInit() {
    this.headers=[
      {
        name: "Cliente",
        class: "min-800"
      },
      {
        name: "Fecha",
        class: "min-100"
      },
      {
        name: "",
        class: ""
      }
    ];
    this.sortName = {'user':'nombre', 'date':'fecha'};

    if (this.isAsociados || this.isProvider){
      let nameTitle = this.isAsociados ? 'Cliente' : 'Proveedor';
      this.headers = [
        {
          name: nameTitle,
          class: "min-550"
        },
        {
          name: "Nº Pedidos",
          class: "min-200",
        },
        {
          name: "Importe Total",
          class: "min-200"
        },
        {
          name: "",
          class: ""
        }
      ]

      this.sortName = {'user': 'nombre', 'date':'nº pedidos', 'importe':'importe'};
    }  
    
    if (this.id>0){
      this.headers = [
        {
          name: "Nº",
          class: "min-50"
        },
        {
          name: 'Cliente',
          class: "min-500"
        },
        {
          name: 'Proveedor',
          class: "min-200"
        },
        {
          name: 'Importe',
          class: "min-100"
        },
        {
          name: "Fecha",
          class: "min-200",
        },
        {
          name: "",
          class: ""
        }
      ];

      this.sortName = {'id':'nº', 'user':'nombre', 'provider':'proveedor', 'lastname': 'importe', 'date':'fecha'};
    }
  }

  getOrders():void{
    this.orderService.getOrders().subscribe(
      data => {
        if (data.response){
          if(parseInt(this.cookie.get("role"))==3){
            this.orders=[];
            this.api.user.getUsersId(parseInt(this.cookie.get("userid"))).subscribe(
              user => {
                data.response.forEach(element => {
                  this.api.order.getProductsOrder(element.id).subscribe(
                    order => {
                      order.response.forEach(product => {
                        if(product.provider==user.response.provider){
                          this.orders.push(element);
                        }
                      });
                    },
                    error => {
                      console.error(error);
                    }
                  );
                });
              },
              error => {
                console.error(error);
              }
            );
          }
          else{
            this.orders = data.response;
          }
        }
      },
      error =>{console.log(error);
        this.notification.error("Ha ocurrido un error al cargar los pedidos.");
      }
    );
  }

  getProductsOrder(id:number):void{
    if (this.isAsociados || this.isProvider){
      this.router.navigate(['/admin/order/'+this.path+'/'+id]);
    }else{
      this.orderService.getProductsOrder(id).subscribe(
        data =>{
          if (data!=null){
            this.router.navigate(["/admin/order/"+id]);
          }else{
            this.notification.error("Ha ocurrido un error con el pedido "+ id);
          }
        },
        error=>{
          console.log(error.error);
        }
      );
    }    
  }

  sortOrder(type,i){
    if (!this.desc[i]){
      this.orders.sort(function(a,b){
        if (a[type]==b[type]) return 0;
        if (a[type]>b[type]) return 1;
        else return -1;
      });
    }else{
      this.orders.sort(function(a,b){
        if (a[type]==b[type]) return 0;
        if (a[type]>b[type]) return -1;
        else return 1;
      });
    }

    this.desc[i] = !this.desc[i];
    
    /*if (type==1){ //nombre
      if (!this.orderName){
        this.orders.sort(function(a,b){
          if (a['user'] == b['user']) return 0;
          if (a['user']>b['user']) return 1;
          else return -1;
        });
      }else{
        this.orders.sort(function(a,b){
          if (a['user'] == b['user']) return 0;
          if (a['user']>b['user']) return -1;
          else return 1;
        });
      }
      this.orderName = !this.orderName;
    }else if (type==2){//fecha
      if (!this.orderDate){
        this.orders.sort(function(a,b){
          if (a['date'] == b['date']) return 0;
          if (a['date']>b['date']) return 1;
          else return -1;
        });
      }else{
        this.orders.sort(function(a,b){
          if (a['date'] == b['date']) return 0;
          if (a['date']>b['date']) return -1;
          else return 1;
        });
      }
      
      this.orderDate = !this.orderDate;
    }*/
  }

  getOrdersByAsociado(){
    if (this.id == 0){
      this.api.order.getClientsWOrders().subscribe(
        data=>{
          this.orders = data.response;
          this.orders.forEach(ele=>{
            ele['importe'] = ele['importe'].toFixed(4);
          })
        }
      );
    }else if (this.id>0){     
      let that = this;
      this.getDataUser(this.id, function(user){
        that.api.order.getOrdersByUser(that.id).subscribe(
          data=>{
            if (data.response){
              that.orders = data.response;
              that.orders.forEach(ele => {
                ele.user =  user.business;
                ele['provider'] = ele.email;
                ele.email = user.email;
                ele.lastname = Number(ele.lastname).toFixed(4);
              });
            }
          }
        );
      }); 
    }    
  }

  getOrdersByProveedor(){
    if (this.id == 0){
      this.api.order.getProvidersWOrders().subscribe(
        data=>{
          this.orders = data.response;
          this.orders.forEach(ele=>{
            ele['importe'] = ele['importe'].toFixed(4);
          })
        }
      );
    }else if (this.id>0){
      let that = this;
      this.api.order.getOrdersByProvider(this.id).subscribe(
        data=>{
          if(data.response){
            let orders = data.response;
            orders.forEach((ele, ind)=>{
              that.getOrderId(ele, function(order){
                ele.id = order;
              });
              that.getDataUser(ele.user.split(';')[0], function(user){
                ele.user = user.contact;
                ele.email = user.email;
                ele['provider'] = that.providerName;
                ele.lastname = Number(ele.lastname).toFixed(4);
                if (ind+1 == orders.length){
                    that.orders = orders;
                }
              }); 
            });
          }
        }
      )
    }    
  }

  getDataUser(id, response:((resp)=>any)){
    this.api.user.getUsersId(id).subscribe(
      data=>{
        if (data.response){
          response(data.response);
        }
      }
    );
  }

  getOrderId(order, response:((resp)=>any)){
    this.api.order.getOrderById(order.id).subscribe(
      data=>{
        if (data.response){
          response(data.response.id);
        }else{
          response(order.id);
        }
      }
    );
  }

}
