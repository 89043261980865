import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/api/api.service';

@Component({
  selector: 'app-aviso-legal',
  templateUrl: './aviso-legal.component.html',
  styleUrls: ['./aviso-legal.component.less']
})
export class AvisoLegalComponent implements OnInit {

  constructor(private api:ApiService, private cookie:CookieService) {
    api.user.sumPages(parseInt(cookie.get("userid"))).subscribe();
  }

  ngOnInit() {
  }

}
