import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from 'src/app/config/config.service';

@Injectable({
  providedIn: 'root'
})
export class ApiProviderService {
  private http;
  private server;
  private url = "/api/"+this.config.version+"/api"; 

  constructor(private httpClient: HttpClient, private config: ConfigService) { 
    this.server=this.config.getServer();
    this.http=this.httpClient;
  }

  public getApis(){
    return this.http.get(this.server + this.url);
  }
}
