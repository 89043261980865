import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api/api.service';
import { User } from 'src/app/api/models/user/user';
import { NotificationService } from 'src/app/notification/notification.service';

@Component({
  selector: 'app-remember',
  templateUrl: './remember.component.html',
  styleUrls: ['./remember.component.less']
})
export class RememberComponent implements OnInit {
  public emailSend:string;
  public success:boolean = false;
  
  constructor(private api: ApiService, private notification: NotificationService, private router: Router) { }

  ngOnInit() {
  }

  back(){
    this.router.navigate(["/"])
  }

  sendMail(){
    let that = this;//add @ a htaccess prod
    this.api.user.getUsersEmail(this.emailSend).subscribe(
      data=>{
        if (data && data.code == 200){
          let user: User = data.response;
          user.rec_pass = 'rec_pass';
          user['pass'] = null;
          that.api.user.updateUser(user.id, [user]).subscribe(
            data=>{
              if (data.code == 200){
                that.success = true;  
                //that.back();
              }else{
                that.success = false;
                console.log(data);
              }
            },
            error=>{
              that.success = false;
              console.log(error);
            }
          )
        }
      }, 
      error=>{console.log(error);
        if (error.error.code == 404){
          that.notification.error(error.error.message);
        }
      }
    )
  }
}
