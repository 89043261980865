import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class NotificationService {

  constructor(private toastr: ToastrService) { }

  success($message){
    this.toastr.success($message,'¡Éxito!');
  }

  error($message){
    this.toastr.error($message,'¡Error!');
  }
}
