<main-header></main-header>
<div class="container">
  <div class="order" *ngIf="cart && cart.length>0">
    <div class="nCart" *ngIf="cart.length==1">Tienes {{cart.length}} producto en tu carrito</div>
    <div class="nCart" *ngIf="cart.length>1">Tienes {{cart.length}} productos en tu carrito</div>
    <div class="provider" *ngFor="let provider of providers; let i = index">
      <h3>{{provider.name}}</h3>
      <div class="products">
        <div class="headers">
          <div class="dta img hideMobile"></div>
          <div class="data double name">Producto</div>
          <div class="data uds">Unidades</div>
          <div class="data price hideMobile">Precio</div>
          <div class="data total hideMobile">Total</div>
          <div class="data half actions hideMobile"></div>
        </div>
        <div class="content">
          <div class="product" *ngFor="let product of provider.products; let j = index">
              <div class="data image">
                <img [src]="product.images[0]" title="Imagen Producto" alt="Error Imagen"  onerror="this.src='/assets/no-image.png';" />
              </div>
              <div class="mobile-wrapper">
                <div class="wrapper">
                  <div class="data double name">{{product.name}}</div>
                  <div class="data uds">
                    <div class="form-input number-input">
                      <input type="number" name="uds" min="{{product.uds_caja}}" [(ngModel)]="product.uds" readonly/>
                      <div class="controls">
                        <div class="wrapper">
                          <div class="decrement" (click)="removeUds(i, j)">-</div>
                          <div class="increment" (click)="addUds(i, j)">+</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="data price" *ngIf="product.price!=0">{{numberPointToCo(product.price)}} €</div>
                  <div class="data price" *ngIf="product.price==0">Consultar precio</div>
                  <div class="data total hideMobile" *ngIf="product.price!=0">{{mathCeil(product.price, product.uds)}} €</div>
                  <div class="data total hideMobile" *ngIf="product.price==0">Consultar precio</div>
                  <div class="data half actions">
                    <div class="action" (click)="deleteOrder(product.ean)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="more">
          <div class="title">Observaciones para {{provider.name}}</div>
          <div class="input">
            <input type="text" name="more" [(ngModel)]="provider.more"/>
          </div>
        </div>
        <div class="actions">
          <div class="action">
            <div class="title">Total:</div>
            <div class="value" *ngIf="getSubtotal(i)!=0">{{getSubtotal(i)}} €</div>
            <div class="value" *ngIf="getSubtotal(i)==0">Consultar precio</div>
          </div>
          <div *ngIf="credito && user.role!=5; else second">
            <div [hidden]="!hasContacts[i]" class="action send cartButton" (click)="sendOrder(provider.id)">
              <div class="text" >Enviar propuesta de pedido</div>
            </div>
            <div [hidden]="hasContacts[i]" class="action notsend cartButton">
              <div>Pedido online no disponible todavía para este proveedor</div>
            </div>
          </div>
          <ng-template #second  class="action notsend cartButton">
            <div *ngIf="!credito" class="action notsend">Crédito excedido, pendiente de autorización por parte de UNIFERSA.</div>
            <div *ngIf="user.role==5" class="action notsend">Esta versión demo no permite enviar un pedido.</div>
          </ng-template>
        </div>
      </div>
    </div>
    <!-- Deshabilitar enviar todo -->
    <!-- <div class="footer" *ngIf="providers.length>1">
      <div class="more">
        <div class="title">Observaciones generales</div>
        <div class="input">
          <input type="text" name="more" [(ngModel)]="providers.more"/>
        </div>
      </div>
      <div class="actions">
        <div class="action">
          <div class="title">Total:</div>
          <div class="value" *ngIf="getTotal()!=0">{{getTotal()}} €</div>
          <div class="value" *ngIf="getTotal()==0">Consultar precio</div>
        </div>
       
         <div  *ngIf="credito && getTotal()!='0,0000'" class="action send" (click)="sendOrder()">
          <div class="text" >Enviar todo</div>
        </div>  
        <div *ngIf="getTotal()=='0,0000'" >
          <div class="action notsend">Pedido online total no disponible</div>
        </div>
        <div *ngIf="!credito" class="action notsend cartButton">
          <div >Crédito excedido, pendiente de autorización por parte de UNIFERSA.</div>
        </div>
      </div>
    </div> -->
  </div>
  <div class="empty-cart text-center" *ngIf="!cart || cart.length==0">
    No hay productos en el carrito.
  </div>
</div>
<main-footer></main-footer>