import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router, NavigationEnd } from '@angular/router';

import { filter } from 'rxjs/operators';
declare var gtag;

@Component({
  selector: 'unifersa',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  logged:boolean=false;

  constructor( private cookieService: CookieService, private router: Router ) {
    /*this.router.events.subscribe(event=>{
      if (event instanceof NavigationEnd){
        (<any>window).gtag('set', 'page', event.urlAfterRedirects);
        //ga('set', 'page', event.urlAfterRedirects);
        (<any>window).gtag('send', 'pageview');
      }
    });*/
    const navEndEvent$= router.events.pipe(
      filter(e=>e instanceof NavigationEnd)
    );
    navEndEvent$.subscribe((e:NavigationEnd)=>{ //send_page_view
      gtag('config', 'UA-19263592-4', {'page_path':e.urlAfterRedirects})
    });

    this.logged=this.cookieService.check('userid');
  }
 
  ngOnInit(): void {
    if(!this.logged){
      if ((this.router.url.includes('/login/setpass/')))
        this.router.navigate(["login"]);
    }
  }
}