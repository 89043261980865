<main-header *ngIf="user && user.role==roleProvider"></main-header> <!---is es rol provider-->
<div class="container" *ngIf="user && (user.role==roleProvider || user.role==2)">
    <header-admin *ngIf="user && user.role==roleProvider" title="&nbsp;&nbsp;&nbsp;Mis contactos"></header-admin>
    <header-admin *ngIf="user && user.role==2" title="&nbsp;&nbsp;&nbsp;Contactos del proveedor: {{nameProvider}}"></header-admin>
    <div class="add" title="Añadir contacto">
        <svg (click)="goTo()" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" width="24" heigth="24" x="0px" y="0px" viewBox="0 0 426.667 426.667" style="enable-background:new 0 0 426.667 426.667;" xml:space="preserve">
            <path style="fill:#0082c0;" d="M213.333,0C95.514,0,0,95.514,0,213.333s95.514,213.333,213.333,213.333  s213.333-95.514,213.333-213.333S331.153,0,213.333,0z M341.333,251.733h-89.6v89.6h-76.8v-89.6h-89.6v-76.8h89.6v-89.6h76.8v89.6  h89.6V251.733z"/>
            <g>            </g>            <g>            </g>            <g>            </g>            <g>            </g>
            <g>            </g>            <g>            </g>            <g>            </g>            <g>            </g>
            <g>            </g>            <g>            </g>            <g>            </g>            <g>            </g>
            <g>            </g>            <g>            </g>            <g>            </g>
            </svg>
        <span (click)="goTo()" style="vertical-align: super;"> Añadir Contacto</span>
    </div>
    <unifersa-tabla>
        <div class="row" *ngFor="let con of contacts">
            <div class="contact">
                <div class="wrapper">
                    <div class="init">{{con.name.charAt(0)}}</div>
                    <div class="data">
                      <div class="title">{{con.name}}</div>
                    </div>
                  </div>
            </div>
            <div class="field">Telf: {{con.phone}} </div>
            <div class="field">{{con.email}} </div>
            <div class="field">
                <div *ngFor="let prov of provinces">
                    <span *ngIf="prov.id == con.province">{{prov.name}}</span>
                </div>
            </div>
            <div class="field">
                <span *ngIf="con.central == 1">Oficina Central</span>
                <span *ngIf="!con.central || con.central != 1">Comercial</span>
            </div>

            <div class="actions">
                <div class="action edit" (click)="goTo(con.id)" title="Editar">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/></svg>
                </div>
                <div class="action delete" (click)="deleteContact(con.id)" title="Eliminar">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/></svg>
                </div>  
            </div>
        </div>
    </unifersa-tabla>  

</div>