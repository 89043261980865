import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/api/models/user/user';
import { CookieService } from 'ngx-cookie-service';
import { NotificationService } from 'src/app/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/api/api.service';
import { Contact, Province } from 'src/app/api/models/contact/contact';
import { Provider } from 'src/app/api/models/provider/provider';

@Component({
  selector: 'app-contact-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.less']
})
export class ContactFormComponent implements OnInit {
  public user:User;
  public n:number; 
  public roleProvider:number = 3;
  public provinces: Array<Province>=[];
  public nameProvider: string = null;

  public provincesContact: Array<number>=[];

  public contact: Contact = new Contact();
  id: number = 0;
  idProvider: number = 0;

  public error:string = "";

  constructor(private api: ApiService,private cookie: CookieService, private notification:NotificationService, private router: Router, private route:ActivatedRoute) {
    this.n = parseInt(this.cookie.get('userid'));
    this.getUser(); 

    let that = this;
    this.route.params.subscribe(
      data => {
        if (!data.idContact){
          if (data.id == 'new'){
          }else{
            that.id = data.id;
            that.getContact();
          } 
        }else{
          that.idProvider = data.id;
          that.contact.provider = that.idProvider;
          if (data.idContact != 'new')
            that.id = data.idContact;
            that.getContact();
        }
      }
    );
  }

  ngOnInit(): void {
  }

  back(){//if provider o admin
    if (this.user.role == this.roleProvider){
      this.router.navigate(['/my-contacts']);
    }else if (this.user.role == 2){
      this.router.navigate(['/admin/provider/'+this.idProvider]);
    }
  }

  getUser(){
    let that = this;
    this.api.user.getUsersId(this.n).subscribe(
      data=>{
        if (data && data.response!=null){
          that.user = data.response;
          that.user['pass'] = null;

          that.getProvinces();
          if (that.user.role == 2){
            that.getProvider();
          }else{
            that.contact.provider = that.user.provider;
          }
        }
      },
      error=>{
        console.log(error);
      }
    );
  }

  getProvinces(){
    let that = this;
    this.api.contact.getProvinces().subscribe(
      data=>{
        that.provinces = data.response;
      }
    );
  }

  getProvider(){
    let that = this;
    this.api.provider.getProviderId(that.idProvider).subscribe(
      data=>{
        that.nameProvider = data.response.name;
      }
    );
  }

  saveContact(){
    let that = this;
    if (this.id == 0){
      if (this.provincesContact.length == 0) this.error = "Seleccione una o más provincias.";
      else this.error = "";
      this.provincesContact.forEach(ele => {
        this.contact.province = ele;
          this.api.contact.insertContact(this.contact).subscribe(
            data=>{
              if (data.code == 201){
                that.notification.success("Nuevo contacto creado.");
                that.back();
              }
            },
            err=>{
              console.log(err);
            }
          );
      });
      
    }else{
      this.api.contact.updateContact(this.id, this.contact).subscribe(
        data=>{
          if (data.code == 200){
            that.notification.success("Contacto actualizado correctamente.");
            window.location.reload;
          }
          console.log(data);
        },
        err=>{
          console.log(err);
        }
      );
    }
    
  }

  getContact(){
    let that = this;
    this.api.contact.getContactById(this.id).subscribe(
      data=>{
        if (data.code==200){
          that.contact = data.response[0];
        }
      }, 
      err=>{
        console.log(err);
      }
    );
  }
  
  addProvinces(value, id){
    let exists = (this.provincesContact.filter( ele => {return ele == id})).length;
    if (value && exists == 0){
      this.provincesContact.push(id);
    }else if (!value){
      if (exists > 0){
        this.provincesContact.forEach((element, indx) =>{
          if (element == id){
            this.provincesContact.splice(indx, 1);
          }
        });
      }
    }
  }

}
