import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/api/api.service';
import { Province } from 'src/app/api/models/contact/contact';
import { Provider } from 'src/app/api/models/provider/provider';
import { NotificationService } from 'src/app/notification/notification.service';
import { OrderService } from '../../api/models/order/order.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

@Component({
  selector: 'app-order-data',
  templateUrl: './order-data.component.html',
  styleUrls: ['./order-data.component.less']
})
export class OrderDataComponent implements OnInit {
  public n:number;
  products: any[];
  headers:Array<any>;
  userOrder:any=[];
  user:any=[];
  provider: Provider;
  provinces: Array<Province> = [];
  orderId = null;
  nameOrderContact = '';

  constructor(private router:Router, private notification: NotificationService, private cookie:CookieService,
    private _route: ActivatedRoute, private orderService: OrderService, private api: ApiService) {
    this.n = parseInt(this._route.snapshot.paramMap.get('id'));

    let that = this;
    this.api.user.getUsersId(parseInt(cookie.get("userid"))).subscribe(
      data=>{
        if (data.response)
          that.user = data.response;
      }
    );
    if (this.n){
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      this.getProductsOrder(this.n);
      this.api.contact.getProvinces().subscribe(
        data=>{
          this.provinces = data.response;
        }
      );
      this.api.order.getOrdersById(this.n).subscribe(
        data=>{
          if (data.response){
            that.api.user.getUsersId(data.response.user).subscribe(
              dat=>{
                if (dat.response){
                  that.userOrder = dat.response;
                  //that.nameOrderContact = that.userOrder.name_business && that.userOrder.name_business != "" ? (that.userOrder.name_business+" ("+that.userOrder.contact+")") : that.userOrder.contact;
                  that.nameOrderContact = that.userOrder.contact;
                }
              }
            );
          }
        }
      );
    }
  }

  ngOnInit() {
    this.headers=[
      {
        name: "Producto",
        class: "min-600"
      },
      {
        name: "Uds",
        class: "min-50"
      },
      {
        name: "Precio",
        class: "min-91"
      },
      {
        name: "Proveedor",
        class: "min-126"
      },
      {
        name: "Observaciones",
        class: "min-151"
      }
    ];
  }

  getProductsOrder(id:number):void{
    this.orderService.getProductsOrder(id).subscribe(
      data =>{
        if (data.response != null){console.log(data);
          this.products = data.response;
          this.getDataProvider();
          this.products.forEach(element => {
            element.prov_img="/assets/logos/"+element.prov_img;
          });
          let dateOrder = this.products[0].date.split(' ')[0];
          let year = dateOrder.split('-')[0];
          this.orderId= year.toString().substring(2,4)+'0'+this.n.toString();
        }
      }, 
      err =>{
        console.error(err.error);
      }
    );
  }

  getDataProvider(){
    this.api.provider.getProviderId(this.products[0].provider).subscribe(
      data=>{
        if (data.response){
          this.provider = data.response;
        }
      }
    );
  }

  download(){
    let provinceId: any = this.provinces.filter(ele => {return ele.name == this.userOrder.province;});
    if (provinceId.length > 0) 
      provinceId = provinceId[0].id;
    else 
      provinceId = 1;
    
    this.api.contact.getContactByProviderForEmail(this.products[0].provider, provinceId).subscribe(
      data=>{
        if (data.response){
          let contact = data.response;
          this.generatePdf(this.provider.name, this.products, this.products[0].more, 0, contact, this.products[0].id);
        }
      }
    );
  }

  numberPointToCo(number){
    let num=(number * 1000 / 1000).toFixed(4);
    num = num.toString().replace(".", ",");
    return num;
  }

  mathCeil(price, uds){
    let num = this.numberPointToCo(price*uds);
    if (num == '0,0000') return num = '';
    return num+"€";
  }

  getSubtotal(i){
    let total=0;
    this.products.forEach(product => {
      total+=(product.price * product.uds);
    });
    let totalR = this.numberPointToCo(total);
    if (totalR == "0,0000") return "";
    return totalR+"€";
  }

  generatePdf(nameProv, products, observaciones, i, contacts, orderId=''){
    let cod_client= this.userOrder.code;    let name = this.userOrder.name_business;    let cif= this.userOrder.nif;
    let address= this.userOrder.address_business;    let localidade = this.userOrder.locality;    let province= this.userOrder.province;
    let cp= this.userOrder.cp;        let country= this.userOrder.country;       let persona= this.userOrder.contact;
    let dateOrder = products[0].date.split(' ')[0];
    let today = dateOrder.split('-')[2]+'-'+dateOrder.split('-')[1]+'-'+dateOrder.split('-')[0];
    let contact = null;
    if (typeof contacts != 'string'){
      contact = contacts.filter(e=>{return e.central == 1;});
      if (contact.length > 0){
        contact = contact[0];
      }else{
        contact = contacts[0];
      }
    }else{
      contact = {'name': '', 'phone': '', 'email': ''};
    }
    
    //let year = dateOrder.split('-')[0];
    //this.orderId= year.toString().substring(2,4)+'0'+orderId.toString();

    const documentDefinition = {
      defaultFileName : 'Propuesta',
      info: {
        title: 'unifersa.net - '+this.n,
        author: 'Unifersa'
      },
      content: [
        {
          alignment: 'justify',
          columns: [
            {
              width: '*',
              stack: [
                {
                  image: 'logo',
                  width: 200,
                  margin: [0,0,0,10]
                },
                { 
                  stack:[
                    "Rúa Leme, S/N - Pol. Ind. Vilar do Colo - 15500 Fene (A Coruña)",
                    "981 39 64 74 - unifersa@unifersa.es",
                    "C.I.F. A70063383"
                  ],
                  fontSize: 10
                }
              ]
            }, 
            {
              width: 200,
              alignment: 'right',
              stack: [
                {
                  text: "PROPUESTA DE PEDIDO",
                  fontSize: 18,
                  bold: true
                },
                {
                  margin: [0,10,0,0],
                  stack: [
                    nameProv,
                    contact.name,
                    contact.phone+" - "+contact.email
                  ],
                  fontSize: 10 
                }
              ]              
            }
          ]
        },
        {
          alignment: 'justify',
          columns: [
            {
              width: 370,
              style: 'tableDataCliente',
              table: {
                headerRows: 0,
                widths: [110, 110, 110],
                body: [
                  [
                    {text: 'COD. CLIENTE', border: [true, true, true, false], fontSize: 9}, 
                    {text: 'NOMBRE', border: [true, true, true, false], fontSize: 9}, 
                    {text: 'C.I.F./N.I.F.', border: [true, true, true, false], alignment: 'center', fontSize: 9}
                  ],   
                  [
                    {text: cod_client, border: [true, false, true, true], fontSize: 9}, 
                    {text: name, border: [true, false, true, true], fontSize: 9}, 
                    {text: cif, border: [true, false, true, true], fontSize: 9}
                  ],  
                  [
                    {text: 'DIRECCIÓN', border: [true, true, true, false], colSpan: 2, fontSize: 9}, {}, 
                    {text: 'LOCALIDADE', border: [true, true, true, false], fontSize: 9}
                  ],   
                  [
                    {text: address, border: [true, false, true, true], colSpan: 2, fontSize: 9}, {}, 
                    {text: localidade, border: [true, false, true, true], fontSize: 9}
                  ],    
                  [
                    {text: 'COD. POSTAL', border: [true, true, true, false], fontSize: 9}, 
                    {text: 'PROVINCIA', border: [true, true, true, false], fontSize: 9}, 
                    {text: 'PAÍS', border: [true, true, true, false], fontSize: 9}
                  ],   
                  [
                    {text: cp, border: [true, false, true, true], fontSize: 9}, 
                    {text: province, border: [true, false, true, true], fontSize: 9}, 
                    {text: country, border: [true, false, true, true], fontSize: 9}
                  ],                
                  [ 
                    {text: 'Contacto: '+persona+' - '+this.userOrder.email, border: [true, true, true, true], colSpan: 3, fontSize: 9}, {}, {}
                  ]
                ]
              },
              layout: {
                hLineWidth: function (i, node) {
                  return (i === 0 || i === node.table.body.length) ? 2 : 1;
                },
                vLineWidth: function (i, node) {
                  return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                }
              }
            }, 
            {
              width: 120,
              style: 'tableDataCliente',
              table: {
                headerRows: 0,
                widths: [60, 60],
                body: [
                  [{text: 'Nº PEDIDO', border: [true, true, true, false], fontSize: 9}, {text: 'FECHA', border: [true, true, true, false], fontSize: 9}],
                  [{text: this.orderId, border: [true, false, true, true], fontSize: 9}, {text: today, border: [true, false, true, true], fontSize: 9}],
                  [{text: 'FECHA CONFIRMACIÓN', colSpan: 2, border: [true, true, true, false], fontSize: 9}, {}],
                  [{text: ' ', colSpan: 2, border: [true, false, true, true], fontSize: 9}, {}],
                  [{text: 'AGENTE', colSpan: 2, border: [true, true, true, false], fontSize: 9}, {}],
                  [{text: 'unifersa.net', colSpan: 2, border: [true, false, true, true], fontSize: 9}, {}]
                ]
              },
              layout: {
                hLineWidth: function (i, node) {
                  return (i === 0 || i === node.table.body.length) ? 2 : 1;
                },
                vLineWidth: function (i, node) {
                  return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                }
              }
            }
          ]
        },
        {
          style: 'tableOrder',
          table: {
            headerRows: 1,
            widths: ['auto', 68, 55, 'auto', 'auto', 57, 'auto', 58],
            body: [
              [
                {text: 'Nº', style:'tableHeader'}, 
                {text: 'EAN', style:'tableHeader'}, 
                {text: 'REF', style:'tableHeader'}, 
                {text: 'DESCRIPCIÓN', style:'tableHeader'}, 
                {text: 'CANTIDAD', style:'tableHeader'}, 
                {text: 'PRECIO', style:'tableHeader'}, 
                {text: 'DTO', style:'tableHeader'}, 
                {text: 'IMPORTE', style:'tableHeader'}],
              ...products.map((p,ind) => (
                [ {text: ind+1, style: 'dataOrder'}, 
                  {text: p.product_ean, style: 'dataOrder'}, 
                  {text: p.ref, style: 'dataOrder'}, 
                  {text: p.name, style: 'dataOrder'}, 
                  {text: p.uds +' und', style: 'dataOrder'}, 
                  {text: this.numberPointToCo(p.price), style: 'dataOrder'}, '', 
                  {text: (this.mathCeil(p.price , p.uds )), style: 'dataOrder'}])
              )
              ,
              [
                {text: 'TOTAL PEDIDO:', colSpan:7, fontSize: 10},{},{},{},{},{},{},
                {text: this.getSubtotal(i), fontSize: 10}
              ]
            ]
          },
          layout: {
            hLineWidth: function (i, node) {
              return (i === 0 || i === node.table.body.length) ? 2 : 1;
            },
            vLineWidth: function (i, node) {
              return (i === 0 || i === node.table.widths.length) ? 2 : 1;
            }
          }
        },
        {
          margin: [0,10,0,0],
          stack: [
            {text: "Observaciones:", bold: true},
            observaciones        
          ],
          fontSize: 10
        }
      ],
      images: {
        logo: 'https://unifersa.net/assets/logo.png'
      },
      styles: {
        tableDataCliente: {
          margin: [0,10,0,0],
          alignment: 'center'
        }, 
        tableOrder: {          
          margin: [0,20,0,0],
          alignment: 'right'
        },
        tableHeader: {
          bold: true,
          fontSize: 9,
          color: 'black',
          alignment: 'center'
        },
        dataOrder:{
          fontSize: 8
        }
      }
    };

    let pdf = pdfMake.createPdf(documentDefinition);
    let namePdf = 'unifersa.net-'+this.n+".pdf";

    //pdfMake.createPdf(documentDefinition).open();
    pdfMake.createPdf(documentDefinition).download(namePdf);
  }
}
