<main-header></main-header>
<div class="container">
    <header-admin title="Todos los Pedidos"></header-admin>
    <div *ngIf="!orders || orders.length==0" class="text-center view-grid">
        <div class="wrapper">
          <div class="element">
            No hay pedidos.
          </div>
        </div>
    </div>
    <unifersa-tabla>
        <div class="row" *ngFor="let order of orders">
          <div class="user">
            <div class="wrapper">
              <div class="init">{{order.user.charAt(0)}}</div>
              <div class="data" *ngIf="user.role==3">
                <div class="title"><span *ngIf="order.dataUser">{{order.dataUser.business}} - </span> {{order.lastname}} ({{order.email}})</div>
                <div class="subtitle">Fecha: {{order.date}} - Dirección: <span *ngIf="order.dataUser">{{order.dataUser.address_business}} {{order.dataUser.cp}} {{order.dataUser.city}} {{order.dataUser.province}}</span></div>
              </div>
              <div class="data" *ngIf="user.role!=3">
                <div class="title">Fecha: {{order.date}}</div>
              </div>
            </div>
          </div>
          <div class="actions">
            <div class="action more" (click)="getProductsOrder(order.id)">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5.88 4.12L13.76 12l-7.88 7.88L8 22l10-10L8 2z"/></svg>
            </div>
          </div>
        </div>
    </unifersa-tabla>
</div>
