<div class="header">
    <div class="filters">
        <div class="filter all" (click)="activateFilter($event, 'todos')">Todos</div>
        <div [ngClass]="{'filter': true, 'active': filter==letter, 'all': letter==null}" *ngFor="let filter of filters" (click)="activateFilter($event, filter)">{{filter}}</div>
    </div>
</div>
<div *ngIf="!providers || providers.length==0" class="text-center view-grid">
    <div class="wrapper">
      <div class="element">
        Cargando proveedores...
      </div>
    </div>
</div>
<list-grid [data]="providers" [providers]="true" route="/provider/" image="../../../assets/logos/" identifier="id" elem_by_row="5" perPage="500" [letter]="filter"></list-grid>