<div *ngIf="data_print && data_print.length>0" id="grid-list" class="ebyr-{{elem_by_row}} view-{{view}}">
  <div class="provider-cenor" *ngIf="providers">
    <div class="wrapper-cenor">
      <div class="image">
          <a href="http://intranet.cenor.es" target="_blank">
            <img src="https://www.cenor.es/storage/img/C00001_F0000000004_logo.png" title="Imagen Principal" onerror="this.src='/assets/no-image.pnt';"/>
          </a>
      </div>
      <div class="data-info-cenor">
        <div class="title">
          <h3><a href="http://intranet.cenor.es" target="_blank"><span>Cenor</span></a></h3>
        </div>
        <div class="nprods">
          <span>+1700 productos</span>
        </div>
        <div class="buttones">
          <div class="access-cenor">
            <span (click)="loginCenor()">Acceso a plataforma</span>
          </div>
          <div class="conds" *ngIf="role!=5">
            <a (click)="regDownload()" title="Plantilla de condiciones">
              <svg width="24px" height="24px" viewBox="0 0 16 16" class="bi bi-info-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                  <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z"/>
                  <circle cx="8" cy="4.5" r="1"/>
                </svg>
            </a>
        </div>
        </div>
      </div>
    </div>
  </div>
  <div class="wrapper">
    <div class="element" *ngFor="let element of data_print; let i = index">
      <div class="wrapper" *ngIf="element.name ">
        <div class="image">
          <div class="wrapper">
            <a routerLink="{{route}}/{{element[identifier]}}">
              <img src="{{image}}{{element.images[0]}}" title="Imagen Principal"
                onerror="this.src='/assets/no-image.png';" />
            </a>
          </div>
        </div>
        <div class="data-info">
          <h3 class="title">
            <div class="wrapper"><a routerLink="{{route}}/{{element[identifier]}}"><span>{{element.name}}</span></a>
            </div>
          </h3>
          <div class="nprods" *ngIf="element.nProds && element.nProds>0">{{element.nProds}} productos</div>
          <div class="nprods online" *ngIf="element.nProds && element.nProds>0 && element.online">
            <span class="availaibleOnline">Pedido online disponible</span>
          </div>
          <div class="nprods" *ngIf="element.nProds==0"></div>
          <div class="data-list" *ngIf="view=='list'">
            <div class="wrapper">
              <div class="price" *ngIf="element.price!=0">{{numberPointToCo(element.price)}} €</div>
              <div class="price" *ngIf="element.price==0">Consultar</div>
              <div class="add-to-cart">
                <span (click)="addToCart(element.provider, element)" *ngIf="showAddToCart">Añadir
                  al carrito</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <paginate-list *ngIf="show_pag" [data]="data" perPage="{{perPage}}" (onChangePage)="changedPage($event)">
  </paginate-list>
</div>
<div *ngIf="data_print && data_print.length==0" class="text-center view-grid">
  <div class="wrapper">
    <div *ngIf="!providers" class="element">
      No hay elementos en este catálogo.
    </div>
  </div>
</div>