<main-header></main-header>
<div class="container">
    <header-admin title="&nbsp;&nbsp;&nbsp;Mi cuenta"></header-admin>
    <div>
        <form *ngIf="user">
            <div class="campo">
                <div class="etiqueta">
                  <div class="wrapper">
                    <div class="icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/></svg>
                    </div>
                    <div class="text">Nombre</div> 
                  </div>
                </div>
                <div class = "input">
                  <input class = "inputc" type = "text" name="name" placeholder="Nombre" 
                    [(ngModel)]="user.contact" #ctrl="ngModel" readonly>
                </div>
              </div>
             
            
            <p><header-admin title="Contraseña nueva"></header-admin></p>
            
            <div class="campo">
                <div class="etiqueta">
                  <div class="wrapper">
                    <div class="icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"/></svg>
                    </div>
                    <div class="text">Contraseña nueva</div> 
                  </div>
                </div>
              <div class = "input">
                <input class = "inputc" type = "password" name="pass" placeholder="Contraseña nueva" 
                [(ngModel)]="user.pass" #ctrl="ngModel">
              </div>
            </div>
            <div class="campo">
                <div class="etiqueta">
                  <div class="wrapper">
                    <div class="icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"/></svg>
                    </div>
                    <div class="text">Confirmar contraseña nueva</div> 
                  </div>
                </div>
              <div class = "input">
                <input class = "inputc" type = "password" name="passv" placeholder="Confirmar contraseña nueva" 
                [(ngModel)]="user.passv" #ctrl="ngModel">
              </div>
            </div>
        </form>
        <div class="actions">
            <div class="action save" (click)="saveUser()">
              <div class="wrapper">
                <div class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/></svg></div>
                <div class="text">Guardar</div>
              </div>
            </div>
            <div class="action cancel" (click)="goTo('/')">
                <div class="wrapper">
                  <div class="text">Cancelar</div>
                </div>
              </div>
        </div>
    </div>
</div>