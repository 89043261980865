import { Injectable } from '@angular/core';

@Injectable()
export class ConfigService {
  //private server="http://localhost:8080";
  private server="https://unifersa.net";
  public version = "v2";

  constructor() { }

  getServer(){
    return this.server;
  }
}