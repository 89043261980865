<div id="avisolegal" class="rte">
  <h3 id="uno">1.   TITULAR DEL SITIO WEB</h3>
  <p>En cumplimiento del deber de información contemplado en el artículo 10 de la vigente Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico, le comunicamos que el responsable de la web donde puede descargar diferentes aplicaciones es </p>
  <ul>
  <li><b>Denominación social</b> UNIFERSA 2006 S.A</li>
  <li><b>NIF</b> A 70063383</li>
  <li><b>Domicilio social</b> C/LEME POLÍGONO INDUSTRIAL VILAR DO COLO S/N 15500 - FENE (A Coruña)</li>
  <li><b>Teléfono Contacto</b> 981.39.64.74</li>
  <li><b>Dirección de correo electrónico</b> sistemas@unifersa.es v
  </li><li><b>Registros oficiales</b></li>
  <li>Registro Mercantil de A Coruña, en el tomo 3126, Folio 110, Nº C-39374, Inscripción 1º.</li>
  <li>Marca ante la O.E.P.M. Expediente  </li>
  <li><b>Normativa aplicable por la profesión y/o códigos de conducta</b></li>
  <li>Sitio web de UNIFERSA 2006 S.A</li>
  <li>Página web principal www.unifersa.es</li>
  <li>Otras páginas web secundarias con afectación de este aviso legal </li>
  </ul>
  <h3 id="seis">2.	CONCEPTO DE USUARIO</h3>
  <p>
  La utilización de la Web atribuye la condición de Usuario, e implica la aceptación plena y sin reservas de todas y cada una de las disposiciones incluidas en este Aviso Legal en la versión publicada por UNIFERSA 2006 S.A en el mismo momento en que el Usuario acceda a la web. En consecuencia, el Usuario debe leer atentamente el presente Aviso Legal en cada una de las ocasiones en que se proponga utilizar la Web, ya que puede sufrir modificaciones.
  </p>
  <h3 id="siete">3.	IDIOMA OFICIAL DE LA PÁGINA</h3>
  <p>El idioma legalmente establecido es el español de España, por lo tanto ante cualquier traducción de este aviso legal a cualquier otro idioma, se entenderán como vinculantes tanto en el Aviso Legal como en las condiciones del servicio, las cláusulas originales en español.</p>
  <h3 id="ocho">4.	REDES SOCIALES </h3>
  <p>UNIFERSA 2006 S.A tiene o puede tener algún perfil abierto en las principales redes sociales, en todas las redes en las que este, se reconoce como  responsable del tratamiento de los datos sus usuarios, y/o seguidores de la empresa hayan incluido en el perfil de la empresa. El tratamiento que UNIFERSA 2006 S.A llevará a cabo con dichos datos será el que la red social permita a los perfiles empresariales dentro de sus políticas como red social. Así pues, UNIFERSA 2006 S.A podrá informar a sus seguidores por cualquier vía que la red social permita sobre sus actividades, nuevos productos, actos y eventos, así como ofertas de servicio personalizado de atención al cliente. UNIFERSA 2006 S.A no extraerá datos de las redes sociales, a menos que se obtuviera puntual y expresamente el consentimiento del usuario para ello. </p>
  <h3 id="nueve">5.	INFORMACIÓN SOBRE LOS LINKS</h3>
  <p>UNIFERSA 2006 S.A no se hace responsable de las webs no propias a las que se puede acceder mediante vínculos "links" o de cualquier contenido puesto a disposición por terceros.</p>
  <p>Cualquier uso de un vínculo o acceso a una web no propia es realizado por voluntad y riesgo exclusivo del usuario y UNIFERSA 2006 S.A no recomienda ni garantiza ninguna información obtenida a través de un vínculo ajeno a UNIFERSA 2006 S.A, ni se responsabiliza de ninguna pérdida, reclamación o perjuicio derivada del uso o mal uso de un vínculo, o de la información obtenida a través de él, incluyendo otros vínculos o webs, de la interrupción en el servicio o en el acceso, o del intento de usar o usar mal un vínculo, tanto al conectar a la Web de UNIFERSA 2006 S.A, como al acceder a la información de otras webs desde la Web de UNIFERSA 2006 S.A.</p>  
  <h3 id="once">6.    RENUNCIA Y LIMITACIÓN DE LA RESPONSABILIDAD</h3>
  
  <p>La información y servicios incluidos o disponibles a través de las páginas web pueden incluir incorrecciones o errores tipográficos. De forma periódica se incorporan cambios a la información contenida. UNIFERSA 2006 S.A. puede introducir en cualquier momento mejoras y/o cambios en los servicios o contenidos.
  UNIFERSA 2006 S.A. ha obtenido la información y los materiales incluidos en la web de fuentes consideradas como fiables, pero, si bien se han tomado las medidas correspondientes para asegurar que la información contenida sea correcta, no garantiza que sea exacta y actualizada.
  También se advierte que los contenidos de esta web, tienen finalidad informativa en cuanto a la calidad, situación, alojamiento, servicios y tarifas de UNIFERSA 2006 S.A.</p>
   
  <h3 id="doce">7.    INFORMACIÓN SOBRE LE EXENCIÓN DE TODA LA RESPONSABILIDAD DERIVADA DE UN FALLO TÉCNICO Y DE CONTENIDO</h3>
  
  <p>UNIFERSA 2006 S.A. declina cualquier responsabilidad en caso de que existan interrupciones o un mal funcionamiento de los servicios o contenidos ofrecidos en Internet, cualquiera que sea su causa. Asimismo, UNIFERSA 2006 S.A no se hace responsable por caídas de la red, pérdidas de negocio a consecuencia de dichas caídas, suspensiones temporales del fluido eléctrico o cualquier otro tipo de daño indirecto que le pueda ser causado a los Usuarios por causas ajenas a UNIFERSA 2006 S.A.</p>
  <p>UNIFERSA 2006 S.A. no declara ni garantiza que los servicios o contenidos sean interrumpidos o que estén libres de errores, que los defectos sean corregidos, o que el servicio o el servidor que lo pone a disposición estén libres de virus u otros componentes nocivos, sin perjuicio de que UNIFERSA 2006 S.A. realiza sus mejores esfuerzos en evitar este tipo de incidentes. En caso de que el Usuario tomara determinadas decisiones o realizara acciones con base a la información incluida en cualquiera de los websites, se recomienda la comprobación de la información recibida con otras fuentes.</p>
  
  <h3 id="trece">8.    PROPIEDAD INDUSTRIAL E INTELECTUAL</h3>
  <p>Los contenidos prestados por UNIFERSA 2006 S.A, así como los contenidos vertidos en la red a través de sus páginas web, constituyen una obra en el sentido de la legislación sobre propiedad intelectual por lo que se hallan protegidos por las leyes y convenios internacionales aplicables en la materia.</p>
  <p>Queda prohibida cualquier forma de reproducción, distribución, comunicación pública, transformación, puesta a disposición y, en general, cualquier otro acto de explotación pública referido tanto a las páginas Web como a sus contenidos e información, sin el expreso y previo consentimiento y por escrito UNIFERSA 2006 S.A.</p>
  <p>En consecuencia, todos los contenidos que se muestran en los diferentes websites y en especial, diseños, textos, gráficos, logos, iconos, botones, software, nombres comerciales, marcas, dibujos industriales o cualesquiera otros signos susceptibles de utilización industrial y comercial están sujetos a derechos de propiedad intelectual e industrial de UNIFERSA 2006 S.A o de terceros titulares de los mismos que han autorizado debidamente su inclusión en los diferentes websites.</p>
  <p>Los contenidos, imágenes, formas, opiniones, índices y demás expresiones formales que formen parte de las páginas Web, así como el software necesario para el funcionamiento y visualización de las mismas, constituyen asimismo una obra en el sentido del Derecho de Autor y quedan, por lo tanto, protegidas por las convenciones internacionales y legislaciones nacionales en materia de Propiedad intelectual que resulten aplicables. El incumplimiento de lo señalado implica la comisión de graves actos ilícitos y su sanción por la legislación civil y penal.</p>
  <p>Queda prohibido todo acto por virtud del cual los Usuarios de los servicios o contenidos puedan explotar o servirse comercialmente, directa o indirectamente, en su totalidad o parcialmente, de cualquiera de los contenidos, imágenes, formas, índices y demás expresiones formales que formen parte de las páginas Web sin permiso previo y por escrito de UNIFERSA 2006 S.A.</p>
  <p>En concreto, y sin carácter exhaustivo, quedan prohibidos los actos de reproducción, distribución, exhibición, transmisión, retransmisión, emisión en cualquier forma, almacenamiento en soportes físicos o lógicos (por ejemplo, disquetes o disco duro de ordenadores), digitalización o puesta a disposición desde bases de datos distintas de las pertenecientes a las autorizadas por UNIFERSA 2006 S.A, así como su traducción, adaptación, arreglo o cualquier otra transformación de dichas opiniones, imágenes, formas, índices y demás expresiones formales que se pongan a disposición de los Usuarios a través de los servicios o contenidos, en tanto tales actos estén sometidos a la legislación aplicable en materia de Propiedad intelectual, industrial o de protección de la imagen.</p>
  <p>UNIFERSA 2006 S.A es libre de limitar el acceso a las páginas web, y a los productos y/o servicios en ella ofrecidos, así como la consiguiente publicación de las opiniones, observaciones, imágenes o comentarios que los usuarios puedan hacerle llegar a través del e-mail.</p>
  <p>UNIFERSA 2006 S.A, en este sentido, podrá establecer, si lo considera oportuno, sin perjuicio de la única y exclusiva responsabilidad de los Usuarios, los filtros necesarios a fin de evitar que a través de sus páginas Web puedan verterse en la red contenidos u opiniones, considerados como racistas, xenófobos, discriminatorios, pornográficos, difamatorios o que, de cualquier modo, fomenten la violencia o la diseminación de contenidos claramente ilícitos o nocivos.</p>
  <p>Aquellos usuarios que envíen a las páginas web de UNIFERSA 2006 S.A, a su departamento de sugerencias, observaciones, opiniones o comentarios por medio del servicio de correo electrónico, salvo que expresen de manera cierta e inconfundible lo contrario, en los casos en los que por la naturaleza de los servicios o contenidos ello sea posible, se entiende que autorizan a UNIFERSA 2006 S.A para la reproducción, distribución, exhibición, transmisión, retransmisión, emisión en cualquier formato, almacenamiento en soportes físicos o lógicos (por ejemplo, disquetes o disco duro de ordenadores), digitalización, puesta a disposición desde bases de datos pertenecientes a UNIFERSA 2006 S.A, traducción, adaptación, arreglo o cualquier otra transformación de tales observaciones, opiniones o comentarios, por todo el tiempo de protección de derecho de autor que esté previsto legalmente. Asimismo, se entiende que esta autorización se hace a título gratuito, y que por el solo hecho de enviar por e-mail tales observaciones, opiniones o comentarios, los usuarios declinan cualquier pretensión remuneratoria por parte de UNIFERSA 2006 S.A.</p>
  <p>De acuerdo con lo señalado en el párrafo anterior UNIFERSA 2006 S.A queda autorizada igualmente para proceder a la modificación o alteración de tales observaciones, opiniones o comentarios, a fin de adaptarlos a las necesidades de formato editorial de las páginas Web, sin que por ello pueda entenderse que existe en absoluto cualquier tipo de lesión de cualesquiera de las facultades morales de derecho de autor que los Usuarios pudieran ostentar sobre aquéllas.</p>
  <p>Quedan prohibidos cualesquiera de los recursos técnicos, lógicos o tecnológicos por virtud de los cuales un tercero pueda beneficiarse, directa o indirectamente, con o sin lucro, de todos y cada uno de los contenidos, formas, índices y demás expresiones formales que formen parte de las páginas Web, o del esfuerzo llevado a cabo UNIFERSA 2006 S.A para su funcionamiento. En concreto, queda prohibido todo link, hyperlink, framing o vínculo similar que pueda establecerse en dirección a las páginas Web de UNIFERSA 2006 S.A, sin el consentimiento previo, expreso y por escrito de UNIFERSA 2006 S.A. Cualquier trasgresión de lo dispuesto en este punto será considerada como lesión de los legítimos derechos de Propiedad intelectual de UNIFERSA 2006 S.A sobre las páginas Web y todos los contenidos de las mismas.</p>
  <p>UNIFERSA 2006 S.A no asumirá responsabilidad alguna ante consecuencias derivadas de las conductas y actuaciones antes citadas, del mismo modo que no asumirá responsabilidad alguna por los contenidos, servicios, productos, etc., de terceros a los que se pueda acceder directamente o a través de banners, enlaces, links, hyperlinks, framing o vínculos similares desde los websites de UNIFERSA 2006 S.A.</p>
  
  <h3 id="catorce">9.	RESOLUCION DE CONFLICTOS</h3>
  <p>Este aviso legal no se archivará individualmente para cada usuario sino que permanecerá accesible por medio de Internet en esta página web. Es accesible mediante la conexión que cada usuario tenga en su sistema de conexión. </p>
  <p>Los Usuarios que tengan la condición de consumidores o usuarios conforme los define la normativa española y residan en la Unión Europea, si han tenido un problema con una compra realizada a UNIFERSA 2006 S.A para tratar de llegar a un acuerdo extrajudicial pueden acudir a la Plataforma de Resolución de Litigios en Línea, creada por la Unión Europea y desarrollada por la Comisión Europea al amparo del Reglamento (UE) 524/2013. Tal y como se recoge también en las “Condiciones Generales de contratación” y/o “Uso de la Web”.</p>
  <p>Siempre que el Usuario no sea consumidor o usuario, y cuando no haya una norma que obligue a otra cosa, las partes acuerdan someterse a los Juzgados y Tribunales de A Coruña, por ser este el lugar de celebración del contrato, con renuncia expresa a cualquier otra jurisdicción que pudiera corresponderles.</p>
  
  <h3 id="quince">10.	LEGISLACION </h3>
  
  <p>Las leyes que rigen esta página web son las leyes españolas.</p>
  
  <p>Aviso Legal redactado por HONOS ABOGADOS S.L.P. para UNIFERSA 2006 S.A. en cumplimiento del RGPD 679/2016  sobre protección de datos de carácter personal y la LSSI (Ley de Servicios de la Sociedad de Información), 34/2002. Además de la legislación sobre Propiedad Intelectual. Los contenidos de este aviso legal  se encuentran registrados en el Registro de Propiedad Intelectual Safe Creative, con código de registro. 1602176603277. Se prohíbe la reproducción total o parcial del mismo</p>
</div>