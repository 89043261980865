export class Provider {
    id: number;
    name: string;
    image: string;
    products:any[];
    view: string;
    conditions: string;
    show_conds: boolean;
    emails:string[];
    date_products:string;
    code:string;
    cif:string;
    api: number;
}