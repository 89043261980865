export class Product{
    ref: string;
    provider: number;
    ean: string;
    name: string;
    price: number;
    price_offer: number;
    pvpr: number;
    description: string;
    attrs: string;
    categories:string;
    uds_caja: number;
    url_info: string;
    images: string;
    card: string;
}