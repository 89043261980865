<div class="container">
  <div class="content-form">
    <div class="logo-app">
      <img src="assets/logo.jpg" alt="Logo" title="Logo Unifersa"/>
    </div>
    <form id="loginForm" #loginForm="ngForm" *ngIf="!verification">
      <div class="input-form">
        <input type="text" [(ngModel)]="email" id="email" placeholder="E-Mail *" name="email" required/>
      </div>
      <div class="input-form">
        <input type="password" [(ngModel)]="pass" id="pass" placeholder="Contraseña *" name="pass" required/>
      </div>
      <div class="links-form">
        <div class="remember">
          <input type="checkbox" id="remember" name="remember"/>
          <span (click)="checkActive()">Mantener sesión</span>
        </div>
        <div class="setpass">
          <span (click)="goTo('/login/remember')">Olvidé mi contraseña</span>
        </div>
      </div>
      <div *ngIf="notif != ''" class="notification">
        <span>{{notif}}</span><br/>
        <span>{{notif2}}</span>
      </div>
      <button *ngIf="!blockButton" type="submit" (click)="login($event)">Acceder</button>
      <button *ngIf="blockButton" type="submit" class="block">RECIBIRÁS UN CORREO ELECTRÓNICO CUANDO TENGAS LOS PERMISOS PARA ACCEDER</button>
      <button *ngIf="blockButton" type="submit" (click)="checkBlock()">COMPROBAR SI YA TENGO LOS PERMISOS PARA ACCEDER</button>
      <div *ngIf="error != ''" class="notification">
        <span>{{error}}</span>
      </div>
    </form>
    <form id="verifyForm" #loginForm="ngForm" *ngIf="verification">
      <div class="notification">
        <span>{{notif}}</span><br/>
        <span>{{notif2}}</span>
      </div>
      <div class="input-form">
        <input type="text" [(ngModel)]="code" id="code" placeholder="Código de verificación*" name="code" required/>
      </div>
      <button type="submit" (click)="verify()">Verificar</button>
    </form>
  </div>
</div>