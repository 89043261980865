<div class="container">
  <header-admin *ngIf="n" title="Editar proveedor"></header-admin>
  <header-admin *ngIf="!n" title="Añadir un nuevo proveedor"></header-admin>
  <form *ngIf="provider">
    <div class="campo">
      <div class="etiqueta">
        <div class="wrapper">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/></svg>
          </div>
          <div class="text">Nombre</div> 
        </div>
      </div>
      <div class = "input">
          <input class = "inputc" type = "text" name="name" placeholder="Nombre del proveedor" 
          [(ngModel)]="provider.name" #ctrl="ngModel"   >
      </div>
    </div>
    <div class="campo width-double">
      <div class="etiqueta">
        <div class="wrapper">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"/></svg>
          </div>
          <div class="text">Data View de Productos</div> 
        </div>
      </div>
      <div class = "input">
          <input class = "inputc" id="view" type = "text" name="view" 
          placeholder="Url para la view de productos"
          [(ngModel)]="provider.view" #ctrl="ngModel"   >
      </div>
    </div>
    <div class="campo">
        <div class="etiqueta">
          <div class="wrapper">
            <div class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zM8.5 13.5l2.5 3.01L14.5 12l4.5 6H5l3.5-4.5z"/></svg>
            </div>
            <div class="text">Imagen</div> 
          </div>
        </div>
        <div class = "input" >
            <input class = "inputc" id="imagen" type="file" name="imagen" 
            placeholder="Imagen del proveedor" accept="image/*" 
            (change)="changeImage($event)">
        </div>
    </div>
    <div class="campo">
        <div class="etiqueta">
          <div class="wrapper">
            <div class="icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 7V3H2v18h20V7H12zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zm-2-8h-2v2h2v-2zm0 4h-2v2h2v-2z"/></svg>
            </div>
            <div class="text">Productos</div>
            <div class="input input-mini"><input type="text" id="date_products" name="date_products" placeholder="Fecha subida" [(ngModel)]="provider.date_products" #ctrl="ngModel"></div>
          </div>
        </div>
        <div class = "input">
            <input class = "inputc" id="csv" type = "file" name="products" 
            placeholder="Productos del usuario" accept=".csv, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" 
            (change)="changeCsv($event)">
        </div>
    </div>
    <div class="campo">
      <div class="etiqueta">
        <div class="wrapper">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16.5 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6H10v9.5c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5V5c0-2.21-1.79-4-4-4S7 2.79 7 5v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6h-1.5z"/></svg>
          </div>
          <div class="text">Condiciones del proveedor (imagen): <a href="{{urlPdf}}" target="_blank">{{nomeCondic}}</a></div> 
        </div>
      </div>
      <div class = "input" >
          <input class = "inputc" id="file" type="file" name="file" 
          placeholder="Condiciones del proveedor" accept="image/*" 
          (change)="changeConds($event)">
      </div>
    </div>
    <div class="campo">
      <div class="etiqueta">
        <div class="wrapper">
          <div class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path xmlns="http://www.w3.org/2000/svg" d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"/></svg>
          </div>
          <div class="text">Archivo Excel</div>
        </div>
      </div>
      <div class = "input">
          <input class = "inputc" id="archive" type = "file" name="archive" 
          placeholder="Archivo" accept=".csv, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" 
          (change)="uploadFile($event)">
      </div>
    </div>
    <div class="campo">
      <div class="etiqueta">
        <div class="wrapper">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path xmlns="http://www.w3.org/2000/svg" d="M3.5 18.99l11 .01c.67 0 1.27-.33 1.63-.84L20.5 12l-4.37-6.16c-.36-.51-.96-.84-1.63-.84l-11 .01L8.34 12 3.5 18.99z"/></svg>
          </div>
          <div class="text">Código Proveedor</div> 
        </div>
      </div>
      <div class = "input">
          <input class = "inputc" type = "text" name="code" placeholder="Código del Proveedor" 
          [(ngModel)]="provider.code" #ctrl="ngModel" maxlength="4">
      </div>
    </div>
    <div class="campo">
      <div class="etiqueta">
        <div class="wrapper">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path xmlns="http://www.w3.org/2000/svg" d="M3.5 18.99l11 .01c.67 0 1.27-.33 1.63-.84L20.5 12l-4.37-6.16c-.36-.51-.96-.84-1.63-.84l-11 .01L8.34 12 3.5 18.99z"/></svg>
          </div>
          <div class="text">Código CIF</div> 
        </div>
      </div>
      <div class = "input">
          <input class = "inputc" type = "text" name="cif" placeholder="Código del CIF" 
          [(ngModel)]="provider.cif" #ctrl="ngModel" maxlength="20">
      </div>
    </div>
    <div [ngClass]="{'campo': true, 'width-all': i+1!=provider.emails.length, 'width-more': i+1==provider.emails.length}" *ngFor="let email of provider.emails; index as i">
      <div class="etiqueta">
          <div class="wrapper">
            <div class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"/></svg>
            </div>
            <div class="text">Correo de contacto</div> 
          </div>
        </div>
        <div class = "input" >
          <input class = "inputc" type="email" name="email{{i}}" placeholder="Correo de contacto" (change)="editInput($event, i)" value="{{provider.emails[i]}}">
          <div class="add" *ngIf="i+1==provider.emails.length" (click)="addEmailProvider()">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>
          </div>
        </div>
    </div>
    <div class="campo">
      <div class="etiqueta">
        <div class="wrapper">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M11.99 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm3.61 6.34c1.07 0 1.93.86 1.93 1.93 0 1.07-.86 1.93-1.93 1.93-1.07 0-1.93-.86-1.93-1.93-.01-1.07.86-1.93 1.93-1.93zm-6-1.58c1.3 0 2.36 1.06 2.36 2.36 0 1.3-1.06 2.36-2.36 2.36s-2.36-1.06-2.36-2.36c0-1.31 1.05-2.36 2.36-2.36zm0 9.13v3.75c-2.4-.75-4.3-2.6-5.14-4.96 1.05-1.12 3.67-1.69 5.14-1.69.53 0 1.2.08 1.9.22-1.64.87-1.9 2.02-1.9 2.68zM11.99 20c-.27 0-.53-.01-.79-.04v-4.07c0-1.42 2.94-2.13 4.4-2.13 1.07 0 2.92.39 3.84 1.15-1.17 2.97-4.06 5.09-7.45 5.09z"/></svg>
          </div>
          <div class="text">¿Usa api?</div> 
        </div>
      </div>
      <div class = "input">
        <select class="inputc" name="api" [(ngModel)]="provider.api" #ctrl="ngModel" ng-init= "'none'">
          <option [ngValue]="none" disabled>Selecciona la api</option>
          <option [value]="null">Ninguna</option>
          <option *ngFor="let api of apis" [value]="api.id">{{api.name}}</option>
        </select>
      </div>
    </div>
  </form>
  <div class="actions">
    <div class="action contacts" (click)="viewContacts()">
      <div class="wrapper">
        <div class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/></svg></div>
        <div class="text">Ver Contactos</div>
      </div>
    </div>

    <div class="action check" *ngIf="tieneProductos" (click)="checkImages()">
      <div class="wrapper">
        <div class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M18 13v7H4V6h5.02c.05-.71.22-1.38.48-2H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-5l-2-2zm-1.5 5h-11l2.75-3.53 1.96 2.36 2.75-3.54zm2.8-9.11c.44-.7.7-1.51.7-2.39C20 4.01 17.99 2 15.5 2S11 4.01 11 6.5s2.01 4.5 4.49 4.5c.88 0 1.7-.26 2.39-.7L21 13.42 22.42 12 19.3 8.89zM15.5 9C14.12 9 13 7.88 13 6.5S14.12 4 15.5 4 18 5.12 18 6.5 16.88 9 15.5 9z"/></svg></div>
        <div class="text">Comprobar imágenes</div>
      </div>
    </div>
    <div class="action save" (click)="saveProvider()">
      <div class="wrapper">
        <div class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/></svg></div>
        <div class="text">Guardar proveedor</div>
      </div>
    </div>
  </div>

</div>
<div class="loading" *ngIf="hideLoad"><div>{{textLoad}}</div></div>