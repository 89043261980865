<h3 id="diez">POLITICA DE COOKIES</h3>
  <b id="dieza">A.	INFORMACIÓN SOBRE LA UTILIZACIÓN DE COOKIES -  LEY DE COOKIES</b>
  <p>En esta Web se pueden estar utilizando cookies en algunas páginas. La finalidad de dichas cookies es mejorar el servicio que ofrecen a sus clientes y a nuestros visitantes. En caso de ser instaladas, el administrador de esta Web le solicitara su consentimiento para hacerlo, y tendrá opción de aceptarlo.</p>
  <p>Una "Cookie" es un pequeño archivo que se almacena en el ordenador del usuario y nos permite reconocerle. El conjunto de "cookies" nos ayuda a mejorar la calidad de nuestra web, permitiéndonos controlar qué páginas encuentran nuestros usuarios útiles y cuáles no.</p>
  <b id="diezb">B.	ADMINISTRACIÓN DE COOKIES Y REVOCACIÓN DEL CONSENTIMIENTO PRESTADO</b>
  <p>Le informamos que usted puede rechazar el uso e instalación de las cookies de esta web cambiando la configuración de su navegador, pudiendo seguir usando este sitio web, también le advertimos que puede verse limitada la experiencia de uso de esta web si cambia algunas de las opciones que le damos:</p>
  <p><strong>ADMINISTRACION DE COOKIES POR NAVEGADOR: </strong></p>
  <ul>
  <li><strong>Google Chrome</strong>, <a target="_blank" src="http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647">http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647</a></li>
  <li><strong>Explorer</strong>, <a target="_blank" src="http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9">http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9</a>  </li>
  <li><strong>Firefox</strong>, <a target="_blank" src=">http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we">http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we</a></li>
  <li><strong>Safari</strong>, <a target="_blank" src="http://support.apple.com/kb/ph5042">http://support.apple.com/kb/ph5042</a></li>
  </ul>
  <p>Muchos navegadores permiten activar un modo privado mediante el cual las cookies se borran siempre después de su visita. Dependiendo de cada navegador, este modo privado puede tener diferentes nombres, abajo puede encontrar una lista de los navegadores más comunes y los diferentes nombres de este “modo privado”:
  </p><ul>
      <li><strong>Internet Explorer 8 y superior:</strong> InPrivate</li>
  <li><strong>FireFox 3.5 y superior:</strong> Navegación Privada</li>
  <li><strong>Google Chrome 10 y superior:</strong> Incógnito</li>
  <li><strong>Safari 2 y superior:</strong> Navegación Privada</li>
  <li><strong>Opera 10.5 y superior:</strong> Navegación Privada</li>
  </ul>
  <p>Por favor, lea atentamente la sección de ayuda de su navegador para conocer más acerca de cómo activar el “modo privado”. Podrá seguir visitando nuestra Web aunque su navegador esté en “modo privado”, no obstante, la experiencia de usuario puede no ser óptima y algunas utilidades pueden no funcionar.</p>
  <p>Si necesita más información acerca del bloqueo en su equipo de cookies, no dude en atender la explicación que se le da en este enlace Web:   http://windows.microsoft.com/es-es/windows-vista/block-or-allow-cookies</p>
  <p>Aunque las cookies son esenciales para el funcionamiento de internet, aportando innumerables ventajas en la prestación de servicios interactivos, facilitándole la navegación y el uso de nuestra web. Las cookies no pueden dañar su equipo y que, a cambio, el que estén activadas nos ayudan a identificar y resolver los errores.</p>
  <p><strong>TIPOS DE COOKIES:</strong></p>
  <p>La información que le proporcionamos a continuación, le ayudará a comprender los diferentes tipos de cookies:</p>
  <p>Cookies de sesión: son cookies temporales que permanecen en el archivo de cookies de su navegador hasta que abandone la página web, por lo que ninguna queda registrada en el disco duro del usuario. La información obtenida por medio de estas cookies, sirven para analizar pautas de tráfico en la web. A la larga, esto nos permite proporcionar una mejor experiencia para mejorar el contenido y facilitando su uso.
  Cookies permanentes: son almacenadas en el disco duro y nuestra web las lee cada vez que usted realiza una nueva visita. Una web permanente posee una fecha de expiración determinada. La cookie dejará de funcionar después de esa fecha. Las utilizamos, generalmente, para facilitar los servicios de compra y registro.
  </p>
  <p>Además le informamos que las cookies permiten obtener la siguiente información:</p>
  <ul>
  <li>Fecha y hora de la última vez que el usuario visitó la Web.</li>
  <li>Diseño de contenido que el usuario escogió en su primera visita a la web.</li>
  <li>Elementos de seguridad que intervienen en el control de acceso a las áreas restringidas.</li>
  <li>Otro tipo de información de determinadas cookies específicas.</li>
  </ul>
  <p>En todo caso y en cumplimiento de la “Ley  de Cookies” Ley 13/2012 de 30 de marzo,  y del art. 22 de la LSSI, nuestra página Web, en caso de instalar alguna cookie le solicitara su consentimiento previo a la instalación de la misma en el mismo momento que indexe nuestra dirección Web.</p>
  
  <p>En algunos casos, el hecho de no aceptar la instalación de las mismas, nuestra página Web puede no dejarle continuar navegando en la misma. Y algunos de nuestros servicios podrían no funcionar adecuadamente.</p>
  
  <p><strong>COOKIES EN NUESTRA WEB</strong></p>
  
  <p>Dentro de esta Web, pueden ser utilizadas estos tipos de Cookies, distinguiendo:</p>
  
  <p>Las cookies estrictamente necesarias como por ejemplo, aquellas que sirvan para una correcta navegación o las que permitan realizar el pago de bienes o servicios solicitados por el usuario o cookies que sirvan para asegurar que el contenido de la página web se carga eficazmente y correctamente.</p>
  
  <p>Las cookies de terceros, son las usadas por redes sociales, o por complementos externos de contenido como por ejemplo google maps, redes sociales… etc.</p>
  
  <p>Las cookies analíticas con intenciones de mantenimiento periódico, y para garantizar el mejor servicio posible al usuario, se hacen uso normalmente de cookies  analíticas  para recopilar datos estadísticos de la actividad.</p>
   
  <p>En todo caso, si Vd. acepta la instalación de alguna cookie, le detallamos a continuación el nombre de las que nuestra Web le puede instalar y la utilidad de las mismas:</p>
  
  <table>
      <tbody><tr>
          <th style="width:10%">DOMINIO</th>
          <th style="width:10%">NOMBRE COOKIE</th>
          <th style="width:40%">FINALIDAD</th>
          <th style="width:40%">CADUCIDAD</th>
      </tr>
      <tr>
          <td>www.unifersa.es</td>
          <td>PHPSESSID</td>
          <td>Su finalidad es la de controlar la sessión del usuario.Necesarias para la navegación y el buen funcionamiento de nuestra página web. </td>
          <td>Caduca cuando el usuario cierra sesión o bien caduca al cabo de 8 horas de inactividad en la web</td>
      </tr>    
      <tr>
          <td>www.unifersa.es</td>
          <td>_ga</td>
          <td>Cookie de Google Analytics, que ayuda a los propietarios de sitios web a medir cómo interactúan los usuarios con el contenido del sitio. Se usa para distinguir a los usuarios y funciona en conjunto con la cookie _gat</td>
          <td>2 años</td>
      </tr>       
      <tr>
          <td>www.unifersa.es</td>
          <td>cookie_ue</td>
          <td>Recuerda si se acepto la política de cookies para mostrar el aviso.</td>
          <td>1 año</td>
      </tr>       
  </tbody></table> 
  
  <p>UNIFERSA 2006 S.A. ha seguido los criterios y consejos que la Agencia Española de Protección de datos, ha publicado en su “Guía para el uso de las Cookies” de año 2013.</p>