<div class="container">
  <header-admin title="{{title}}">
    <div class="orders">
      <span *ngFor="let sort of objectKeys(sortName); index as i" (click)="sortOrder(sort, i)">Orden por {{sortName[sort]}} 
        <span *ngIf="!desc[i]">
        <svg class="svg" xmlns="http://www.w3.org/2000/svg" version="1.0" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
          <g xmlns="http://www.w3.org/2000/svg" transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
          <path d="M2422 5105 c-71 -16 -154 -51 -226 -98 -29 -18 -270 -260 -580 -582 -564 -585 -585 -610 -629 -750 -33 -104 -30 -272 6 -373 68 -194 231 -345 427 -397 72 -19 230 -19 305 0 33 9 90 31 128 50 l67 34 0 -1213 c0 -1174 1 -1215 20 -1290 72 -284 330 -486 620 -486 237 0 465 141 569 350 74 151 71 84 71 1432 l0 1207 68 -34 c93 -47 170 -65 282 -65 174 0 309 56 430 179 199 201 236 497 93 740 -28 48 -175 207 -564 610 -289 300 -547 561 -572 579 -147 105 -340 145 -515 107z m283 -345 c54 -26 114 -85 570 -559 567 -589 560 -580 560 -706 -1 -84 -25 -145 -82 -202 -53 -53 -106 -76 -183 -81 -84 -5 -138 10 -192 53 -116 93 -197 118 -291 91 -75 -22 -136 -70 -172 -136 l-30 -55 -5 -1305 -5 -1305 -22 -47 c-27 -60 -94 -127 -158 -158 -41 -20 -65 -25 -135 -25 -77 1 -91 4 -148 34 -70 38 -100 69 -140 145 l-27 51 -5 1305 -5 1305 -25 48 c-46 92 -134 146 -234 147 -79 0 -125 -15 -183 -61 -78 -63 -146 -92 -217 -91 -169 1 -291 122 -291 287 0 126 -7 117 558 704 526 548 551 571 649 591 66 13 146 2 213 -30z"/>
          </g></svg>
        </span>
        <span *ngIf="desc[i]">
        <svg class="svg" xmlns="http://www.w3.org/2000/svg" version="1.0" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
          <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
          <path d="M2422 5105 c-231 -50 -423 -238 -482 -471 -19 -75 -20 -116 -20 -1290 l0 -1213 -67 34 c-94 47 -172 66 -280 66 -113 0 -165 -12 -272 -63 -318 -151 -436 -576 -242 -875 45 -71 1054 -1121 1124 -1171 197 -140 445 -159 667 -51 77 38 100 56 246 203 362 363 927 959 964 1018 62 95 93 202 93 323 1 179 -55 317 -178 441 -118 118 -254 174 -425 174 -113 0 -188 -17 -282 -65 l-68 -35 0 1213 c0 1175 -1 1216 -20 1291 -30 117 -83 209 -170 296 -156 156 -374 221 -588 175z m274 -336 c62 -30 123 -90 155 -154 l24 -50 5 -1305 c6 -1452 -1 -1335 74 -1418 103 -113 267 -116 396 -7 66 56 114 75 191 75 88 0 150 -23 205 -76 62 -61 88 -121 88 -209 1 -127 8 -118 -560 -708 -461 -479 -514 -531 -572 -559 -55 -27 -75 -32 -142 -32 -67 0 -87 5 -142 32 -58 28 -111 80 -573 561 -345 358 -518 545 -535 577 -21 41 -25 62 -25 134 0 101 18 143 88 208 62 56 108 75 192 76 84 1 136 -20 218 -87 65 -53 112 -70 187 -70 99 0 194 59 240 150 l25 48 5 1305 c4 1016 8 1311 18 1333 39 90 112 158 202 191 62 22 170 15 236 -15z"/>
          </g>
          </svg>
        </span>
        &nbsp;&nbsp; &nbsp;
      </span>
      <!--span (click)="sortOrder(2)">Orden por fecha 
        <span *ngIf="!orderDate">
        <svg class="svg" xmlns="http://www.w3.org/2000/svg" version="1.0" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
          <g xmlns="http://www.w3.org/2000/svg" transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
          <path d="M2422 5105 c-71 -16 -154 -51 -226 -98 -29 -18 -270 -260 -580 -582 -564 -585 -585 -610 -629 -750 -33 -104 -30 -272 6 -373 68 -194 231 -345 427 -397 72 -19 230 -19 305 0 33 9 90 31 128 50 l67 34 0 -1213 c0 -1174 1 -1215 20 -1290 72 -284 330 -486 620 -486 237 0 465 141 569 350 74 151 71 84 71 1432 l0 1207 68 -34 c93 -47 170 -65 282 -65 174 0 309 56 430 179 199 201 236 497 93 740 -28 48 -175 207 -564 610 -289 300 -547 561 -572 579 -147 105 -340 145 -515 107z m283 -345 c54 -26 114 -85 570 -559 567 -589 560 -580 560 -706 -1 -84 -25 -145 -82 -202 -53 -53 -106 -76 -183 -81 -84 -5 -138 10 -192 53 -116 93 -197 118 -291 91 -75 -22 -136 -70 -172 -136 l-30 -55 -5 -1305 -5 -1305 -22 -47 c-27 -60 -94 -127 -158 -158 -41 -20 -65 -25 -135 -25 -77 1 -91 4 -148 34 -70 38 -100 69 -140 145 l-27 51 -5 1305 -5 1305 -25 48 c-46 92 -134 146 -234 147 -79 0 -125 -15 -183 -61 -78 -63 -146 -92 -217 -91 -169 1 -291 122 -291 287 0 126 -7 117 558 704 526 548 551 571 649 591 66 13 146 2 213 -30z"/>
          </g></svg>
        </span>
        <span *ngIf="orderDate">
        <svg class="svg" xmlns="http://www.w3.org/2000/svg" version="1.0" width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet">
          <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
          <path d="M2422 5105 c-231 -50 -423 -238 -482 -471 -19 -75 -20 -116 -20 -1290 l0 -1213 -67 34 c-94 47 -172 66 -280 66 -113 0 -165 -12 -272 -63 -318 -151 -436 -576 -242 -875 45 -71 1054 -1121 1124 -1171 197 -140 445 -159 667 -51 77 38 100 56 246 203 362 363 927 959 964 1018 62 95 93 202 93 323 1 179 -55 317 -178 441 -118 118 -254 174 -425 174 -113 0 -188 -17 -282 -65 l-68 -35 0 1213 c0 1175 -1 1216 -20 1291 -30 117 -83 209 -170 296 -156 156 -374 221 -588 175z m274 -336 c62 -30 123 -90 155 -154 l24 -50 5 -1305 c6 -1452 -1 -1335 74 -1418 103 -113 267 -116 396 -7 66 56 114 75 191 75 88 0 150 -23 205 -76 62 -61 88 -121 88 -209 1 -127 8 -118 -560 -708 -461 -479 -514 -531 -572 -559 -55 -27 -75 -32 -142 -32 -67 0 -87 5 -142 32 -58 28 -111 80 -573 561 -345 358 -518 545 -535 577 -21 41 -25 62 -25 134 0 101 18 143 88 208 62 56 108 75 192 76 84 1 136 -20 218 -87 65 -53 112 -70 187 -70 99 0 194 59 240 150 l25 48 5 1305 c4 1016 8 1311 18 1333 39 90 112 158 202 191 62 22 170 15 236 -15z"/>
          </g>
          </svg>
        </span>
      </span-->
    </div>
  </header-admin>
  <unifersa-tabla [headers]="headers">
      <div class="row" *ngFor="let order of orders">
        <div class="user">
          <div class="wrapper">
            <div class="init" *ngIf="id==0">{{order.user.charAt(0)}}</div>
            <div class="init" *ngIf="id>0">{{order.id}}</div>
            <div class="data">
              <div class="title" *ngIf="!isAsociados">{{order.user}}</div>
              <div class="title" *ngIf="isAsociados || id>0">{{order.business}}</div>
              <div class="subtitle">{{order.email}}</div>
            </div>
          </div>
        </div>
        <div class="provider" *ngIf="id>0">
          {{order['provider']}}
        </div>
        <div class="price" *ngIf="id>0">
          <span *ngIf="order.lastname">{{order.lastname}}€</span>
        </div>
        <div class="date" *ngIf="!(isAsociados || isProvider)">{{order.date}}</div>
        <div class="total" *ngIf="(isAsociados || isProvider)">{{order.date}}</div>
        <div class="importe" *ngIf="(isAsociados || isProvider) && order['importe']">
          {{order['importe']}}€
        </div> 
        <div class="actions">
          <div class="action more" (click)="getProductsOrder(order.id)">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5.88 4.12L13.76 12l-7.88 7.88L8 22l10-10L8 2z"/></svg>
          </div>
        </div>
      </div>
  </unifersa-tabla>
</div>