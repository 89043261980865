import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'header-admin',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderAdminComponent implements OnInit {

  @Input() title:string;
  @Input() back:boolean;

  constructor(private location:Location) { }

  ngOnInit() {
  }

  backRoute(){
    this.location.back();
  }

}
