import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/api/api.service';
import { User } from 'src/app/api/models/user/user';
import { NotificationService } from 'src/app/notification/notification.service';

@Component({
  selector: 'app-setpass',
  templateUrl: './setpass.component.html',
  styleUrls: ['./setpass.component.less']
})
export class SetpassComponent implements OnInit {
  private hash: string;
  public user:User;


  constructor(private route:ActivatedRoute, private router: Router, private api: ApiService, private notification: NotificationService) { 
    let that = this;
    this.route.params.subscribe(
      data=>{
        that.hash = data.code;
        that.getUser();
      }
    );
  }

  ngOnInit() {
  }

  getUser(){
    let that = this;
    this.api.user.getUserRecPass(this.hash).subscribe(
      data=>{
        if (data.code == 200){
          that.user = data.response;
          that.user['pass'] = "";
        }
      }
    );
  }

  changePass(){
    let that = this;
    this.user.rec_pass="";
    if (this.user['passv'] == null){
      if (this.user['pass'] != null){
        this.notification.error("Para cambiar la contraseña tiene que cubrir el campo: 'Validar contraseña'.");
      }else{
        this.user['pass']=null;   
        this.update();
      }   
    }else{
      if (this.user['pass'] == this.user['passv']){
        this.update();
      }else{
        this.notification.error("Contraseña no válida.")
      }
    }
  }

  update(){
    let that = this;
    this.api.user.updateUser(this.user.id, [this.user]).subscribe(
      data=>{
        that.notification.success("Datos actualizados");
        that.router.navigate(['/']);
      },
      error=>{
        that.notification.error("Ha ocurrido un error.");
        console.log(error);
      }
    );
  }
}
