<h3 id="dos">1.	PROTECCION DE DATOS DE CARÁCTER PERSONAL</h3>
  <h4 id="dosuno">1.1	DATOS DE CONTACTO DEL RESPONSABLE DE TRATAMIENTO</h4>
  <p><b>Denominación social:</b> UNIFERSA 2006 S.A</p>
  <p><b>NIF:</b> A 70063383</p>
  <p><b>Domicilio social:</b> C/LEME POLÍGONO INDUSTRIAL VILAR DO COLO S/N 15500 - FENE (A Coruña)</p>
  <p><b>Teléfono Contacto:</b> 981.39.64.74</p>
  <p><b>Dirección de correo electrónico:</b> rgpdunifersa@unifersa.es </p>
  <h4 id="dosdos">1.2 DATOS DE CONTACTO DEL DATA PROTECTION OFFICER  (D.P.O.)</h4>
  <p>Martin Lopez Escartín, abogado ICAM 103516 y con despacho para notificaciones en HONOS ABOGADOS S.L.P. en Calle San Bernardo nº 21, 2º de Gijón (Principado de Asturias). Con teléfono 608781399 y mail martin.lopez@honosabogados.com</p>
  <h4 id="dostres">1.3 POLITICA DE PRIVACIDAD - DEBER DE INFORMACIÓN CONFORME AL RGPD 679/2016</h4>
  <p>El cliente que accede a poner sus datos en los formularios de contacto de esta página web, conforme a lo expuesto en este párrafo de este aviso legal, DECLARA Y MANIFIESTA EXPRESAMENTE QUE:</p>
  <p>•	Autoriza expresamente a la entidad UNIFERSA 2006 S.A con domicilio en C/LEME POLÍGONO INDUSTRIAL VILAR DO COLO S/N de 15500 - FENE (A Coruña) a la recogida y tratamiento de los datos  de carácter personal de quien firma, o actúa como representante legal, tutor o curador, de alguna persona menor de edad. Así mismo declara que se le ha informado convenientemente acerca de la tabla que se presenta en relación al derecho recogido en el art. 13 del RGPD.</p>
  <p>
  </p><table class="table">
    <tbody><tr>
    <td colspan="2">INFORMACION BASICA SOBRE PROTECCION DE DATOS FICHERO DE DATOS CLIENTES
    </td>
    </tr>
    <tr>
      <td style="font-weight:bold">RESPONSABLE</td>
      <td>UNIFERSA 2006 S.A</td>
    </tr>
    <tr>
      <td style="font-weight:bold">FINALIDAD PREVISTA</td>
      <td>Gestión de los datos introducidos en los formularios de recogida de datos de la página web. 
  Envío de publicidad.
  </td>
    </tr>
    
    <tr>
      <td style="font-weight:bold">D.P.O. (Data Protection Officer)</td>
      <td>MARTIN LOPEZ ESCARTIN – Abogado ICAM 103516</td>
    </tr>
    <tr>
      <td style="font-weight:bold">LEGITIMACION</td>
      <td>Consentimiento interesado</td>
    </tr>
    <tr>
      <td style="font-weight:bold">DESTINATARIOS DE CESIONES DE DATOS</td>
      <td>Empresas del mismo grupo.
  No se cederán datos salvo que sea necesario para cumplir con la propia prestación solicitada.
  No se cederán datos salvo por mandato legal.
  Asesoría fiscal y contable. (en caso de compra a través de la web)
  </td>
    </tr>
    <tr>
      <td style="font-weight:bold">DERECHOS</td>
      <td><p>Derecho a solicitar el <u>acceso</u> a los datos personales relativos al interesado</p>
  <p>Derecho a solicitar su <u>rectificación o supresión</u></p>
  <p>Derecho a solicitar la <u>limitación de su tratamiento</u></p> 
  <p>Derecho a <u>oponerse</u> al tratamiento</p>
  <p>Derecho a la <u>portabilidad</u> de los datos</p>
  </td>
    </tr>
    <tr>
      <td style="font-weight:bold">INFORMACION ADICIONAL</td>
      <td>Puede ver nuestra política de privacidad en: <a href="https://www.unifersa.es">https://www.unifersa.es</a> </td>
    </tr>
    <tr>
      <td style="font-weight:bold">REVOCACION CONSENTIMIENTO</td>
      <td>En cualquier momento.</td>
    </tr>
    <tr>
      <td style="font-weight:bold">COMO EJERCITAR SUS DERECHOS</td>
      <td>Solicite nuestros formularios para el ejercicio de derechos en:
  (Dirección del cliente expuesta en el apartado 2.1)
  <a href="https://www.agpd.es">www.agpd.es</a>
  </td>
    </tr>
  </tbody></table>
  <p></p>
  <p>Se prohíbe el que menores de 16 años pongan los datos en nuestros formularios de recogida de datos, dado que es contrario al art. 8 RGPD, en caso de necesitar el consentimiento de un menor, deberá darlo y por lo tanto recoger datos de la persona que ostenta la Patria Potestad y/o tutela del menor.
  La presente política de protección de datos podrá variar con el tiempo debido a los posibles cambios legislativos, jurisprudenciales o de los criterios seguidos por la Agencia de Española de Protección de Datos y/o la autoridad competente en cada momento. Es por ello que UNIFERSA 2006 S.A se reserva el derecho a modificar el presente aviso legal para adaptarlo a novedades legislativas o jurisprudenciales que se encuentran vigentes en el momento preciso en que se acceda a los websites, así como a prácticas del sector. Le pedimos que revise este aviso legal periódicamente.
  </p>
  <h4 id="doscuatro">1.4.DERECHOS RECONOCIDOS POR EL REGLAMENTO UE 2016/679</h4>
  <ul>
  <li>Derecho a solicitar el acceso a los datos personales: usted podrá preguntar a UNIFERSA 2006 S.A si tratamos sus datos.</li>
  <li>Derecho a solicitar su rectificación, siempre y cuando estén recogidos incorrectamente; y o solicitar su supresión.</li>
  <li>Derecho a solicitar la limitación de su tratamiento, </li>
  <li>Derecho a oponerse al tratamiento: Dejaremos de tratar sus datos, salvo por motivos legales.</li>
  <li>Derecho a la portabilidad de los datos: Puede pedirnos en cualquier momento que enviemos sus datos a la mercantil que desee, mediante petición expresa.</li>
  </ul>
  <h4 id="doscinco">1.5.COMO PUEDO SOLICITAR LOS DERECHOS</h4>
  <p>En cualquier momento, puede enviarnos un mail utilizando los formularios que la Agencia Española de protección de datos tiene en su página web www.agpd.es con la solicitud de sus derechos.</p>
  <h3 id="tres">2.	PRINCIPIOS BASICOS QUE RIGEN EN NUESTRA POLITICA DE PRIVACIDAD.</h3>
  <h4 id="tresuno">A. CONFIDENCIALIDAD</h4>
  <p>
  Todos los datos proporcionados mediante correo electrónico o formularios electrónicos serán tratados según la normativa vigente sobre protección de datos personales, y en todo caso tendrán el carácter de confidencial para el personal de UNIFERSA 2006 S.A que gestione dicha información, como así firman en los contratos de confidencialidad que nuestra entidad regula con sus empleados.
  </p>
  <h4 id="tresdos">B.	INFORMACIÓN SOBRE LA VOLUNTAD DE DEJAR LOS DATOS Y SUS CONSECUENCIAS</h4>
  <p><b>VOLUNTARIEDAD</b></p>
  <p>Se informa a los Usuarios de los websites, que las respuestas a las preguntas planteadas en los formularios de recogida de datos contenidos en la presente website tienen carácter voluntario, si bien la negativa a facilitar los datos solicitados puede comportar la imposibilidad de acceso a los servicios que lo requieran.</p>
  <p><b>CONSECUENCIA</b></p>
  <p>Mediante la implementación de los formularios incluidos en los diferentes websites, relativos a servicios prestados por UNIFERSA 2006 S.A, los Usuarios aceptan la inclusión y tratamiento de los datos que proporcionen en un fichero de datos de carácter personal, del cual es titular de UNIFERSA 2006 S.A, pudiéndose ejercer los derechos pertinentes según la cláusula siguiente.</p>
  <p>Asimismo, si un Usuario no desea recibir información vía correo electrónico o cualquier otro medio, podrá comunicarlo por cualquier medio que quede constancia de su recepción a UNIFERSA 2006 S.A en la dirección indicada.</p>
  <b>INFORMACIÓN SOBRE LOS DATOS QUE SE CONSERVAN</b>
  <p>
  Los datos que conservamos son los meramente recogidos en los formularios que se tienen en la página web como son datos de contacto (carácter básico), como son el nombre, apellido, e-mail, teléfono, etc. Esta información es recibida por UNIFERSA 2006 S.A y nunca es vendida, cedida o arrendada a otras empresas, exceptuando el caso lógico para la prestación del servicio.
  En ocasiones, recogemos los datos de navegación, como datos de IP, de wifi.
  </p>
  <b>DURANTE CUANTO TIEMPO</b>
  <p><b>Datos recogidos para la entrega de nuestro newletter:</b> Desde la suscripción hasta el momento que solicita el usuario la baja del servicio mediante el envío de un mail. </p>
  <p><b>Datos de usuarios subidos a páginas y redes sociales:</b> Desde que el usuario ofrece su consentimiento hasta que nos comunica su voluntad de permanecer en las mismas.
  </p>
  <b>CON QUÉ FINALIDAD</b>
  <p>La única finalidad de tratar sus datos es prestarle el servicio solicitado por nuestra empresa.</p>
  <h3 id="cuatro">3.	BAJA DE LA LISTA DE DISTRIBUCIÓN DE INFORMACIÓN</h3>
  <p>
  Ocasionalmente, UNIFERSA 2006 S.A envía un e-mail notificando mejoras, novedades u ofertas, que se produzcan en esta página Web. En cualquier momento pueden darse de baja enviando un e-mail a UNIFERSA 2006 S.A 
  </p>
  <h3 id="cinco">4.	RESPONSABILIDAD DE LOS USUARIOS POR USO Y CONTENIDO</h3>
  <p>Tanto el acceso a los websites como el uso que pueda realizarse de la información y contenidos incluidos en los mismos, será de exclusiva responsabilidad de quien lo realice.
  Por tanto, el uso que pueda hacerse de la información, imágenes, contenidos y/o productos reseñados y accesibles a través del mismo, estará supeditada a la legalidad, sea nacional o internacional, aplicable, así como a los principios de buena fe y uso lícito por parte de los Usuarios, quienes serán enteramente responsables de dicho acceso y correcto uso.
  Los Usuarios estarán obligados a hacer un uso razonable de los servicios o contenidos, bajo el principio de buena fe y con respeto a la legalidad vigente, a la moral, al orden público, a las buenas costumbres, a los derechos de terceros o de la propia UNIFERSA 2006 S.A, todo ello según las posibilidades y fines para los que están concebidos. UNIFERSA 2006 S.A no asume responsabilidades, ya sean directas o indirectas, por daño emergente o lucro cesante, derivadas del mal uso de los servicios o contenidos realizado por los Usuarios o terceros.</p>