import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'unifersa-tabla',
  templateUrl: './tabla.component.html',
  styleUrls: ['./tabla.component.less']
})
export class TablaComponent implements OnInit {
  @Input() headers:Array<string>;

  constructor() { }

  ngOnInit() {
  }

}
