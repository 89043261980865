  <div class="container">
    <div class="content-form">
      <div class="logo-app">
        <img src="assets/logo.jpg" alt="Logo" title="Logo Unifersa"/>
      </div>
      <form id="loginForm" #loginForm="ngForm" *ngIf="user">
        <div class="input-form">
          <input type="text" [(ngModel)]="user.email" id="email" placeholder="E-Mail *" name="email" readonly/>
        </div>
        <div class="input-form">
          <input type="password" [(ngModel)]="user.pass" id="pass" placeholder="Contraseña *" name="pass" required/>
        </div>
        <div class="input-form">
            <input type="password" [(ngModel)]="user.passv" id="passv" placeholder="Verificar Contraseña *" name="passv" required/>
        </div>
       
        <button type="submit" (click)="changePass($event)">Cambiar contraseña</button>
      </form>
    </div>
  </div>