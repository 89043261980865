import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';    
import { ToastrModule } from 'ngx-toastr';
import { NgxPaginationModule } from 'ngx-pagination';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';

import { ConfigService } from './config/config.service';
import { ApiService } from './api/api.service';
import { UserService } from './api/models/user/user.service';
import { ProviderService } from './api/models/provider/provider.service';
import { ProductService } from './api/models/product/product.service';
import { OrderService } from './api/models/order/order.service';
import { CookieService } from 'ngx-cookie-service';
import { NotificationService } from './notification/notification.service';
import { HeaderComponent } from './main/header/header.component';
import { MainComponent } from './main/main.component';
import { AdminComponent } from './admin/admin.component';
import { MenuComponent } from './admin/menu/menu.component';
import { TablaComponent } from './admin/tabla/tabla.component';
import { UserComponent } from './admin/user/user.component';
import { ListComponent } from './main/list/list.component';
import { ProvidersComponent } from './main/providers/providers.component';
import { ProductsComponent } from './main/products/products.component';
import { UserDataComponent } from './admin/user-data/user-data.component';
import { PaginationComponent } from './pagination/pagination.component';
import { ProviderComponent } from './admin/provider/provider.component';
import { ProviderDataComponent } from './admin/provider-data/provider-data.component';
import { ProductViewComponent } from './main/products/view/view.component';
import { CartComponent } from './main/cart/cart.component';
import { OrderComponent } from './admin/order/order.component';
import { OrderDataComponent } from './admin/order-data/order-data.component';
import { SeekerComponent } from './main/header/seeker/seeker.component';
import { SearchComponent } from './main/search/search.component';
import { ModalComponent } from './admin/modal/modal.component';
import { ProviderProductsComponent } from './admin/provider/provider-products/provider-products.component';
import { HeaderAdminComponent } from './admin/header/header.component';
import { FooterComponent } from './main/footer/footer.component';
import { AvisoLegalComponent } from './main/aviso-legal/aviso-legal.component';
import { PoliticaPrivacidadComponent } from './main/politica-privacidad/politica-privacidad.component';
import { PoliticaCookiesComponent } from './main/politica-cookies/politica-cookies.component';

import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { AccessComponent } from './admin/user/access/access.component';

import { OrdersComponent } from './main/orders/orders.component';
import { ProfileComponent } from './main/profile/profile.component';
import { SetpassComponent } from './login/setpass/setpass.component';
import { RememberComponent } from './login/remember/remember.component';
import { ProductsXmlComponent } from './products-xml/products-xml.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ProviderContactsComponent } from './main/provider-contacts/provider-contacts.component';
import { ContactFormComponent } from './main/provider-contacts/form/form.component';
import { DocumentsComponent } from './admin/user/documents/documents.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    MainComponent,
    AdminComponent,
    MenuComponent,
    TablaComponent,
    UserComponent,
    ListComponent,
    ProvidersComponent,
    ProductsComponent,
    UserDataComponent,
    PaginationComponent,
    ProviderComponent,
    ProviderDataComponent,
    ProductViewComponent,
    CartComponent,
    OrderComponent,
    OrderDataComponent,
    SeekerComponent,
    SearchComponent,
    ModalComponent,
    ProviderProductsComponent,
    HeaderAdminComponent,
    FooterComponent,
    AvisoLegalComponent,
    PoliticaPrivacidadComponent,
    PoliticaCookiesComponent,
    AccessComponent,
    OrdersComponent,
    ProfileComponent,
    SetpassComponent,
    RememberComponent,
    ProductsXmlComponent,
    ProviderContactsComponent,    ContactFormComponent, DocumentsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,  
    ToastrModule.forRoot(),
    NgxPaginationModule,
    AngularFontAwesomeModule,
    FontAwesomeModule
  ],
  providers: [
    CookieService,
    ConfigService,
    ApiService,
    UserService,
    ProviderService,
    ProductService,
    OrderService,
    NotificationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
