<div class="container">
  <header-admin title="Lista de usuarios">
    <div class="orders">
      <span (click)="sortUser(1)">Orden por nombre <span *ngIf="!orderUser">as</span><span *ngIf="orderUser">des</span>c</span> 
      &nbsp;&nbsp; &nbsp;
      <span (click)="sortUser(3)">Orden por correo <span *ngIf="!orderEmail">as</span><span *ngIf="orderEmail">des</span>c</span> 
      &nbsp;&nbsp; &nbsp;
      <span (click)="sortUser(2)">Orden por empresa <span *ngIf="!orderEmpres">as</span><span *ngIf="orderEmpres">des</span>c</span>
      &nbsp;&nbsp; &nbsp;
      <span (click)="sortUser(4)">Orden por docs <span *ngIf="!orderDocs">as</span><span *ngIf="orderDocs">des</span>c</span>
      &nbsp;&nbsp; &nbsp;
      <span (click)="sortUser(5)">Orden por provincia <span *ngIf="!orderProv">as</span><span *ngIf="orderProv">des</span>c</span>
    </div>
  </header-admin>
  <unifersa-tabla [headers]="headers">
    <div class="row" *ngFor="let us of users">
      <div class="user">
        <div class="wrapper">
          <div class="init">{{us.contact.charAt(0)}}</div>
          <div class="data">
            <div class="title">{{us.contact}} </div>
            <div class="subtitle">{{us.email}}</div>
          </div>
        </div>
      </div>
      <div class="name">{{us.contact}}</div>
      <div class="business">{{us.business}}</div>
      <div class="address">{{us.province}}</div>
      <div class="docs">{{us.docs}}</div>
      <div class="role">
        <span class="label admin" *ngIf="us.role==1">Cliente Asociado</span>
        <span class="label client" *ngIf="us.role==2">Administrador</span>
        <span class="label provider" *ngIf="us.role==3">Proveedor</span>
        <span class="label compras" *ngIf="us.role==4">Cliente Compras</span>
        <span class="label demo" *ngIf="us.role==5">Demo</span>
      </div>
      <div class="credito">
        <div *ngIf="us.credito==1" class="action delete" (click)="changeCredito(us.id, us.credito)" title="Crédito">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path xmlns="http://www.w3.org/2000/svg" d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/></svg>
        </div>
        <div *ngIf="us.credito==0" class="action delete" (click)="changeCredito(us.id, us.credito)" title="Crédito">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path xmlns="http://www.w3.org/2000/svg" d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/></svg>
        </div>
      </div>
      <div class="actions">
        
        <div class="action edit" (click)="getUserId(us.id)" title="Editar">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/></svg>
        </div>
        <div *ngIf="us.baja==0" class="action delete" (click)="deleteUser(us.id, us.baja)" title="Eliminar">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/></svg>
        </div>
        <div *ngIf="us.baja==1" class="action delete" (click)="deleteUser(us.id, us.baja)" title="Recuperar">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path xmlns="http://www.w3.org/2000/svg" d="M12 4V1L8 5l4 4V6c3.31 0 6 2.69 6 6 0 1.01-.25 1.97-.7 2.8l1.46 1.46C19.54 15.03 20 13.57 20 12c0-4.42-3.58-8-8-8zm0 14c-3.31 0-6-2.69-6-6 0-1.01.25-1.97.7-2.8L5.24 7.74C4.46 8.97 4 10.43 4 12c0 4.42 3.58 8 8 8v3l4-4-4-4v3z"/></svg>
        </div>
        <div class="action more" (click)="getAccessByUser(us.id)">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M5.88 4.12L13.76 12l-7.88 7.88L8 22l10-10L8 2z"/></svg>
        </div>
      </div>
    </div>
  </unifersa-tabla>
  <div class="add" (click)="insertUser()">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
  </div>
</div>
<modal text="{{textModal}}" title="Eliminar usuario" id="{{id}}" (hideModal)="hide($event)" (action)="delete($event)" *ngIf="deleteModal"></modal>
