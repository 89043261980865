import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/notification/notification.service';
import { Provider } from '../../../api/models/provider/provider';
import { Product } from '../../../api/models/product/product';
import { ApiService } from 'src/app/api/api.service';

@Component({
  selector: 'app-provider-products',
  templateUrl: './provider-products.component.html',
  styleUrls: ['./provider-products.component.less']
})
export class ProviderProductsComponent implements OnInit {
  public n: number;
  p:number = 1;
  public products : Product[];
  public deleteModal: boolean = false;
  public deleteAllModal: boolean = false;
  public cleanModal: boolean = false;
  eann: string;
  headers:Array<any>;
  provider:Provider;

  constructor(private router:Router, private notification: NotificationService, private _route: ActivatedRoute, private api: ApiService) {
    this.n = parseInt(this._route.snapshot.paramMap.get('id'));
    this.getProducts(this.n);

    this.api.provider.getProviderId(this.n).subscribe(
      data => {
        this.provider=data.response;
        //console.log(this.provider);
      },
      error => {
        console.error(error);
      }
    );
   }

  ngOnInit() {
    this.headers=[
      {
        name: "Referencia",
        class: "min-176"
      },
      {
        name: "Ean",
        class: "min-121"
      }, 
      {
        name: "Descripción",
        class: "min-401"
      }, 
      {
        name: "Precio",
        class: "min-81"
      },
      {
        name: "",
        class: ""
      }
    ];
  }

  getProducts(n:number){
    this.api.product.getProductsByProvider(n, 0).subscribe(
      data =>{
        this.products = data.response;
      },
      err =>{
        console.log("Error al obtener los productos de este proveedor.");
        this.notification.error("No se han podido obtener los productos. "+ err.error.message);
      }
    );
  }

  verData(){
    this.router.navigate(["/admin/provider/"+this.n]);
  }

  changeConds(){
    this.provider.show_conds=!this.provider.show_conds;
    this.api.provider.updateProvider(this.n, this.provider).subscribe(
      data => {
        console.log(data);
        this.notification.success("Vista de condiciones modificada correctamente.");
      },
      error => {
        this.notification.error("Error al modificar los datos.");
        console.error(error);
      }
    );
  }

  deleteProdutc(ean:string){
    this.deleteModal= true;
    this.eann = ean;
  }

  cleanProducts(){
    this.cleanModal= true;
  }

  removeAllProducts(){
    this.deleteAllModal = true;
  }

  hide(event):void{
    this.deleteModal= false;
    this.cleanModal= false;
    this.deleteAllModal= false;
  }

  delete(event):void{
    //Si está en el carrito no se puede borrar
    this.api.product.deleteProduct(this.eann, this.n).subscribe(
      data=>{
        if (data.code == 200){
          this.notification.success("Producto eliminado.");
          window.location.reload();
        }
        this.hide(event);
        
      },
      error =>{
        console.log(error);
        this.notification.error("No se ha podido borrar el producto.");
        this.hide(event);
      }
    );
  }

  clean(event):void{
    //Si está en el carrito no se puede borrar
    this.api.provider.cleanProducts(this.n).subscribe(
      data=>{
        if (data.code == 200){
          this.notification.success("Productos sin ean eliminados.");
          window.location.reload();
        }
        this.hide(event);
        
      },
      error =>{
        console.log(error);
        this.notification.error("No se ha podido borrar el producto.");
        this.hide(event);
      }
    );
  }

  removeAll(){
    //Eliminar (poner fecha de baja) tódos los productos del proveedor
    let that = this;
    let ean = null;
    this.api.product.deleteProduct(ean, this.n).subscribe(
      data=>{
        if (data && data.code == 200){
          window.location.reload();
        }
      }
    );
  }

}
