import { Component, OnInit, Input } from '@angular/core';

import { ApiService } from '../api/api.service';
import { NotificationService } from "../notification/notification.service";
import { Router } from '@angular/router';
import { User } from '../api/models/user/user';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'form-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {
  verification: boolean= false;
  private userData: any = [];
  code = null;

  public email:string;
  public pass:string;
  public notif:string="";
  public notif2:string="";
  public blockButton:boolean = false;
  public error : string = "";

  constructor(private api: ApiService, private notification: NotificationService, private router: Router, private cookie: CookieService) {
    let blockLogin = this.cookie.get('blockLogin');
    if (blockLogin == '1'){
      this.notif = "Aún no tienes permisos para acceder.";
      this.blockButton = true;
    }
  }

  ngOnInit() {
  }

  login(e:Event=null){
    if (e) e.preventDefault();
    
    this.api.user.login(this.email, this.pass).subscribe(
      data => {
        if(data.code===200){
          if(data.response instanceof Array){
            this.userData = data.response[0];
            //this.notification.success("Has solicitado el acceso desde esta IP. Espera a que sea autorizado por el administrador.");
            this.notif = "Tu acceso ha sido solicitado desde esta IP.";
            this.notif2 = "Recibirás un código en tu correo electrónico que debes introducir a continuación para verificar tu acceso.";
            /*this.cookie.set("blockLogin", '1', 0.18, "/", undefined, true, "None");
            this.setIdCookieByEmail();
            this.blockButton = true;*/
            this.verification = true;
          }
          else{
            this.userData = data.response;
            this.api.user.setIdCookie(data.response.id);
            this.api.user.setNameCookie(data.response.name);
            this.api.user.setRoleCookie(data.response.role);
            this.notification.success("¡Bienvenido a Unifersa!");
            window.location.href="/";
            //this.verification = true;
          }
        }
      },
      err => {
        if(err.error==null || err.code == 500 || err.status == 500){
          //this.notification.error("Aún no tienes permisos para acceder.");
          this.notif = "Aún no tienes permisos para acceder.";
          this.cookie.set("blockLogin", '1', 0.18, "/", undefined, true, "None");
          this.blockButton = true;
          
          this.setIdCookieByEmail();
        }else{ 
          this.notification.error(err.error.message);
        }
      }
    );
  }

  checkActive(){
    if(document.querySelector("#remember").getAttribute("checked")){
      document.querySelector("#remember").removeAttribute("checked");
    }
    else{
      document.querySelector("#remember").setAttribute("checked", "true");
    }
  }

  goTo(dest){
    this.router.navigate([dest]);
  }

  verify(){
    if (parseInt(this.code) == this.code){
      let that = this;
      this.api.user.verify(this.userData.id, this.code).subscribe(
        data=>{
          if (data && data.code == 200){
            this.api.user.setIdCookie(that.userData.id);
            this.api.user.setNameCookie(that.userData.name);
            this.api.user.setRoleCookie(that.userData.role);
            this.notification.success("¡Bienvenido a Unifersa!");
            window.location.href="/";
          }
        },
        error=>{
          this.notification.error(error.error.message);
          this.code = "";
        }
      );
    }else{
      this.notification.error("Código incorrecto.");
    }
  }

  checkBlock(){
    let id = parseInt(this.cookie.get('userid'));console.log(id);
    if (id>0){
      let that = this;
      this.api.user.checkThisIp(id).subscribe(
        data=>{
          if (data.response){
            if (data.response.length>0 && data.response[0].enable == 1){
              that.cookie.delete('blockLogin');
              window.location.reload();
            }else{
              that.error = "Aún no tienes permisos para acceder. Recibirás un correo electrónico cuando tengas permiso para acceder.";
            }
          }
        }
      );
    }else{
      if (Number.isNaN(id) && this.cookie.check('blockLogin') && this.cookie.get('blockLogin') == '1'){
        this.cookie.delete('blockLogin');
        window.location.reload();
      }
    }
  }

  setIdCookieByEmail(){
    this.api.user.getUsersEmail(this.email).subscribe(
      data=>{
        if (data.code===200){
          this.api.user.setIdCookie(data.response.id);
        }
      }
    );
  }
}
