import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { parse } from 'querystring';
import { NotificationService } from 'src/app/notification/notification.service';
import { ApiService } from 'src/app/api/api.service';
import { User } from 'src/app/api/models/user/user';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.less']
})
export class ProfileComponent implements OnInit {
  public user:User;
  public n:number;

  constructor(private api: ApiService,private cookie: CookieService,private router: Router, private notification: NotificationService) {
    this.n = parseInt(this.cookie.get('userid'));
    this.getUser();
  }

  ngOnInit() {
  }

  getUser(){
    let that = this;
    this.api.user.getUsersId(this.n).subscribe(
      data=>{
        if (data && data.response!=null){
          that.user = data.response;
          that.user['pass'] = null;
        }
      },
      error=>{
        console.log(error);
      }
    );
  }

  goTo(dest){
    this.router.navigate([dest]);
  }

  saveUser(){
    if (this.user['passv'] == null){
      if (this.user['pass'] != null){
        this.notification.error("Para cambiar la contraseña tiene que cubrir el campo: 'Validar contraseña'.");
      }else{
        this.user['pass']=null;   
        this.update();
      }   
    }else{
      if (this.user['pass'] == this.user['passv']){
        this.update();
      }else{
        this.notification.error("Contraseña no válida.")
      }
    }
  }

  update(){
    let that = this;
    this.api.user.updateUser(this.n, [this.user]).subscribe(
      ()=>{
        that.notification.success("Datos actualizados");
        that.goTo("/");
      },
      error=>{
        that.notification.error("Ha ocurrido un error.");
        console.log(error);
      }
    );
  }

}
