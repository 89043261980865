import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import {ConfigService} from "../../../config/config.service";
import { Provider } from './provider';

@Injectable({
  providedIn: 'root'
})
export class ProviderService {
  private http;
  private server;
  private url = "/api/"+this.config.version+"/provider";

  constructor(private httpClient: HttpClient, private config: ConfigService) {
    this.server=this.config.getServer();
    this.http=this.httpClient;
  }

  public getProviders(){
    return this.http.get(this.server + this.url);
  }

  public getNumbers(){
    return this.http.get(this.server + this.url + "/all");
  }

  public getProviderId(id: number){
    return this.http.get(this.server + this.url + "/" + id);
  }

  public getConditions(name: string){
    return this.http.get(this.server + this.url + "/nameProvider" + name);
  }

  public getAllConditions(){
    return this.http.get(this.server + this.url + "/getAllConditions");
  }

  public getArchive(name: string){
    return this.http.get(this.server + this.url + "/nameArchive" + name);
  }

  public insertProvider(data:Provider){
    const _headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const _params = null;

    const httpOptions = {
      _headers,
      _params,
      withCredentials: false
    };

    return this.http.post(this.server+this.url, data, httpOptions);
  }

  public updateProvider(id: number, data:Provider){
    const _headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    
    const httpOptions = {
      _headers
    };

    return this.http.put(this.server + this.url + "/"+id, data, httpOptions);
  }

  public deleteProvider(id: number){
    const _headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const _params = null;

    const httpOptions = {
      _headers,
      _params,
      withCredentials: false
    };

    return this.http.delete(this.server + this.url + "/" + id, httpOptions);  
  }

  public uploadFile(n:number, file: File, name:string){
    const formData = new FormData();
    let nameFile = name+this.parseTypeImage(file.type);
    formData.append('image', file, file.name); console.log(file);
    let result: string = this.parseNameToUrl(nameFile);

    return this.http.post(this.server + this.url + "/uploadFileToServer", formData);
  }

  public uploadImage(n:number, imagen: File, name:string){
    const formData = new FormData();
    let nameFile = name+this.parseTypeImage(imagen.type);
    formData.append('image', imagen, nameFile);
    let result: string = this.parseNameToUrl(nameFile);

    return this.http.post(this.server + this.url + "/uploadImageToServer", formData);
  }

  public uploadArchive(n:number, file: File, name:string, nameProvider:string){
    const formData = new FormData();
    let nameFile = name;
    formData.append('file', file, nameFile);
    return this.http.post(this.server + this.url + "/archive" + nameProvider, formData);
  }

  parseTypeImage(type:string):string{
    let dotType=".";

    switch(type){
      case "image/jpg": case "image/jpeg": dotType+="jpg";break;
      case "image/png": dotType+="png";break;
      default: dotType="";
    }

    return dotType;
  }

  parseTypePdf(type:string):string{
    let dotType="";

    if(type=="application/pdf"){
      dotType+=".pdf";
    }

    return dotType;
  }

  parseNameToUrl(name:string):string{
    let chars=["á","é","í","ó","ú","ñ"," "];
    let chars_check=["a","e","i","o","u","n","_"];
    let name_array=name.split("");
    name_array.forEach((charName, i)=>{
      chars.forEach( (char, j)=>{
        if(charName==char){
          name_array[i]=chars_check[j];
        }
      });
    });
    return name_array.join("");
  }

  /*uploadFTP(imagen: File):any{
    const formData = new FormData();
    let nameFile = name+this.parseTypeImage(imagen.type);
    formData.append('image', imagen, nameFile);
    let result: any;
    result = this.http.post(this.server + this.url + "/uploadImageToFTP", formData);
    
    return result;
  }*/

  checkImages(id:number){
    const _headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    
    const httpOptions = {
      _headers
    };
    let data = null;
    let result = this.http.put(this.server + this.url + "/" + id, data, httpOptions);
    return result;
    //this.http.post(this.server + this.url + "/checkImages/",+id);
  }

  public cleanProducts(provider: number){
    const _headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
  
    const _params =null;
  
    const httpOptions = {
      _headers,
      _params,
      withCredentials: false
    };
  
    return this.http.delete(this.server + this.url + "/" + provider + "/clean",httpOptions); 
  }

}
