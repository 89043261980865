import { Injectable } from '@angular/core';
import { UserService } from "./models/user/user.service";
import { ProviderService } from './models/provider/provider.service';
import { ProductService } from './models/product/product.service';
import { OrderService } from './models/order/order.service';
import { ContactService } from './models/contact/contact.service';
import { ApiProviderService } from './models/api/api-provider.service';
import { CartService } from './models/cart/cart.service';

@Injectable()
export class ApiService {
  public user:UserService;
  public provider:ProviderService;
  public product:ProductService;
  public order:OrderService;
  public contact:ContactService;
  public apiProvider:ApiProviderService;
  public cart:CartService;

  constructor(private userService:UserService, private providerService: ProviderService, private productService: ProductService, 
    private orderService: OrderService, private contactService: ContactService, private apiProviderService: ApiProviderService, 
    private cartCurrent: CartService) {
    this.user=this.userService;
    this.provider=this.providerService;
    this.product=this.productService;
    this.order=this.orderService;
    this.contact=this.contactService;
    this.apiProvider=this.apiProviderService;
    this.cart=this.cartCurrent;
  }
}
