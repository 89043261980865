<footer>
  <div class="container">
    <div class="banners" *ngIf="banner">
      <div class="banner">
        <img src="/assets/banners/banner-correo.jpg" title="Banner llámanos" alt="Error al cargar la imagen"/>
        <div class="inner">
          <div class="top">¿Dudas? ¿Preguntas?</div>
          <div class="middle">Llámanos al <span class="big">981 39 64 74</span></div>
          <div class="button">
            <div class="wrapper">
              <div class="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"/></svg>
              </div>
              <div class="text"><a href="mailto:mail@unifersa.net" target="_blank">O envíanos un correo</a></div>
            </div>
          </div>
        </div>
      </div>
      <div class="banner">
        <a href="https://unifersa.es/Unifersa/Publicaciones/2019%20NUM%2039%20Junio.pdf" target="_blank">
          <img src="/assets/banners/banner-catalogo.jpg" title="Banner catálogo" alt="Error al cargar la imagen"/>
        </a>
      </div>
    </div>
    <div class="bottom">
      <div class="text">{{year}} Unifersa | 981 39 64 74 | contacto@unifersa.net</div>
      <div class="links">
        <div class="link">
          <a routerLink="/politica-privacidad">Política de privacidad</a>
        </div>
        <div class="link">|</div>
        <div class="link">
          <a routerLink="/aviso-legal">Aviso legal</a>
        </div>
        <div class="link">|</div>
        <div class="link">
          <a routerLink="/politica-cookies">Política de cookies</a>
        </div>
      </div>
    </div>
  </div>
</footer>