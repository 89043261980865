import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-politica-cookies',
  templateUrl: './politica-cookies.component.html',
  styleUrls: ['./politica-cookies.component.less']
})
export class PoliticaCookiesComponent implements OnInit {

  constructor(private api:ApiService, private cookie:CookieService) {
    api.user.sumPages(parseInt(cookie.get("userid"))).subscribe();
  }

  ngOnInit() {
  }

}
