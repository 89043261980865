import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/api/api.service';
import { NotificationService } from 'src/app/notification/notification.service';
import { Router } from '@angular/router';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { User } from 'src/app/api/models/user/user';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.less']
})
export class CartComponent implements OnInit {
  public cart:any[]=[];
  public providers:any[];
  public total:number=0;
  public userId:number;
  public credito:boolean = true;
  public provinceId:number=1;
  public user: User;
  public hasContacts: any[];

  constructor(private cookie: CookieService, private api: ApiService, private notification: NotificationService, private router:Router, private http:HttpClient) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  
    this.providers = [];
    this.hasContacts = [];
    this.userId=parseInt(cookie.get("userid"));
    api.user.getUsersId(this.userId).subscribe(
      data =>{
        if (data && data["response"]){
          this.user = data["response"];
          this.credito = data["response"]["credito"];
          this.api.contact.getProvinces().subscribe(
            prov=>{
              prov.response.forEach(element => {
                if (element.name == data.response.province){
                  this.provinceId = element.id;
                }
              });
            }
          );
        }
      },
      err =>{
        console.log("Error with id User.");
      }
    );

    api.user.sumPages(parseInt(cookie.get("userid"))).subscribe();
  }

  ngOnInit() {
    if (this.cookie.get("userid")){
      this.getCartCurrent();
    }

    /*if(this.cookie.get("car")){
      this.cart=JSON.parse(this.cookie.get("cart"));
    }*/
   /* if(this.cart && this.cart.length>0){
      let idx=0;
      let dataIdx=[];
      let that = this;
      this.cart.forEach((provider) => {
        provider.more="";
        this.api.provider.getProviderId(parseInt(provider.id)).subscribe(
          data => {
            dataIdx[idx]=provider.id;
            idx++;

            data.response.products=[];
            data.response.more="";
            data.response.subtotal=0;
            this.providers.push(data.response);
            provider.products.forEach(product => {
              this.api.product.getProductByRef(provider.id, product.ean).subscribe(
                data => {
                  data.response.uds=product.uds;
                  if(data.response.price_offer){
                    data.response.price=data.response.price_offer;
                  }
                  product.price=data.response.price;
                  
                  let j=0;
                  dataIdx.forEach((idx, i) => {
                    if(idx==data.response.provider){
                      j=i;
                    }
                  });

                  this.providers[j].products.push(data.response);
                  this.providers[j].subtotal+=data.response.price;
                  
                  //contactos
                  that.providers[j]['contacts'] = [];
                  that.api.contact.getContactByProviderForEmail(provider.id, that.provinceId).subscribe(
                    cont=>{
                      if (cont.response){
                        this.hasContacts[j] = Array.isArray(cont.response);
                        that.providers[j]['allcontacts'] = cont.response;
                        cont.response.forEach(element => {                          
                          that.providers[j]['contacts'].push(element.email);
                        });
                      }
                    }
                  );
                    
                  this.total+=data.response.price;
                },
                error => {
                  console.error(error);
                }
              );
            });
          },
          error => console.error(error)
        );
      });
    }*/
  }

  getCartCurrent(){
    let that = this;
    this.api.cart.getCartCurrentByUser(this.cookie.get("userid")).subscribe(
      data=>{
        if (data && data.response){
          data.response.forEach((element, ind) => {
            let isIn = that.cart.length > 0 ? that.cart.map(function(ele){ return ele.id;}).indexOf(element.provider) : -1;
            if (isIn >=0){
              that.cart[isIn].products.push(element);
            }else{
              let prods = that.cart.push({id: element.provider, products: [element]});
            }
            if (ind+1 == data.response.length){
              that.getDataCart();
            }
          });
        }
      },
      error=>{
        console.log(error);
      }
    );
  }

  getDataCart(){
    if(this.cart && this.cart.length>0){
      let idx=0;
      let dataIdx=[];
      let that = this;
      this.cart.forEach((provider) => {
        provider.more="";
        this.api.provider.getProviderId(parseInt(provider.id)).subscribe(
          data => {
            dataIdx[idx]=provider.id;
            idx++;

            data.response.products=[];
            data.response.more="";
            data.response.subtotal=0;
            this.providers.push(data.response);
            provider.products.forEach(product => {
              this.api.product.getProductByRef(provider.id, product.ean).subscribe(
                data => {
                  data.response.uds=product.uds;
                  if(data.response.price_offer){
                    data.response.price=data.response.price_offer;
                  }
                  product.price=data.response.price;
                  
                  let j=0;
                  dataIdx.forEach((idx, i) => {
                    if(idx==data.response.provider){
                      j=i;
                    }
                  });

                  this.providers[j].products.push(data.response);
                  this.providers[j].subtotal+=data.response.price;
                  
                  //contactos
                  that.providers[j]['contacts'] = [];
                  that.api.contact.getContactByProviderForEmail(provider.id, that.provinceId).subscribe(
                    cont=>{
                      if (cont.response){
                        this.hasContacts[j] = Array.isArray(cont.response);
                        that.providers[j]['allcontacts'] = cont.response;
                        cont.response.forEach(element => {                          
                          that.providers[j]['contacts'].push(element.email);
                        });
                      }
                    }
                  );
                    
                  this.total+=data.response.price;
                },
                error => {
                  console.error(error);
                }
              );
            });
          },
          error => console.error(error)
        );
      });
    }
  }

  getSubtotal(i){
    let total=0;
    this.providers[i].products.forEach(product => {
      total+=(product.price * product.uds);
    });
    let totalR = this.numberPointToCo(total);
    if (totalR == "0,0000") return "";
    return totalR+"€";
  }

  getTotal(){
    let total=0;
    this.providers.forEach((provider, i)  => {
      provider.products.forEach(product => {
        if(this.hasContacts[i]){
        total+=(product.price * product.uds);
        }
      });
    });

    return this.numberPointToCo(total);
  }



  sendOrder(id = null){
    let products:any[]=[];
    let more:string="";
    let name: string = "";
    let contacts: string = ""; 
    let productsText: string = "";
    let that = this;
    if(id!=null){
      let namePdf="";
      this.providers.forEach((provider, i) =>{
        if(provider.id==id){
          more= provider.more != "" ? provider.more : 'sin comentarios';
          name = provider.name;  
          provider.products.forEach(product =>{
            product.provider=id;
            products.push(product);
            productsText += "<p><strong>Producto</strong>: "+product.name+"<br>Ean: "+product.ean+"<br>Ref: "+product.ref+"<br>Unidades: "+product.uds+"</p>";
          });
          productsText += "<p>Comentarios del cliente: "+more+"</p>";
          contacts = JSON.stringify(provider['contacts']);
          let orderId = '';
          
          this.api.order.insertOrder(parseInt(this.cookie.get("userid")), more, products).subscribe(
              data =>{
                orderId = data.id;
                that.notification.success("Pedido enviado correctamente");
                that.generatePdf(name, provider.products, more, i, provider['allcontacts'], orderId,function(resp){
                  namePdf = resp;

                  let text=name;
                  if (that.userId != 1){
                    that.api.order.sendOrder(text, parseInt(that.cookie.get('userid')), contacts, productsText, namePdf).subscribe(
                      data => {
                      that.deleteProductsOfCart(id);
                    },
                    error => {
                      that.deleteProductsOfCart(id);
                    }
                  );
                }else{
                    that.deleteProductsOfCart(id);
                }
              });
              
            },
            error => {
              console.error(error);
            }
            );
        }
      });
      
    }else{ //ENVIAR TODOS
      this.providers.forEach((provider, i) =>{
        if(this.hasContacts[i]){
          more= provider.more != "" ? provider.more : 'sin comentarios';
          let id = provider.id;
          provider.products.forEach(product =>{
          product.provider=id;
          products.push(product);
        });
      }
      });
        //this.cart.splice(i, 1); //con esto me petaba y no seguia recorriendo los proveedores
        let provNames = this.getProveedores();
        this.api.order.insertOrder(parseInt(this.cookie.get("userid")), more, products).subscribe(
          data =>{
            let orderId = data.id;
            this.notification.success("Pedido enviado correctamente");
            let text="Pedido de los proveedores: "+provNames;
            this.providers.forEach((provider, i)=>{
              if(this.hasContacts[i]){
              contacts = "";
              contacts = JSON.stringify(provider['contacts']);
              let namePdf="";
              name = provider.name;
              text = name;
              productsText = "";
              products.forEach(el => {
                if (el.provider == provider.id)
                  productsText += "<p><strong>Producto</strong>: "+el.name+"<br>Ean: "+el.ean+"<br>Ref: "+el.ref+"<br>Unidades: "+el.uds+"</p>";
              });
              productsText += "<p>Comentarios del cliente: "+provider.more+"</p>";

              this.generatePdf(name, provider.products, more, i, provider['allcontacts'], orderId, function(resp){
                namePdf = resp;
                if (that.userId != 1){
                  that.api.order.sendOrder(text, parseInt(that.cookie.get('userid')), contacts, productsText, namePdf).subscribe(
                    data=>{
                      if (that.providers.length == i+1){
                        /*if(that.cart.length>0 && that.cart[0].length>0){
                          that.cookie.set("cart", JSON.stringify(that.cart), 1, "/", undefined, true, 'None');
                        }
                        else{*/
                          that.api.cart.deleteCartByUser(that.userId);             
                        //}
                        window.location.href="/";
                      }
                    },
                    error=>{
                      if (that.providers.length == i+1){
                        /*if(that.cart.length>0 && that.cart[0].length>0){
                          that.cookie.set("cart", JSON.stringify(that.cart), 1, "/", undefined, true, 'None');
                        }
                        else{*/
                          that.api.cart.deleteCartByUser(that.userId);
                        //}
                        window.location.href="/";
                      }
                    }
                  );
                }else{
                  that.api.cart.deleteCartByUser(that.userId);             
                  window.location.href="/";
                }
              });
            }

            });
          
          },
          error =>{
            console.error(error.error);
          }
        );
      //});
    }
  }

  

  deleteOrder(ean){
    /*let newcart=[];
    let cart = JSON.parse(this.cookie.get("cart"));
    this.cookie.set("cart", "", 1, "/", undefined, true, 'None');
    for(let i=0; i<cart.length; i++){
      let newbrand={
        "id": cart[i].id,
        "products": []
      };

      cart[i].products.forEach((product, j) => {
        if(product.ean!=ean){
          newbrand.products.push(product);
        }
      });
      if(newbrand.products.length>0){
        newcart.push(newbrand);
      }
    }
    this.cookie.set("cart", JSON.stringify(newcart), 1, "/", undefined, true, 'None');*/

    this.api.cart.deleteCartByUserAndEan(parseInt(this.cookie.get("userid")), ean).subscribe(
      data=>{
        window.location.reload();
      },
      error=>{
        console.log(error);
      }
    );
  }

  deleteProductsOfCart(id){
    this.cart.forEach(ele=>{
      if (ele.id == id){
        let prods = ele.products;
        prods.forEach((prod, index) => {
          this.api.cart.deleteCartByUserAndEan(this.userId, prod.ean).subscribe(
            data=>{
              if (index+1 == prods.length){
                window.location.reload();
              }
            }
          );
        });
      }
    });
  }

  private addUds(i, j){
    if(this.providers[i].products[j].uds_caja){
      this.providers[i].products[j].uds+=this.providers[i].products[j].uds_caja;
    }else{
      this.providers[i].products[j].uds++;
    }
  }

  private removeUds(i, j){
    if(this.providers[i].products[j].uds>1){
      if(this.providers[i].products[j].uds_caja){
        this.providers[i].products[j].uds-=this.providers[i].products[j].uds_caja;
      }else{
        this.providers[i].products[j].uds--;
      }
    }
  }

  mathCeil(price, uds){
    let num = this.numberPointToCo(price*uds);
    if (num == '0,0000') return num = '';
    return num+"€";
  }

  numberPointToCo(number){
    let num=(number * 1000 / 1000).toFixed(4);
    num = num.toString().replace(".", ",");
    return num;
  }

  getProveedores(){
    let names = "";
    this.providers.forEach(elem => {
      if (names == "")
        names = elem.name;
      else  
        names = names + ", " + elem.name;
    });
    return names;
  }

  generatePdf(nameProv, products, observaciones, i, contacts, orderId = '', response:((resp)=>any)){
    let cod_client= this.user.code;    let name = this.user.name_business;    let cif= this.user.nif;
    let address= this.user.address_business;    let localidade = this.user.locality;    let province= this.user.province;
    let cp= this.user.cp;        let country= this.user.country;       let persona= this.user.contact;
    let today = new Date().getDate() + "-" + (new Date().getMonth()+1) +"-"+ new Date().getFullYear();
    let contact = contacts.filter(e=>{return e.central == 1;});
    if (contact.length > 0){
      contact = contact[0];
    }else{
      contact = contacts[0];
    }
    let year = new Date().getFullYear();
    orderId= year.toString().substring(2,4)+'0'+orderId.toString();
    
    const documentDefinition = {
      defaultFileName : 'Propuesta',
      info: {
        author: 'Unifersa'
      },
      content: [
        {
          alignment: 'justify',
          columns: [
            {
              width: '*',
              stack: [
                {
                  image: 'logo',
                  width: 200,
                  margin: [0,0,0,10]
                },
                { 
                  stack:[
                    "Rúa Leme, S/N - Pol. Ind. Vilar do Colo - 15500 Fene (A Coruña)",
                    "981 39 64 74 - unifersa@unifersa.es",
                    "C.I.F. A70063383"
                  ],
                  fontSize: 10
                }
              ]
            }, 
            {
              width: 200,
              alignment: 'right',
              stack: [
                {
                  text: "PROPUESTA DE PEDIDO",
                  fontSize: 18,
                  bold: true
                },
                {
                  margin: [0,10,0,0],
                  stack: [
                    nameProv,
                    contact.name,
                    contact.phone+" - "+contact.email
                  ],
                  fontSize: 10 
                }
              ]              
            }
          ]
        },
        {
          alignment: 'justify',
          columns: [
            {
              width: 370,
              style: 'tableDataCliente',
              table: {
                headerRows: 0,
                widths: [110, 110, 110],
                body: [
                  [
                    {text: 'COD. CLIENTE', border: [true, true, true, false], fontSize: 9}, 
                    {text: 'NOMBRE', border: [true, true, true, false], fontSize: 9}, 
                    {text: 'C.I.F./N.I.F.', border: [true, true, true, false], alignment: 'center', fontSize: 9}
                  ],   
                  [
                    {text: cod_client, border: [true, false, true, true], fontSize: 9}, 
                    {text: name, border: [true, false, true, true], fontSize: 9}, 
                    {text: cif, border: [true, false, true, true], fontSize: 9}
                  ],  
                  [
                    {text: 'DIRECCIÓN', border: [true, true, true, false], colSpan: 2, fontSize: 9}, {}, 
                    {text: 'LOCALIDADE', border: [true, true, true, false], fontSize: 9}
                  ],   
                  [
                    {text: address, border: [true, false, true, true], colSpan: 2, fontSize: 9}, {}, 
                    {text: localidade, border: [true, false, true, true], fontSize: 9}
                  ],    
                  [
                    {text: 'COD. POSTAL', border: [true, true, true, false], fontSize: 9}, 
                    {text: 'PROVINCIA', border: [true, true, true, false], fontSize: 9}, 
                    {text: 'PAÍS', border: [true, true, true, false], fontSize: 9}
                  ],   
                  [
                    {text: cp, border: [true, false, true, true], fontSize: 9}, 
                    {text: province, border: [true, false, true, true], fontSize: 9}, 
                    {text: country, border: [true, false, true, true], fontSize: 9}
                  ],                
                  [ 
                    {text: 'Contacto: '+persona+' - '+this.user.email, border: [true, true, true, true], colSpan: 3, fontSize: 9}, {}, {}
                  ]
                ]
              },
              layout: {
                hLineWidth: function (i, node) {
                  return (i === 0 || i === node.table.body.length) ? 2 : 1;
                },
                vLineWidth: function (i, node) {
                  return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                }
              }
            }, 
            {
              width: 120,
              style: 'tableDataCliente',
              table: {
                headerRows: 0,
                widths: [60, 60],
                body: [
                  [{text: 'Nº PEDIDO', border: [true, true, true, false], fontSize: 9}, {text: 'FECHA', border: [true, true, true, false], fontSize: 9}],
                  [{text: orderId, border: [true, false, true, true], fontSize: 9}, {text: today, border: [true, false, true, true], fontSize: 9}],
                  [{text: 'FECHA CONFIRMACIÓN', colSpan: 2, border: [true, true, true, false], fontSize: 9}, {}],
                  [{text: ' ', colSpan: 2, border: [true, false, true, true], fontSize: 9}, {}],
                  [{text: 'AGENTE', colSpan: 2, border: [true, true, true, false], fontSize: 9}, {}],
                  [{text: 'unifersa.net', colSpan: 2, border: [true, false, true, true], fontSize: 9}, {}]
                ]
              },
              layout: {
                hLineWidth: function (i, node) {
                  return (i === 0 || i === node.table.body.length) ? 2 : 1;
                },
                vLineWidth: function (i, node) {
                  return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                }
              }
            }
          ]
        },
        {
          style: 'tableOrder',
          table: {
            headerRows: 1,
            widths: ['auto', 68, 55, 'auto', 'auto', 57, 'auto', 58],
            body: [
              [
                {text: 'Nº', style:'tableHeader'}, 
                {text: 'EAN', style:'tableHeader'}, 
                {text: 'REF', style:'tableHeader'}, 
                {text: 'DESCRIPCIÓN', style:'tableHeader'}, 
                {text: 'CANTIDAD', style:'tableHeader'}, 
                {text: 'PRECIO', style:'tableHeader'}, 
                {text: 'DTO', style:'tableHeader'}, 
                {text: 'IMPORTE', style:'tableHeader'}],
              ...products.map((p,ind) => (
                [ {text: ind+1, style: 'dataOrder'}, 
                  {text: p.ean, style: 'dataOrder'}, 
                  {text: p.ref, style: 'dataOrder'}, 
                  {text: p.name, style: 'dataOrder'}, 
                  {text: p.uds +' und', style: 'dataOrder'}, 
                  {text: this.numberPointToCo(p.price) == '0,0000' ? '' : this.numberPointToCo(p.price), style: 'dataOrder'}, '', 
                  {text: (this.mathCeil(p.price , p.uds )), style: 'dataOrder'}])
              )
              ,
              [
                {text: 'TOTAL PEDIDO:', colSpan:7, fontSize: 10},{},{},{},{},{},{},
                {text: this.getSubtotal(i), fontSize: 10}
              ]
            ]
          },
          layout: {
            hLineWidth: function (i, node) {
              return (i === 0 || i === node.table.body.length) ? 2 : 1;
            },
            vLineWidth: function (i, node) {
              return (i === 0 || i === node.table.widths.length) ? 2 : 1;
            }
          }
        },
        {
          margin: [0,10,0,0],
          stack: [
            {text: "Observaciones:", bold: true},
            observaciones        
          ],
          fontSize: 10
        }
      ],
      images: {
        logo: 'https://unifersa.net/assets/logo.png'
      },
      styles: {
        tableDataCliente: {
          margin: [0,10,0,0],
          alignment: 'center'
        }, 
        tableOrder: {          
          margin: [0,20,0,0],
          alignment: 'right'
        },
        tableHeader: {
          bold: true,
          fontSize: 9,
          color: 'black',
          alignment: 'center'
        },
        dataOrder:{
          fontSize: 8
        }
      }
    };

    let pdf = pdfMake.createPdf(documentDefinition);
    let namePdf = this.user.id+(new Date().valueOf())+".pdf";
    
    let clientInfo = [cod_client, name, cif, address, localidade, cp, province, country, persona+' - '+this.user.email]
    this.generateCSV(namePdf, products, orderId, clientInfo);
    
    /*pdfMake.createPdf(documentDefinition).open();
    response(namePdf);*/
    pdf.getBase64((dataPdf)=>{
      let dataPost = namePdf+","+dataPdf;       
      this.api.order.uploadInvoice(namePdf, dataPdf)
            .subscribe(
                data => {
                  response(namePdf);
                },
                error => console.log(error)
            )
    });
  }

  generateCSV(name, products, orderId, clientInfo){
    let productsExport:Array<any>=[];
    productsExport.push(['Nº', 'EAN', 'REFERENCIA', 'DESCRIPCIÓN', 'CANTIDAD', 'PRECIO', 'DTO', 'IMPORTE', 'Nº PEDIDO', 'COD. CLIENTE', 'NOMBRE', 'C.I.F./N.I.F', 'DIRECCIÓN', 'LOCALIDADE', 'COD. POSTAL', 'PROVINCIA', 'PAÍS', 'CONTACTO']);
    products.forEach((element, ind) => {
      productsExport.push([ind+1, element.ean, element.ref, element.name == null ? '' : element.name, element.uds+" und", this.numberPointToCo(element.price) == '0,0000' ? '' : this.numberPointToCo(element.price), '', (this.mathCeil(element.price , element.uds)), orderId, ...clientInfo]);      
    });
    name = name.replace('.pdf', '.csv');

    this.api.order.uploadInvoiceCSV(name, productsExport).subscribe();
  }

}
