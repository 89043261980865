import { Component, OnInit } from '@angular/core';
import { UserService } from '../../api/models/user/user.service';
import { User } from '../../api/models/user/user';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/notification/notification.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.less']
})
export class UserComponent implements OnInit {
  users: User[];
  user: User[];
  headers:Array<any>;
  p: number = 1;
  public deleteModal:boolean=false;
  public adminModal:boolean=false;
  public id:number;
  public admin:number;
  all:number=0;
  textModal:string;
  userUpdate: User;

  orderUser:boolean = false;
  orderEmail:boolean = false;
  orderEmpres:boolean = false;
  orderDocs:boolean = false;
  orderProv:boolean = false;

  constructor(private userService: UserService, private router:Router, private notification: NotificationService, private cookie:CookieService, private _route: ActivatedRoute) {
    if(parseInt(cookie.get("role"))==3){
      this.router.navigate(["admin/providers"]);
    }
    if (this._route.snapshot.paramMap.get('id') != null && this._route.snapshot.paramMap.get('id') != ""){console.log("hey");
      this.all = 1;
    }
     
    if (this._route.url['_value'] && this._route.url['_value'].length > 1){
      if (this._route.snapshot.paramMap.get('id') == "asociado" || this._route.url['_value'][1].path == "asociado"){
        this.all = 2;
      }else if (this._route.snapshot.paramMap.get('id') == "compras" || this._route.url['_value'][1].path == "compras"){
        this.all = 3;
      }else if (this._route.snapshot.paramMap.get('id') == "prov" || this._route.url['_value'][1].path == "prov"){
        this.all = 4;
      }else if (this._route.snapshot.paramMap.get('id') == "administrador" || this._route.url['_value'][1].path == "administrador"){
        this.all = 5;
      }else{
        this.all = 1;
      }
    }
    
    this.getUsers();
   }

  ngOnInit() {
    this.headers=[
      {
        name: "Usuario",
        class: "min-390"
      },
      {
        name: "Nombre",
        class: "min-350"
      }, 
      {
        name: "Nombre de empresa",
        class: "min-180"
      },
      {
        name: "Provincia",
        class: "min-220"
      }, 
      {
        name: "Docs",
        class: "min-5"
      },
      {
        name: "Rol",
        class: "min-120"
      },
      {
        name: "Crédito",
        class: "min-50"
      },
      {
        name: "",
        class: "min-90"
      }
    ];
    
  }

  getUsers(): void{
    //this.users = this.userService.getUsers();
    let that = this;
    this.userService.getUsers().subscribe(//users=> this.users =users);
      users=>{
        if(users.response!=null){          
          that.users=[];
          if (!this.all){
            users.response.forEach(element => {
              if (element.baja == 0)
              that.users.push(element);
            });
          }else{
            if (that.all == 1){
              that.users =users.response;
            }
            if (that.all == 2){//asociado
              users.response.forEach(element => {
                if (element.role == 1)
                that.users.push(element);
              });
            }
            if (that.all == 3){//compras
              users.response.forEach(element => {
                if (element.role == 4 || element.role == 5)
                that.users.push(element);
              });
            }
            if (that.all == 4){//proveedor
              users.response.forEach(element => {
                if (element.role == 3)
                that.users.push(element);
              });
            }
            if (that.all == 5){//administrador
              users.response.forEach(element => {
                if (element.role == 2)
                that.users.push(element);
              });
            }
          }
        } else {
          this.notification.error("ERROR with users");
        }  
      },
      err=>{
        console.log(err);
      }    
    );
  }

  getUserId(id: number): void{
    let user=this.userService.getUsersId(id).subscribe(user=>{
      if(user.response!=null){
        //this.user = user.response;
        this.router.navigate(["/admin/user/"+id]);
      } else{
        console.log("Error with user by id");
      }
    });
  }

  deleteUser(id: number, baja:number): void{
    if (baja == 0)
      this.textModal = "¿Está seguro que desea eliminar este usuario?";
    else if (baja == 1)
      this.textModal = "¿Está seguro que desea recuperar este usuario? Tendrá que habilitar las IPs de nuevo.";

    this.deleteModal=true;
    this.id=id;
  }

  changeCredito(id,credito):void{
    this.userUpdate = new User;
    this.userUpdate.id = id;
    this.userUpdate.credito = credito==1 ? 0 : 1;
    this.userService.updateUser(id, [this.userUpdate]).subscribe(
      data=> {console.log(data);
        this.notification.success("Usuario actualizado.");
        //this.router.navigate(["/admin/usesr"]);
        window.location.reload();
      },
      error =>{
        this.notification.error("Ha ocurrido un error.");
        console.log(error);
      }
    );
  }

  hide(event):void{
    this.deleteModal=false;
    this.adminModal=false;
  }

  delete(event):void{
    this.userService.deleteUser(event.id).subscribe(
      () => {
        this.notification.success("Usuario eliminado.");
        this.deleteModal=false;
        //document.querySelector("tr.id-"+event.id).remove();
        window.location.reload();
      },
      error => {
        this.notification.error("Ha ocurrido un error al eliminar el usuario.");
      }
    );
  }

  insertUser(): void{
    //this.user = [];
    this.router.navigate(["/admin/user"]);
  }

  getAccessByUser(id){
    this.router.navigate(["admin/user/"+id+"/access"]);
  }

  sortUser(number){
    if (number == 1){ //nombre
      if (!this.orderUser){
        this.users.sort(function(a,b) {
          if (a.contact.toLowerCase() > b.contact.toLowerCase()) return 1; 
          if (a.contact.toLowerCase() == b.contact.toLowerCase()) return 0; 
          if (a.contact.toLowerCase() < b.contact.toLowerCase()) return -1;});
        this.orderUser = !this.orderUser;
      }else{
        this.users.sort(function(a,b) {
          if (a.contact.toLowerCase() > b.contact.toLowerCase()) return -1; 
          if (a.contact.toLowerCase() == b.contact.toLowerCase()) return 0; 
          if (a.contact.toLowerCase() < b.contact.toLowerCase()) return 1;});
        this.orderUser = !this.orderUser;
      }
     
    }else if (number == 2){ //empresa
      if (!this.orderEmpres){
        this.users.sort(function(a,b) {
          if (a.business == null && b.business == null) return 1;
          if (a.business == null) return 1;
          if (b.business == null) return -1;
          if (a.business.toLowerCase() > b.business.toLowerCase()) return 1; 
          if (a.business.toLowerCase() == b.business.toLowerCase()) return 0; 
          if (a.business.toLowerCase() < b.business.toLowerCase()) return -1;});
        this.orderEmpres = !this.orderEmpres;
      }else{
        this.users.sort(function(a,b) {          
          if (a.business == null && b.business == null) return 1;
          if (a.business == null) return -1;
          if (b.business == null) return 1;
          if (a.business.toLowerCase() > b.business.toLowerCase()) return -1; 
          if (a.business.toLowerCase() == b.business.toLowerCase()) return 0; 
          if (a.business.toLowerCase() < b.business.toLowerCase()) return 1;});
        this.orderEmpres = !this.orderEmpres;
      }
      
    }else if (number == 3){ //correo
      if (!this.orderEmail){
        this.users.sort(function(a,b) {
          if (a.email == null && b.email == null) return 1;
          if (a.email == null) return 1;
          if (b.email == null) return -1;
          if (a.email.toLowerCase() > b.email.toLowerCase()) return 1; 
          if (a.email.toLowerCase() == b.email.toLowerCase()) return 0; 
          if (a.email.toLowerCase() < b.email.toLowerCase()) return -1;});
        this.orderEmail = !this.orderEmail;
      }else{
        this.users.sort(function(a,b) {          
          if (a.email == null && b.email == null) return 1;
          if (a.email == null) return -1;
          if (b.email == null) return 1;
          if (a.email.toLowerCase() > b.email.toLowerCase()) return -1; 
          if (a.email.toLowerCase() == b.email.toLowerCase()) return 0; 
          if (a.email.toLowerCase() < b.email.toLowerCase()) return 1;});
        this.orderEmail = !this.orderEmail;
      }
    }else if (number == 4){ //docs
      if (!this.orderDocs){
        this.users.sort(function(a,b){
          if (a.docs == null && b.docs == null) return 1;
          if (a.docs == null) return 1;
          if (b.docs == null) return -1;
          if (a.docs > b.docs) return 1; 
          if (a.docs == b.docs) return 0; 
          if (a.docs < b.docs) return -1;
        });
      }else{
        this.users.sort(function(a,b){
          if (a.docs == null && b.docs == null) return 1;
          if (a.docs == null) return -1;
          if (b.docs == null) return 1;
          if (a.docs > b.docs) return -1; 
          if (a.docs == b.docs) return 0; 
          if (a.docs < b.docs) return 1;
        });
      }
      this.orderDocs = !this.orderDocs;
    }else if (number == 5){ //provincia
      if (!this.orderProv){
        this.users.sort(function(a,b){
          if (a.province == null && b.province == null) return 1;
          if (a.province == null) return 1;
          if (b.province == null) return -1;
          if (a.province.toLowerCase() > b.province.toLowerCase()) return 1; 
          if (a.province.toLowerCase() == b.province.toLowerCase()) return 0; 
          if (a.province.toLowerCase() < b.province.toLowerCase()) return -1;
        });
      }else{
        this.users.sort(function(a,b){
          if (a.province == null && b.province == null) return 1;
          if (a.province == null) return 1;
          if (b.province == null) return -1;
          if (a.province.toLowerCase() > b.province.toLowerCase()) return -1; 
          if (a.province.toLowerCase() == b.province.toLowerCase()) return 0; 
          if (a.province.toLowerCase() < b.province.toLowerCase()) return 1;
        });
      }
      this.orderProv = !this.orderProv;
    }
  }
}
