import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api/api.service';

@Component({
  selector: 'main-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit, AfterViewInit  {
  public username:string;
  public role:number;
  public cart:Array<any>=[];
  public cartNumber:number=0;
  public is_admin:boolean=false;
  public cart_total:number=0;
  
  public nprovs:number;
  public nprods:number;
  public roleProvider:number = 3;
  
  @Input() seeker:string;

  public showAddToCart: boolean = true;

  constructor(private cookies: CookieService, private router: Router, private api: ApiService, private cookie: CookieService) {
    this.username = cookies.get("username");
    if (3 == parseInt(this.cookie.get('role'))) this.showAddToCart = false;
    if (parseInt(cookies.get("userid")) > 0){
      api.user.getUsersId( parseInt(cookies.get("userid")) ).subscribe(
        data => {
          if(data){
            this.role=data.response.role;
          }
        },
        error => {
          console.error(error);
        }
      );
    }
     
    api.provider.getNumbers().subscribe(
      data => { 
        this.nprovs=data.response.providers;
        this.nprods=data.response.products;
      },
      error => {
        console.error(error);
      }
    )
  }

  ngOnInit() {
    /*let mycart;
    if(this.cookies.get("cart")){
      mycart=JSON.parse(this.cookies.get("cart"));
      mycart.forEach(provider=>{
          this.cartNumber+=provider.products.length;
          provider.products.forEach(element => {
            this.api.product.getProductByRef(provider.id, element.ean).subscribe(
              data => {
                this.cart.push(data.response);
                this.cart_total+=data.response.price;
              },
              error => {
                console.error(error);
              }
            )
          });
      });
    }*/



    if(this.cookies.get("userid")){
      this.api.user.getUsersId(parseInt(this.cookies.get("userid"))).subscribe(
        userRes => {
          this.is_admin=(userRes.response.is_admin==1);
        },
        error =>{
          console.error(error);
        }
      );
      
      this.getCartCurrent(this.cookies.get("userid"));
    }
  }

  ngAfterViewInit(): void {
    const searchInputParent = document.getElementsByClassName("input")[0];
    const _searchInput = document.getElementById("search");

    let ngAttribute;
    Array.prototype.forEach.call(searchInputParent.attributes, att => {
        if (att.name.startsWith("_ngcontent")) {
            ngAttribute = att;
        }
    });

    if (ngAttribute) {
        _searchInput.setAttribute(ngAttribute.name, ngAttribute.value);
    }

    _searchInput.style.display = "";

    searchInputParent.appendChild(_searchInput);
  }

  getCartCurrent(userId){
    let that = this;
    this.api.cart.getCartCurrentByUser(userId).subscribe(
      data=>{
        if (data && data.response.length > 0){
          let mycart = data.response;
          let lengthcart = data.response.length;
          mycart.forEach(element => {
            that.api.product.getProductByRef(element.provider, element.ean).subscribe(
              data=>{
                that.cart.push(data.response);
                this.cart_total+=data.response.price;
              }
            );
          });
        }
      },
      error=>{
        console.log(error);
      }
    );
  }

  logout(){
    this.cookies.delete("userid");
    this.cookies.delete("username");
    this.cookies.delete("role");
    
    window.location.href="/login";
  }

  viewCart(){
    this.router.navigate(["/cart"]);
  }

  goTo(dest){
    this.router.navigate([dest]);
  }

  reloadHome(){
    window.location.href="/";
  }

  numberPointToCo(number){
    let num=(number * 1000 / 1000).toFixed(4);
    num = num.toString().replace(".", ",");
    return num;
  }
}
