<div class="container">
    <header-admin title="Documentos del usuario"></header-admin>
    <unifersa-tabla [headers]="headers">
        <div class="row" *ngFor="let doc of documents">
            <div class="type">
                <span *ngIf="doc.document">{{doc.document}}</span>
                <span *ngIf="!doc.document">--</span>
            </div>
            <div class="ip">
                {{userIp}}
            </div>
            <div class="documents">
                {{doc.provider}}
            </div>  
            <div class="date">
                {{doc.date | date: 'dd/MM/yyyy hh:mm'}}
            </div>
        </div>
    </unifersa-tabla>

    <div class="actions">
        <a routerLink="/admin/user/{{userId}}/access">Volver</a>
    </div>
    
</div>