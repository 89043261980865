import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from 'src/app/config/config.service';
import { Contact } from './contact';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private http;
  private server;
  private url = "/api/"+this.config.version+"/contact"; 
  
  constructor(private httpClient: HttpClient, private config: ConfigService) {
    this.server=this.config.getServer();
    this.http=this.httpClient;
  }

  public getContacts(){
    return this.http.get(this.server + this.url);
  }

  public getProvinces(){
    return this.http.get(this.server + this.url + "/provinces");
  }

  public getContactById(id){
    return this.http.get(this.server + this.url + "/"+id.toString());
  }

  public getContactByProvider(id){
    return this.http.get(this.server + this.url + "/"+id.toString() + "/provider");
  }

  public getContactByProviderForEmail(id, province){
    return this.http.get(this.server + this.url + "/"+id.toString() + "/" + province.toString());
  }

  public insertContact(data: Contact){
    const _headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const _params = null;

    const httpOptions = {
      _headers,
      _params,
      withCredentials: false
    };

    return this.http.post(this.server+this.url, data, httpOptions);
  }

  public updateContact(id: number, data: Contact){
    const _headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    
    const httpOptions = {
      _headers
    };

    return this.http.put(this.server + this.url + "/"+id, data, httpOptions);
  }

  public deleteContact(id:number){
    
    const _headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const _params = null;

    const httpOptions = {
      _headers,
      _params,
      withCredentials: false
    };

    return this.http.delete(this.server + this.url + "/" + id, httpOptions);  
  }
}
