<main-header *ngIf="user.role != 2"></main-header>
<div class="container">
  <header-admin title="Productos del pedido {{orderId}} de {{nameOrderContact}}" back="true">
    <div class="header-aux">
      <div class="wrapper">
        <button (click)="download()">Descargar propuesta</button>
      </div>
    </div>
  </header-admin>
  <unifersa-tabla [headers]="headers">
    <div class="row" *ngFor="let product of products">
      <div class="name">{{product.name}}</div>
      <div class="uds">{{product.uds}}</div>
      <div class="price">{{product.price}}€</div>
      <div class="provider">
        <img [src]="product.prov_img" title="Logo Proveedor" alt="Error Imagen"/>
        <div class="title">{{product.prov_name}}</div>
      </div>
      <div class="more">{{product.more}}</div>
    </div>
  </unifersa-tabla>
</div>