import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { isArray } from 'util';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { NotificationService } from 'src/app/notification/notification.service';
import { ApiService } from 'src/app/api/api.service';
import { Cart } from 'src/app/api/models/cart/cart';
import * as importedSaveAs from "file-saver";
import * as watermark from 'watermarkjs';

@Component({
  selector: 'list-grid',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.less']
})
export class ListComponent implements OnInit, OnChanges {
  @Input() data:any[];
  @Input() route:string;
  @Input() image:string;
  @Input() identifier:string;
  @Input() perPage:number;
  @Input() elem_by_row:number;
  @Input() view:string;
  @Input() order:string;
  @Input() providers:boolean = false;

  @Input() letter:string;
  
  @Input() show_pag:boolean;

  public perPageOrig:number;

  public gridActive:string="";
  public listActive:string="";
  
  public data_print:Array<any[]> = [];
  public showAddToCart: boolean = true;

  public role: number = 0;

  constructor(private cookie:CookieService, private activatedRoute: ActivatedRoute, private notification:NotificationService, private api: ApiService, private httpClient: HttpClient) {    
    if (3 == parseInt(this.cookie.get('role'))) this.showAddToCart = false;
    this.role = parseInt(this.cookie.get('role'));
  }

  ngOnInit() {
    this.perPageOrig=this.perPage;
    if(this.data){
      this.loadData();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.data){
        this.loadData();
    }
  }

  private loadData(){
    let num_rows:number=0;
    let num_elems:number=0;
    this.data_print[num_rows]=[];
    if(isArray(this.data)){
      this.data.forEach((element, indx) => {
        if(this.letter == null || (this.letter!=null && element.name.charAt(0).toLowerCase() == this.letter.toLowerCase()) ){
          if(num_elems==this.perPage){
            num_rows++;
            num_elems=0;
            this.data_print[num_rows]=[];
          }

          if(!Array.isArray(element.images)) element.images=[element.image];

          this.data_print[num_rows][num_elems]=element;
          
          num_elems++;
        }else if(this.letter!=null && this.letter=='0' && parseInt(element.name.charAt(0))){
          if(num_elems==this.perPage){
            num_rows++;
            num_elems=0;
            this.data_print[num_rows]=[];
          }

          if(!Array.isArray(element.images)) element.images=[element.image];
          this.data_print[num_rows][num_elems]=element;
          
          num_elems++;
        }        
      });

      this.data_print=this.data_print[0];
    }
  }

  public changedPage($e){
    this.data_print=$e;
  }

  addToCart(provider, product){
    let cartData = new Cart();
    cartData.user = parseInt(this.cookie.get("userid"));
    cartData.ref = product.ref;
    cartData.ean = product.ean;
    cartData.user = product.uds_caja ? product.uds_caja : 1;
    cartData.provider = provider;
    
    this.api.cart.insertCurrentCart(cartData).subscribe(
      data=>{
        if (data && data.code == 201){
          this.notification.success(["Producto añadido al carrito"]);
          setTimeout(function(){
             window.location.reload();
           }, 500);
        }else{
          this.notification.error(["No se ha podido añadir el producto al carrito"]);
        }
      },
      error=>{
        this.notification.error(["No se ha podido añadir el producto al carrito"]);
      }
    );

    /*let productAdd={
      provider: provider,
      ean: product.ean,
      uds: product.uds_caja ? product.uds_caja : 1
    };

    let cart = this.cookie.get("cart");
    let providerAdded=false;
    let productAdded=false;
    let cartjson:Array<any>=[];*/

   /* if(cart){
      cartjson=JSON.parse(cart);
      cartjson.forEach(provider => {
        if(provider.id==productAdd.provider){
          provider.products.forEach(product =>{
            if(product.ean==productAdd.ean){
              product.uds=productAdd.uds;
              productAdded=true;
            }
          });
          if(!productAdded){
            provider.products.push({
              ean: productAdd.ean,
              uds: productAdd.uds
            });
          }
          providerAdded=true;
        }
      });
    }

    if(!providerAdded) {
      cartjson.push({
        id: productAdd.provider,
        products: [
          {
            ean: productAdd.ean,
            uds: productAdd.uds
          }
        ]
      });
    }*/
    
    //this.cookie.set("cart", JSON.stringify(cartjson), 1, "/", undefined, true, 'None');

    /*this.notification.success(["Producto añadido al carrito"]);

    setTimeout(function(){
      window.location.reload();
    }, 500);*/
  }

  numberPointToCo(number){
    let num=(number * 1000 / 1000).toFixed(4);
    num = num.toString().replace(".", ",");
    return num;
  }

  loginCenor(){
    window.open('http://intranet.cenor.es', '_blank');
  }

  regDownload(){
    //todo registro downloadpages
    let url = 'https://unifersa.net/assets/conditions/CENOR.jpg';
    let that = this;

    const options = {
      init(img){
        img.crossOrigin = 'anonymous';
      }
    };

    let X = 600; let Y = -1700;
    let nameUser=this.cookie.get("userid");
    const rotate = function(target){
      var context = target.getContext('2d');
      var metrics = context.measureText(nameUser);
      var x = (target.width / 2) - (metrics.width + 164);
      var y = (target.height / 2) + 164 * 2;

      context.translate(x, y);
      context.globalAlpha = 0.2;
      context.fillStyle = '#0082c0';
      context.font = '164px Josefin Slab';
      context.rotate(-45 * Math.PI / 180);
      context.fillText(nameUser, X, Y);
      return target;
    }

    this.api.user.getUsersId(parseInt(this.cookie.get("userid"))).subscribe(
      data=>{
        let nameUser = '';
        if (data.response.code != null && data.response.code != "")
          nameUser= data.response.code;
        else
          nameUser = data.response.email;

          watermark([url], options).image(rotate).then(function(img){ 
            X=1000;Y=-1300; //1,2
            watermark([img.src]).image(rotate).then(function(img){
              X=1400;Y=-900; //1,3
              watermark([img.src]).image(rotate).then(function(img){
                X=1800;Y=-500; //1,4
                watermark([img.src]).image(rotate).then(function(img){
                  X = 0; Y= -1100; //3,1
                  watermark([img.src]).image(rotate).then(function(img){
                    X=400;Y=-700; //3,2
                    watermark([img.src]).image(rotate).then(function(img){
                      X=800;Y=-300;//3,3
                      watermark([img.src]).image(rotate).then(function(img){
                        X=1200;Y=100;//3,4
                        watermark([img.src]).image(rotate).then(function(img){
                          X=-600;Y=-500;//5,1 
                          watermark([img.src]).image(rotate).then(function(img){
                            X=-200; Y=-100; //5,2
                            watermark([img.src]).image(rotate).then(function(img){
                              X=200;Y=300; //5,3
                              watermark([img.src]).image(rotate).then(function(img){
                                X=600;Y=700; //5,4
                                watermark([img.src]).image(rotate).then(function(img){
                                  X=-1200;Y=100; //7,1
                                  watermark([img.src]).image(rotate).then(function(img){
                                    X=-800;Y=500; //7,2
                                    watermark([img.src]).image(rotate).then(function(img){
                                      X=-400;Y=900; //7,3
                                      watermark([img.src]).image(rotate).then(function(img){
                                        X=0;Y=1300; //7,4
                                        watermark([img.src]).image(rotate).then(function(img){
                                          X=300;Y=-1400; //2,1
                                          watermark([img.src]).image(rotate).then(function(img){
                                            X=700;Y=-1000; //2,2
                                            watermark([img.src]).image(rotate).then(function(img){
                                              X=1100;Y=-600; //2,3
                                              watermark([img.src]).image(rotate).then(function(img){
                                                X=1500;Y=-200; //2,4
                                                watermark([img.src]).image(rotate).then(function(img){
                                                  X=-300;Y=-800; //4,1
                                                  watermark([img.src]).image(rotate).then(function(img){
                                                    X=100;Y=-400; //4,2
                                                    watermark([img.src]).image(rotate).then(function(img){
                                                      X=500;Y=0; //4,3
                                                      watermark([img.src]).image(rotate).then(function(img){
                                                        X=900;Y=400; //4,4
                                                        watermark([img.src]).image(rotate).then(function(img){
                                                          X=-900;Y=-200; //6,1
                                                          watermark([img.src]).image(rotate).then(function(img){
                                                            X=-500;Y=200; //6,2
                                                            watermark([img.src]).image(rotate).then(function(img){
                                                              X=-100;Y=600; //6,3
                                                              watermark([img.src]).image(rotate).then(function(img){
                                                                X=300;Y=1000; //6,4
                                                                watermark([img.src]).image(rotate).then(function(img){
                                                                  X=-1500;Y=400; //8,1
                                                                  watermark([img.src]).image(rotate).then(function(img){
                                                                    X=-1100;Y=800; //8,2
                                                                    watermark([img.src]).image(rotate).then(function(img){
                                                                      X=-700;Y=1200; //8,3
                                                                      watermark([img.src]).image(rotate).then(function(img){
                                                                        X=-300;Y=1600; //8,4
                                                                        watermark([img.src]).image(rotate).then(function(img){
                                                                          importedSaveAs.saveAs(img.src, 'CENOR');
                                                                        });
                                                                      });
                                                                    });
                                                                  });
                                                                });
                                                              });
                                                            });
                                                          });
                                                        });
                                                      });
                                                    });
                                                  });
                                                });
                                              });
                                            });
                                          });
                                        });
                                      });
                                    });
                                  });
                                });
                              });
                            });
                          });
                        });
                      });
                    });
                  });
                });
              });
            });                
          });
      }
    );
  }
}