<div id="pagination">
  <div class="pages">
    <div *ngIf = "num_pages<=3">
        <div class="prev page" [ngClass]="{'disable': prevDisabled}" (click)="changePage(page-1)"> < </div>
        <div class="page" [ngClass]="{'active': pageActive[i]}" *ngFor="let page_index of data_print; let i = index" (click)="changePage(i)">{{i+1}}</div>
        <div class="next page" [ngClass]="{'disable': nextDisabled}" (click)="changePage(page+1)"> > </div>
    </div>
    <div *ngIf = "num_pages>3">
        <div class="prev page" [ngClass]="{'disable': prevDisabled}" (click)="changePage(page-1)"> < </div>
    
        <div class="prev page" *ngIf="page==(num_pages-1)" [ngClass]="{'disable': prevDisabled}" (click)="changePage(page-2)"> {{page-1}} </div>
        <div class="prev page" *ngIf="page>=1" [ngClass]="{'disable': prevDisabled}" (click)="changePage(page-1)"> {{page}} </div>
        
        <div class="page" [ngClass]="{'active': pageActive[page]}" (click)="changePage(page)">{{page+1}}</div>
        
        <div class="next page" *ngIf = "page < (num_pages-1)" [ngClass]="{'disable': nextDisabled}" (click)="changePage(page+1)"> {{page+2}} </div>
        <div class="next page" *ngIf="page==0" [ngClass]="{'disable': nextDisabled}" (click)="changePage(page+2)"> {{page+3}} </div>
        
        <div class="next page" [ngClass]="{'disable': nextDisabled}" (click)="changePage(page+1)"> > </div>
    </div>
    
  </div>
</div>