import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../api/api.service';
import { Provider } from '../../api/models/provider/provider';
import { Product } from '../../api/models/product/product';
import { NotificationService } from 'src/app/notification/notification.service';
import { Location } from '@angular/common';
import { isArray } from 'util';
import { Api } from 'src/app/api/models/api/api';

class ImageSnippet {
  constructor(public src: string, public file: File) {}
}

@Component({
  selector: 'app-provider-data',
  templateUrl: './provider-data.component.html',
  styleUrls: ['./provider-data.component.less']
})
export class ProviderDataComponent implements OnInit {
  public n: number;
  selectedFile: ImageSnippet;
  provider: Provider;
  imageInput: any;
  public tieneProductos:boolean = false;
  public hideLoad:boolean=true;
  public textLoad:string="Cargando proveedor";
  private w: boolean = false;
  public nomeCondic:string = "";
  public urlPdf:string = "";
  private url:string = "https://unifersa.net/assets/conditions/";

  public apis: Array<Api>=[];

  constructor(private api: ApiService, private router:Router, private notification: NotificationService,
     private _route: ActivatedRoute, private location:Location) {
    this.n = parseInt(this._route.snapshot.paramMap.get('id'));
      
    if (this.n){
      this.getProviderId(this.n);
      this.tieneProductos = true;
    }else{
      this.hideLoad=false;
      this.provider = new Provider;
      this.provider.emails=[""];
    }
   }

  ngOnInit() {
    let that = this;
    this.api.apiProvider.getApis().subscribe(
      data=>{
        that.apis = data.response;
      }
    );
  }

  getProviderId(id:number): void{
    this.api.provider.getProviderId(id).subscribe(
      provider => {
        if (provider.response != null){
          this.hideLoad=true;
          this.provider = provider.response;
          this.provider.products = [];
          if(!isArray(this.provider.emails)){
            this.provider.emails = [""];
          }
          let that = this;
          
          let name = this.provider.name.replace('Ñ', 'N');
          name = name.replace('-','');
          name = name.replace('_','');
          name = name.replace('.','');
          name = name.replace('&','');
          name = name.split(",")[0];              
          name = name.replace('Á', 'A');
          name = name.replace('É', 'E');
          name = name.replace('Í', 'I');
          name = name.replace('Ó', 'O');
          name = name.replace('Ú', 'U');
          name = name.replace('Ü', 'U');
         
          this.api.provider.getConditions(name).subscribe(
            data => {
              if (data.response != ""){
                that.nomeCondic = data.response;
                that.urlPdf = that.url + data.response;
               // console.log(data);
              }
              that.hideLoad=false;
            },
            error => {
              console.error(error); 
              that.hideLoad=false;
            }
          );
          this.hideLoad=false;
        } else{
          this.notification.error("Este proveedor no existe");
          this.router.navigate(["/admin/providers"]);
        }
      }
    );
  }

  changeImage(event){
    if(event.srcElement.files.length>0){
      let that = this;
      this.textLoad="Preparando imagen";
      this.hideLoad=true;
      this.api.provider.uploadImage(this.n, event.srcElement.files[0], this.provider.name).subscribe(
        data => {console.log(data);
          that.provider.image=data.response;
          that.hideLoad=false;
        },
        error => {
          that.notification.error("Ha ocurrido un error al subir la imagen");
          console.error(error);
          that.hideLoad=false;
        }
      );
      //this.hideLoad=false;
    }
  }

  changeCsv(event){
    if(event.srcElement.files.length>0){
      this.textLoad="Preparando productos";
      this.hideLoad=true;
      const file: File = event.srcElement.files[0];
      const reader = new FileReader();
      let products = [];
      let that=this;
      
      this.provider.date_products = new Date().toLocaleDateString();

      reader.onloadend = function(event){
        event.preventDefault();
        let texto: string = reader.result as string;
        let arr = texto.split('\n');
        
        arr.forEach( row =>{
          let product=new Product;
          if(row.trim()){
            let row_arr=row.split("\t");
          
            /*product.ean=row_arr[0];
            product.ref=row_arr[1];
            product.name=row_arr[2];
            product.description=row_arr[3];
            product.price=parseFloat(row_arr[4]);
            product.price_offer=parseFloat(row_arr[5]);
            product.iva = row_arr[6]!="" ? parseInt(row_arr[6]) : 21;
            product.attrs = row_arr[7];
            product.uds_caja = parseFloat(row_arr[8]);
            product.categories = row_arr[9];
            product.url_info=row_arr[10];
            product.images=row_arr[11];
            product.card=row_arr[12];*/
            row_arr[0] = row_arr[0].replace('"',"");
            product.ean=row_arr[0].trim();
            product.ref=row_arr[1];
            product.name=row_arr[2];
            product.uds_caja = parseFloat(row_arr[3]);
            product.price=parseFloat(row_arr[4]);
            product.pvpr = parseFloat(row_arr[5]);
            product.description=row_arr[6];
            product.images=row_arr[7];
            
            products.push(product);
          }
        });

        that.hideLoad=false;
        that.provider.products=products;
        console.log(products);
      };

      reader.readAsText(file, "UTF-8");
    }
  }

  changeConds(event){
    if(event.srcElement.files.length>0){
      this.textLoad="Preparando archivo";
      this.hideLoad=true;
      let name = this.provider.name;
      name = name.replace('Ñ', 'N');
      name = name.replace('-','');
      name = name.replace('.','');
      name = name.replace('_','');
      name = name.replace('&','');
      name = name.split(",")[0];      
      name = name.replace('Á', 'A');
      name = name.replace('É', 'E');
      name = name.replace('Í', 'I');
      name = name.replace('Ó', 'O');
      name = name.replace('Ú', 'U');
      name = name.replace('Ü', 'U');

      this.api.provider.uploadFile(this.n, event.srcElement.files[0], name).subscribe(
        data => {
          this.provider.conditions=data.response;
          this.hideLoad=false;
        },
        error => {
          this.notification.error("Ha ocurrido un error al subir el pdf");
          console.error(error);
          this.hideLoad=false;
        }
      );
      this.hideLoad=false;
    }
  }

  uploadFile(event){
    if (event.srcElement.files.length>0){
      this.textLoad="Preparando archivo";
      this.hideLoad=true;
      let nameArchivo = event.srcElement.files[0].name;
      let name = this.provider.name;
      name = name.split(" ")[0];
      name = name.replace("-", " ");
      this.api.provider.uploadArchive(this.n, event.srcElement.files[0], nameArchivo, name).subscribe(
        data=>{
          //console.log(data);
          this.notification.success("Archivo subido correctamente.");
          this.hideLoad=false;
        },
        error=>{
          console.error(error);
          this.notification.error("Ha ocurrido un error al subir el archivo");
          this.hideLoad=false;
        }

        //TODO comprobar que sube arvhicos por aqui
      );
    }
  }

  saveProvider(){
    if(this.n){  
      this.updateProvider();
    }else{
      this.insertProvider();      
    }
    
  }

  checkImages(){
    this.textLoad="Comprobando imágenes...";
    this.hideLoad=true;
    this.api.provider.checkImages(this.n).subscribe(
      data =>{
        //console.log(data);
        this.hideLoad=false;
        this.notification.success("Las imágenes han sido comprobadas.");
      },
      error =>{
        console.error(error);
        this.hideLoad=false;
        this.notification.error("Ha ocurrido un error al comprobar.");
      }
    );
  }

  private updateProvider(): void{
    this.textLoad="Subiendo productos...";
    this.hideLoad=true;
    let that = this;
    let api: any = this.provider.api;
    if (api === 'null') this.provider.api = null;
  
    this.api.provider.updateProvider(this.n, this.provider).subscribe(
      data => {
        let num_prods=that.provider.products.length;
        let i=0;
        if (num_prods != 0){
          that.provider.products.forEach( (product, j) => {
            product.provider=that.n;
            
            that.api.product.updateProduct(product).subscribe(
              prod=>{
                i++;
                if(i==num_prods-1){console.log("j");
                  that.hideLoad=false;
                  that.tieneProductos = true;
                  //that.router.navigate(["admin/providers"]);
                }
              },
              err=>{
                console.error(err);
                if (err.error.code==404){
                  that.api.product.insertProduct(product).subscribe(
                    success => {
                      i++;
                      if(i==num_prods-1){
                        that.hideLoad=false;
                        that.notification.success("Proveedor actualizado");
                        that.router.navigate(["admin/providers"]);
                      }
                    },
                    error => {
                      that.hideLoad=false;
                      console.error(error);
                    }
                  );
                }else{
                  that.hideLoad=false;
                }
              }
            );
          });
        }else{
          that.hideLoad=false;
          that.router.navigate(["admin/providers"]);
        }
      },
      error =>{
        console.error(error.error);
        that.hideLoad=false; 
        that.notification.error("Ha ocurrido un error al actualizar.");
      }
    );
  }

  private insertProvider(): void{
    this.textLoad="Subiendo productos...";
    this.hideLoad=true;
    this.api.provider.insertProvider(this.provider).subscribe(
      data=>{
        console.log(data);
        if(data.id){
          if(this.provider.products && this.provider.products.length > 0 ){
            let cont=0;
            this.provider.products.forEach(product => {
              product.provider=data.id;
              this.api.product.insertProduct(product).subscribe(
                data => {
                  this.tieneProductos = true;
                  cont++;
                  if(cont==this.provider.products.length){
                    this.hideLoad=false;
                    this.location.back();
                  }
                },
                error =>{
                  cont++;
                  if(cont==this.provider.products.length){
                    this.hideLoad=false;
                    this.location.back();
                  }
                  console.error(error);
                }
              );
            });
            this.w = true; 
           
          } else{
            this.router.navigate(["/admin/providers"]);
          }
          this.n = data.id;
          if (this.provider.view != "")
            this.checkImages(); //TODO revisar, siempre sale el coso de las imagenes han sido revisadas auqnue no haya
        }
      },
      err =>{
        console.error(err);
        this.notification.error("Ha ocurrido un error al insertar.");
      }
    );
  }

  public editInput($event, i){
    this.provider.emails[i]=$event.srcElement.value;
  }

  public addEmailProvider(){
    this.provider.emails.push("");
  }

  viewContacts(){
    window.location.href= "/admin/provider/"+this.n+"/contacts";
  }

}
