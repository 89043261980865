import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/api/api.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.less']
})
export class DocumentsComponent implements OnInit {
  headers:any[];
  userId:number;
  userIp:string;
  documents: Array<any>=[];

  constructor(private api: ApiService, private route:ActivatedRoute) { 
    let that = this;
    this.route.params.subscribe(
      data=>{
        that.userId = data.id;
        that.userIp = data.ip;
        that.getDocuments();
      }
    );
  }

  ngOnInit(): void {
    this.headers=[
      {
        name: "Tipo de documento",
        class: "min-447"
      },
      {
        name: "IP",
        class: "min-250"
      }, 
      {
        name: "Documento",
        class: "min-510"
      },
      {
        name: "Fecha de descarga",
        class: "min-180"
      }
    ];
  }

  getDocuments(){
    let that = this;
    this.api.user.getAccess(this.userId).subscribe(
      data=>{
        if (data.response){
          that.documents = data.response.filter(doc => {
            return doc.ip == that.userIp;
          })[0].documents;
          console.log(that.documents);
        }
      },  
      error=>{
        console.error(error);
      }
    );
  }

}
